import { cloneDeep, isNil } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { MdAdd } from 'react-icons/md';
import { formatDateToString } from '../../utils/date-utils.tsx';
import Atendimento from './Atendimento';

import './style/medicalCare.css';

const proceduresWithParticipateRate = ['0', '1'];
const proceduresWithDate = ['0', '1', '2'];
const proceduresWithHours = ['0', '1'];
const proceduresWithAmount = ['0', '1', '3'];

const MedicalCareThirthStep = forwardRef(
  (
    {
      values,
      system,
      errors,
      handleChange,
      loading,
      error,
      converterSascTreatmentsCreateSaveToContext,
      handleSubmit,
    },
    ref
  ) => {
    const [professionals, setProfessionals] = useState([]);
    const [procedures, setProcedures] = useState([]);
    const [participateRateList, setParticipateRateList] = useState([]);
    const [accessWaysList, setAccessWaysList] = useState([]);
    const [techniquesList, setTechniquesList] = useState([]);
    const [loadingProcedure, setLoadingProcedure] = useState(false);
    const [showViaeTec, setShowViaeTec] = useState(true);

    const dropProfessionalController = useRef([]);
    const dropProcedureController = useRef([]);
    const procedimentosRefs = useRef([]);
    const profissionaisRefs = useRef([]);

    useImperativeHandle(ref, () => ({
      validateStep: validateStep,
    }));

    const validateStep = () => {
      console.log('valida 3');
      let isValid = true;
      const FIELD_REQUIRED = 'Campo obrigatório';

      values.procedures.forEach((item, index) => {
        const {
          procedure,
          professional,
          participateRate,
          accessWay,
          technique,
          amount,
          date,
          startTime,
          endTime,
        } = item;

        if (!procedure) {
          handleChange(
            `procedures[${index}].procedure`,
            procedure,
            FIELD_REQUIRED
          );
          isValid = false;
        }

        if (!professional) {
          handleChange(
            `procedures[${index}].professional`,
            professional,
            FIELD_REQUIRED
          );
          isValid = false;
        }

        const guideTypeId = values.guideType;

        if (proceduresWithDate.includes(guideTypeId) && !date) {
          handleChange(`procedures[${index}].date`, date, FIELD_REQUIRED);
          isValid = false;
        }

        if (proceduresWithHours.includes(guideTypeId) && !startTime) {
          handleChange(
            `procedures[${index}].startTime`,
            startTime,
            FIELD_REQUIRED
          );
          isValid = false;
        }

        if (proceduresWithHours.includes(guideTypeId) && !endTime) {
          handleChange(`procedures[${index}].endTime`, endTime, FIELD_REQUIRED);
          isValid = false;
        }
      });

      return isValid;
    };

    const getDisplay = (index) => {
      let value = values.procedures[index].participateRate;
      if (value === '12') {
        return 'none';
      } else {
        let value = values.guideType;
        return !isNil(value) && proceduresWithParticipateRate.includes(value)
          ? 'block'
          : 'none';
      }
    };

    const getDisplayBool = (index) => {
      let value = values.procedures[index].participateRate;
      if (value === '12') {
        return false;
      } else {
        let value = values.guideType;
        return !isNil(value) && proceduresWithParticipateRate.includes(value)
          ? true
          : false;
      }
    };

    const getDisplayParticipateRate = (index) => {
      let value = values.guideType;
      return !isNil(value) && proceduresWithParticipateRate.includes(value)
        ? 'block'
        : 'none';
    };

    const getDisplayParticipateRateBool = (index) => {
      let value = values.guideType;
      return !isNil(value) && proceduresWithParticipateRate.includes(value)
        ? true
        : false;
    };

    const getDisplayAmount = (index) => {
      let value = values.guideType;
      return !isNil(value) && proceduresWithAmount.includes(value)
        ? 'block'
        : 'none';
    };

    const getDisplayDate = (index) => {
      let value = values.guideType;
      return !isNil(value) && proceduresWithDate.includes(value)
        ? 'block'
        : 'none';
    };

    const getDisplayHours = (index) => {
      let value = values.guideType;
      return !isNil(value) && proceduresWithHours.includes(value)
        ? 'block'
        : 'none';
    };

    return (
      <>
        {values.procedures.map((procedure, index) => (
          <Atendimento
            key={index}
            index={index}
            values={values}
            handleChange={handleChange}
            errors={errors}
            getDisplayDate={() => getDisplayDate(index)}
            getDisplayParticipateRate={() => getDisplayParticipateRate(index)}
            getDisplayParticipateRateBool={() =>
              getDisplayParticipateRateBool(index)
            }
            getDisplayBool={() => getDisplayBool(index)}
            getDisplayAmount={() => getDisplayAmount(index)}
            getDisplay={() => getDisplay(index)}
          />
        ))}
        {values.guideType && values.guideType !== '2' && (
          <div className="d-flex justify-content-center mb-custom">
            <button
              disabled={false}
              onClick={() => {
                let newProcedures = cloneDeep(values.procedures);
                let obj =
                  values.guideType === '0'
                    ? {
                        date: new Date(),
                      }
                    : {};
                newProcedures.push(obj);
                dropProfessionalController.current.push(React.createRef());
                dropProcedureController.current.push(React.createRef());
                procedimentosRefs.current.push(React.createRef());
                profissionaisRefs.current.push(React.createRef());
                handleChange('procedures', newProcedures, null);
              }}
              className="btn btn-link"
            >
              <MdAdd size={30} className="iconPlusThirdStep" />
              <span
                className={
                  values.procedures.length < 2
                    ? 'textInsertDisabledThirdStep'
                    : 'textInsertThirdStep'
                }
              >
                Inserir novo procedimento
              </span>
            </button>
          </div>
        )}
      </>
    );
  }
);

export default MedicalCareThirthStep;
