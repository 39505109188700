import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { objectConstants } from '../../constants/objects.constants';
import { authenticationService } from '../../services/authentication.service';
import { twoFactorAuthenticationService } from '../../services/twoFactorAuthentication.service';
import { get } from 'lodash';
import ControlledTooltip from '../../components/Tooltip/ControlledTooltip';
import { useNavigate } from 'react-router-dom';
import './styles/authenticationFactorsPage.css';

const AuthenticationFactorsPage = ({
  success,
  error,
  twoFactorAuthenticate,
  loading,
}) => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    authenticatorEmail: null,
    authenticatorSms: null,
    mfaToken: null,
    authenticate: null,
    emailAdress: '',
  });

  const {
    authenticatorEmail,
    authenticatorSms,
    mfaToken,
    authenticate,
    emailAdress,
  } = state;

  const goToRegisterCodeAccessEmail = () => {
    navigate('/register-code-access-email', { state: mfaToken });
  };

  const goToRegisterCodeAccessSms = () => {
    navigate('/register-code-access-sms', { state: mfaToken });
  };

  const goToValidateCodeAccessEmail = () => {
    loading(true);
    twoFactorAuthenticationService
      .challenge(mfaToken, objectConstants.AUTHENTICATOR_TYPE_EMAIL_COFFEE_BEAN)
      .then(
        (r) => {
          loading(false);
          navigate(`/code-login-confirm/${mfaToken}/email_otp/${emailAdress}`);
        },
        () => {
          loading(false);
        }
      );
  };

  const goToValidateCodeAccessSms = () => {
    loading(true);
    twoFactorAuthenticationService
      .challenge(mfaToken, objectConstants.AUTHENTICATOR_TYPE_SMS_COFFEE_BEAN)
      .then(
        (r) => {
          loading(false);
          navigate(`/code-login-confirm/${mfaToken}/sms_otp`);
        },
        () => {
          loading(false);
        }
      );
  };

  const goToRegisterCodeAccessQrCode = () => {
    navigate('/register-code-access-qrcode', {
      mfaToken,
    });
  };

  const goToHome = async () => {
    let systemDefault = await authenticationService.getDefaultSystem();
    if (systemDefault) {
      navigate('/private-screens-navigator', { mfaToken });
    } else {
      navigate('/select-default-system', { mfaToken });
    }
  };

  const removeSmsAuthenticator = () => {
    loading(true);
    twoFactorAuthenticationService
      .removeAuthenticator(authenticatorSms, mfaToken)
      .then(
        (r) => {
          success({ message: 'Telefone removido com sucesso!' });
          loading(false);
          getValidations();
        },
        () => {
          error('Não foi possível remover o telefone!');
          loading(false);
        }
      );
  };

  const removeEmailAuthenticator = () => {
    loading(true);
    twoFactorAuthenticationService
      .removeAuthenticator(authenticatorEmail, mfaToken)
      .then(
        (r) => {
          success({ message: 'E-mail removido com sucesso!' });
          loading(false);
          getValidations();
        },
        () => {
          error('Não foi possível remover o e-mail!');
          loading(false);
        }
      );
  };

  const getValidations = () => {
    loading(true);
    twoFactorAuthenticationService.getValidations().then(
      (r) => {
        let authenticatorEmail = null;
        let authenticatorSms = null;
        let emailAdress = '';
        for (let authenticator of r.data.authenticators) {
          if (
            authenticator.type ===
            objectConstants.AUTHENTICATOR_TYPE_EMAIL_COFFEE_BEAN
          ) {
            authenticatorEmail = authenticator;
          } else if (
            authenticator.type ===
            objectConstants.AUTHENTICATOR_TYPE_SMS_COFFEE_BEAN
          ) {
            authenticatorSms = authenticator;
          }
          if (get(authenticator, 'email_address')) {
            emailAdress = authenticator.email_address;
          }
        }
        setState({
          authenticatorSms,
          authenticatorEmail,
          mfaToken: r.data.mfaToken,
          authenticate: true,
          emailAdress,
        });
        loading(false);
      },
      (err) => {
        console.log(err);
        navigate('/');
        loading(false);
      }
    );
  };

  useEffect(() => {
    // const stateProps = location.state ? location.state : null;
    // if (stateProps && stateProps.authenticatorGate) {
    //  navigate('/private-screens-navigator', { mfaToken });
    // }
    getValidations();
  }, []);

  return (
    <div className="defaultStyles-mainContainer">
      <div className="card_AuthenticationFactorsPage">
        <div className="logoContainer_AuthenticationFactorsPage">
          <img
            src={require('../../assets/logo.png')}
            alt="Logo"
            className="defaultStyles-logo"
          />
        </div>
        <div>
          <p className="defaultStyles-titleLoginPages">
            Gerenciar Fatores de Autenticação
          </p>
          <p className="subtitle_AuthenticationFactorsPage">
            A autenticação em dois fatores (2FA) por SMS ou por e-mail é um
            método de segurança que adiciona uma camada extra de proteção às
            suas contas online
          </p>
          <p className="txtObs_AuthenticationFactorsPage">
            Escolha seu segundo fator de autenticação.
          </p>
        </div>
        <div className="authMethodsContainer_AuthenticationFactorsPage">
          <div className="authMethod_AuthenticationFactorsPage">
            <div
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <ControlledTooltip
                width={100}
                height={100}
                helpText={
                  <p
                    style={{
                      color: '#ffffff',
                      fontSize: 12,
                      textAlign: 'justify',
                    }}
                  >
                    Após inserir suas credenciais, o serviço enviará um código
                    de verificação para o número de telefone vinculado à sua
                    conta. Este código é um número único e temporário, composto
                    por seis dígitos.
                  </p>
                }
              >
                <div className="authMethodHelp_AuthenticationFactorsPage">
                  <div className="authMethodHelpText_AuthenticationFactorsPage">
                    ?
                  </div>
                </div>
              </ControlledTooltip>
            </div>

            <div className="actionContainer">
              <b> Código de Acesso via SMS</b>
              {authenticate &&
                ((!authenticatorSms && !authenticatorEmail) ||
                  (twoFactorAuthenticate && !authenticatorSms)) && (
                  <div className="authMethodButton_AuthenticationFactorsPage">
                    <button
                      onClick={goToRegisterCodeAccessSms}
                      type="button"
                      className="button_validations"
                    >
                      <p className="defaultStyles-noMarginNoPadding">
                        REGISTRAR
                      </p>
                    </button>
                  </div>
                )}
              {!twoFactorAuthenticate && authenticatorSms && (
                <div className="authMethodButton_AuthenticationFactorsPage">
                  <button
                    onClick={goToValidateCodeAccessSms}
                    type="button"
                    className="button_validations"
                  >
                    <p className="defaultStyles-noMarginNoPadding">VALIDAR</p>
                  </button>
                </div>
              )}
            </div>

            {authenticatorSms && (
              <div className="actionContainer">
                <img
                  className="authIcon_AuthenticationFactorsPage"
                  src={require('../../assets/icon-ok.png')}
                  alt="auth-icon"
                />
                <p className="txt-auth">
                  {authenticatorSms.phone_number_obfuscated}
                </p>
                {(twoFactorAuthenticate || !authenticatorSms.verified) &&
                  authenticatorSms && (
                    <div className="authMethodRemoveButton_AuthenticationFactorsPage">
                      <button
                        onClick={removeSmsAuthenticator}
                        className="button_remove"
                      >
                        <p className="authMethodRemoveButtonText_AuthenticationFactorsPage">
                          REMOVER
                        </p>
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
          <div className="authMethod_AuthenticationFactorsPage">
            <div
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <ControlledTooltip
                width={100}
                height={100}
                helpText={
                  <p
                    style={{
                      color: '#ffffff',
                      fontSize: 12,
                      // fontWeight: 'bold',
                      textAlign: 'justify',
                    }}
                  >
                    Após inserir suas credenciais, o serviço enviará um código
                    de verificação para o endereço de e-mail vinculado à sua
                    conta. Esse código é um número único e temporário, composto
                    por seis dígitos.
                  </p>
                }
              >
                <div className="authMethodHelp_AuthenticationFactorsPage">
                  <p className="authMethodHelpText_AuthenticationFactorsPage">
                    ?
                  </p>
                </div>
              </ControlledTooltip>
            </div>

            <div className="actionContainer">
              <b> Código de Acesso via E-mail</b>
              {authenticate &&
                ((!authenticatorSms && !authenticatorEmail) ||
                  (twoFactorAuthenticate && !authenticatorEmail)) && (
                  <button
                    onClick={goToRegisterCodeAccessEmail}
                    className="button_validations"
                  >
                    <p className="defaultStyles-noMarginNoPadding">REGISTRAR</p>
                  </button>
                )}
              {!twoFactorAuthenticate && authenticatorEmail && (
                <button
                  onClick={goToValidateCodeAccessEmail}
                  className="button_validations"
                >
                  <p className="defaultStyles-noMarginNoPadding">VALIDAR</p>
                </button>
              )}
            </div>
            {authenticatorEmail && (
              <div className="actionContainer">
                <img
                  className="authIcon_AuthenticationFactorsPage"
                  src={require('../../assets/icon-ok.png')}
                  alt="auth-icon"
                />
                <p className="txt-auth">
                  {' '}
                  {authenticatorEmail.email_address_obfuscated}
                </p>
                {(twoFactorAuthenticate || !authenticatorEmail.verified) &&
                  authenticatorEmail && (
                    <div className="authMethodRemoveButton_AuthenticationFactorsPage">
                      <button
                        onClick={removeEmailAuthenticator}
                        className="button_remove"
                      >
                        <p className="defaultStyles-noMarginNoPadding">
                          REMOVER
                        </p>
                      </button>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className="finalizeButtonContainer_AuthenticationFactorsPage">
          {twoFactorAuthenticate && (
            <button
              onClick={goToHome}
              className="authMethodTitle_AuthenticationFactorsPage"
            >
              <p className="defaultStyles-noMarginNoPadding">FINALIZAR</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { twoFactorAuthenticate } = state.authentication;

  return {
    twoFactorAuthenticate,
  };
}
const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(mapStateToProps, mapDispatch)(AuthenticationFactorsPage);
