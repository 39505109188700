import React from 'react';
import { isNil, isEmpty } from 'lodash';
import SascCardProcedureInformationProps from './props/SascCardProcedureInformationProps';
import './styles/SascCardProcedureInformation.css';

/**
 * SascCardProcedureInformation
 *
 * @param {number} index
 * @param {string} procedureName
 * @param {string} participateRate
 * @param {string} procedureDate
 * @param {string} procedureStartDate
 * @return {JSX.Element}
 * @constructor
 */
export default function SascCardProcedureInformation({
  index,
  procedureName,
  participateRate,
  procedureDate,
  procedureStartTime,
  procedureEndTime,
  accessWay,
  technique,
  amount,
}: SascCardProcedureInformationProps) {
  return (
    <div>
      <div className="title">{`Informações do Procedimento ${index + 1}`}</div>
      <div className="text">{procedureName}</div>
      {!isEmpty(procedureDate) && (
        <div className="text">{`Data do procedimento: ${procedureDate}`}</div>
      )}
      {!isEmpty(participateRate) && (
        <div className="text">{`Grau de Participação: ${participateRate}`}</div>
      )}
      {!isEmpty(accessWay) && (
        <div className="text">{`Via de acesso: ${accessWay}`}</div>
      )}
      {!isEmpty(technique) && (
        <div className="text">{`Técnica: ${technique}`}</div>
      )}
      {!isEmpty(amount) && (
        <div className="text">{`Quantidade: ${amount}`}</div>
      )}
      <div className="rowContainer">
        {!isNil(procedureStartTime) && (
          <div className="text">{`Hora início: ${procedureStartTime}`}</div>
        )}
        {!isNil(procedureEndTime) && (
          <div className="text">{`Hora fim: ${procedureEndTime}`}</div>
        )}
      </div>
    </div>
  );
}
