import React from 'react';
import SascCardTreatmentsItemProps from '../../../../screen/treatments/list/interfaces/SascCardTreatmentsItemProps';
import { formatStringDate } from '../../../../utils/date-utils.tsx';
import SascCardTreatmentsInformationsAppointment from './appointment/sascCardTreatmentsInformationsAppointment.tsx';
import SascCardTreatmentsInformationsDataAndPrice from './dataandprice/SascCardTreatmentsInformationsDataAndPrice.tsx';
import SascCardTreatmentsInformationsIdentifier from './identifier/SascCardTreatmentsInformationsIdentifier.tsx';
import SascCardTreatmentsInformationsName from './name/SascCardTreatmentsInformationsName.tsx';
import './styles/SascCardTreatmentsInformations.css';

export default function SascCardTreatmentsInformations({
  carteirinha,
  nomePaciente,
  cpfPaciente,
  convenio,
  dataAtendimento,
  procedimentoList,
  valor,
}: SascCardTreatmentsItemProps) {
  return (
    <div className="flex-column">
      <SascCardTreatmentsInformationsName name={nomePaciente} />
      <SascCardTreatmentsInformationsIdentifier
        identifier={cpfPaciente}
        identifierCardNumber={carteirinha}
      />
      <SascCardTreatmentsInformationsAppointment
        medicalInsuranceName={convenio.nome}
        treatmentCode={
          procedimentoList && procedimentoList.length > 0
            ? procedimentoList[0].codigoProcedimento
            : ''
        }
      />
      <SascCardTreatmentsInformationsDataAndPrice
        date={dataAtendimento ? formatStringDate(dataAtendimento) : ''}
        price={valor ? valor : null}
      />
    </div>
  );
}
