import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import ButtonSecondary from '../../components/button/ButtonSecondary';
import { objectConstants } from '../../constants/objects.constants';
import { Navigate, useNavigate } from 'react-router-dom';
import { authenticationService } from '../../services/authentication.service';
import './styles/selectRoleLoginPage.css';

const appVersion = process.env.REACT_APP_VERSION || '';

const SelectRoleLoginPage = ({
  loggedIn,
  finalizarAutenticacaoDoisFatores,
  twoFactorAuthenticate,
  isSudoCoopMed,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    // finalizarAutenticacaoDoisFatores();
  }, [finalizarAutenticacaoDoisFatores]);

  const handleMedicoPress = () => {
    navigate('/login-doctor/' + objectConstants.TIPO_LOGIN_MEDICO);
  };

  const handleOperadorPress = () => {
    navigate('/login-doctor/' + objectConstants.TIPO_LOGIN_OPERADOR);
  };

  const getVersioInformation = () => {
    return appVersion;
  };

  const goDirectToHome = async () => {
    let systemDefault = await authenticationService.getDefaultSystem();
    if (systemDefault) {
      navigate('/private-screens-navigator', { systemDefault });
    } else {
      navigate('/select-default-system');
    }
  };

  if (loggedIn && twoFactorAuthenticate) {
    return <Navigate to={'/'} />;
  } else if (loggedIn && !twoFactorAuthenticate && isSudoCoopMed) {
    goDirectToHome();
  } else if (loggedIn && !twoFactorAuthenticate && !isSudoCoopMed) {
    return <Navigate to={'/authentication-factors'} />;
  }

  return (
    <div className="defaultStyles-mainContainer">
      <div className="shadowBorder_SelectRoleLoginPage card_SelectRoleLoginPage">
        <div id="logoContainer_SelectRoleLoginPage">
          <img
            src={require('../../assets/logo.png')}
            alt="Logo"
            id="logo_SelectRoleLoginPage"
          />
        </div>
        <div id="formContainer_SelectRoleLoginPage">
          <div id="buttonsContainer_SelectRoleLoginPage">
            <ButtonPrimary
              style={{ backgroundColor: 'rgb(99,179,126)' }}
              text={'MÉDICO'}
              onPress={handleMedicoPress}
              id="buttons_SelectRoleLoginPage"
            />
            <ButtonSecondary
              style={{ marginTop: 20 }}
              text={'OPERADOR'}
              onPress={handleOperadorPress}
              id="buttons_SelectRoleLoginPage"
            />
          </div>
        </div>
        <p className="mt-2 text-center">
          <small>Versão: {getVersioInformation()}</small>
        </p>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { load } = state.load;
  const { loggedIn, twoFactorAuthenticate, isSudoCoopMed } =
    state.authentication;
  return {
    loading: load,
    loggedIn,
    twoFactorAuthenticate,
    isSudoCoopMed,
  };
};

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
  authentication: { finalizarAutenticacaoDoisFatores },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
  finalizarAutenticacaoDoisFatores: () => finalizarAutenticacaoDoisFatores(),
});

export default connect(mapStateToProps, mapDispatch)(SelectRoleLoginPage);
