
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './styles/progressComponent.css';

class ProgressComponent extends Component {
  /**
   * Responsible getIconBackGroundColor
   *
   * @param active
   */
  getIconBackGroundColor(active) {
    if (active) {
      return '#A7A353';
    }
    return '#C4C4C4';
  }

  /**
   * getIcons
   *
   * @param totalSteps
   * @param actualStep
   */
  getIcons(totalSteps, actualStep) {
    const icons = [];
    for (let i = 1; i <= totalSteps; i += 1) {
      icons.push(
        <FontAwesomeIcon
          className="sasc-progress-icon" // Assuming class name for the icons in the CSS file
          icon={faCircle}
          color={this.getIconBackGroundColor(i <= actualStep)}
          size="1x" // Adjust size accordingly based on CSS
          key={`${totalSteps}${actualStep}${i}`}
        />
      );
    }
    return icons;
  }

  render() {
    const { totalSteps, actualStep, containerStyle, active } = this.props;

    return (
      <div style={containerStyle}>
        <div className={`sasc-progress-line ${active ? 'active' : ''}`}></div>
        <div className="sasc-progress-view-icons">
          {this.getIcons(totalSteps, actualStep)}
        </div>
      </div>
    );
  }
}

export default ProgressComponent;
