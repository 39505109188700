/**
 * shortenFileName
 *
 * @param {string} fileName
 * @param {number} limit
 * @return {string}
 */
const shortenFileName = (fileName, limit = 17) => {
  if (fileName.length > 15) {
    let firstPart = fileName.slice(0, limit);
    const secondPart = fileName.slice(fileName.length - 8);
    if (firstPart.slice(-1) === ' ') {
      firstPart = firstPart.substring(0, firstPart.length - 1);
    }
    return `${firstPart}...${secondPart}`;
  }
  return fileName;
};

export const translator = {
  acomodacao: 'Acomodação',
  data: 'Data',
  entrega: 'Entrega',
  espelho: 'Espelho',
  grauParticipacao: 'Grau de Participação',
  guia: 'Guia',
  local: 'Local',
  hospital: 'Hospital',
  indicadorDemonstrativo: 'Indicador Demonstrativo',
  numero: 'Número',
  paciente: 'Paciente',
  procedimento: 'Procedimento',
  quantidade: 'Quantidade',
  valor: 'Valor',
  vencimento: 'Vencimento',
  viaAcesso: 'Via de Acesso',
  complemento: 'Complemento',
  convenio: 'Convênio',
  dataAtendimento: 'Data de Atendimento',
  dataGlosa: 'Data de Glosa',
  dataRecursoGlosa: 'Data de Recurso de Glosa',
  dataVencimentoCartaGlosa: 'Data de Venc. da Carta de Glosa',
  motivoGlosa: 'Motivo de Glosa',
  motivoGlosaUnimed: 'Motivo de Glosa Unimed',
  numeroAtendimento: 'Número de Atendimento',
  numeroCartaGlosa: 'Número da Carta de Glosa',
  numeroOrigem: 'Número de Origem',
  observacao: 'Observação',
  quantidadeCobrada: 'Quantidade Cobrada',
  quantidadeGlosada: 'Quantidade Glosada',
  situacaoGlosa: 'Situação de Glosa',
  valorCobrado: 'Valor Cobrado',
  valorGlosado: 'Valor Glosado',
  idGlosa: 'Nº da Glosa',
  dataRealizacao: 'Data realização',
};

export default shortenFileName;
