import React, { useState } from 'react';
import { IoEyeOff, IoEye } from 'react-icons/io5';
import { theme } from '../../theme';
import './styles/sumaryReceive.css';

function SumaryReceive({ label, value, color, className }) {
  const [showSumary, setShowSumary] = useState(true);

  const toggleSumaryVisibility = () => {
    setShowSumary((prevState) => !prevState);
  };

  const containerStyle = { backgroundColor: color ? color : '#63b37e' };

  return (
    <div className={className} style={containerStyle}>
      <div className="labelValueContainer">
        <span className="labelSumaryReceive">{label}</span>
        <span className="valueSumaryReceive">{showSumary ? value : '---'}</span>
      </div>
      <div className="iconContainerSumaryReceive">
        <button
          className="iconBackground_sumaryReceive"
          onClick={toggleSumaryVisibility}
        >
          {showSumary ? (
            <IoEyeOff size={24} color={theme.colors.black} />
          ) : (
            <IoEye size={24} color={theme.colors.black} />
          )}
        </button>
      </div>
    </div>
  );
}

export default SumaryReceive;
