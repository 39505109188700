import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usersService } from '../../services/users.service';
import '../../themes/defaultStyles.css';
import Header from '../Navigation/Header';
import Card from './Card';
import SumaryReceive from './SumaryReceive';
import './styles/home.css';
import { glossedGuidesService } from '../../services/glossedGuides.service';
import { paymentsMadeService } from '../../services/paymentsMade.service';
import { expectedPaymentsService } from '../../services/expectedPayments.service';
const imageBtn = require('../../assets/icons/btn/btn-novo-atendimento.png');
// import { apiSasc } from '../../services/api.service';

function Home({ logout, error }) {
  const [valorRecebido, setValorRecebido] = useState(0);
  const [valorGlosado, setValorGlosado] = useState(0);
  const [valorAReceber, setValorAReceber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const date = new Date();
    const anoAtual = date.getFullYear();
    const mesAtual = date.getMonth() + 1;

    paymentsMadeService
      .getTotal(anoAtual, mesAtual)
      .then(({ data }) => {
        setValorRecebido(data);
      })
      .catch((err) => {
        console.log('Erro ao obter valor recebido: ' + err);
        error('Erro ao obter valor recebido:');
      });

    glossedGuidesService
      .getTotal(anoAtual, mesAtual)
      .then(({ data }) => {
        setValorGlosado(data);
      })
      .catch((err) => {
        console.log('Erro ao obter valor glosado: ' + err);
        error('Erro ao obter valor glosado');
      });

    expectedPaymentsService
      .getTotal(anoAtual, mesAtual)
      .then(({ data }) => {
        setValorAReceber(data);
      })
      .catch((err) => {
        console.log('Erro ao obter valor receber: ' + error);
        error('Erro ao obter valor receber');
      });
  }, []);

  const handleRefresh = () => {
    // handle refresh logic
  };

  const fetchData = async () => {
    try {
      const response = await usersService.getUser();
      const data = response.data;
      setUser(data);
      setShowWelcomeModal(true);
    } catch (error) {
      console.log(error);
      setShowWelcomeModal(false);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // const onCloseModal = () => {
  //   setShowModal(!showModal);
  // };

  // const onCloseWelcomeModal = () => {
  //   setShowWelcomeModal(!showWelcomeModal);
  // };

  // const getContentModal = async () => {
  //   try {
  //     const response = await apiSasc.get(`/api/avisos`);
  //     const data = response.data;
  //     if (data && data.length > 0) {
  //       setContentModal(data);
  //       setShowModal(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="container-fluid">
      <Header />

      <div className="container my-5 my-sm-0">
        <div className="d-flex flex-wrap mt-5">
          <SumaryReceive
            className="col-12 col-sm-4 mb-2 mb-sm-0 mx-0 d-flex flex-row align-items-center p-2"
            label={'Valor Recebido no Mês:'}
            value={formatCurrency(valorRecebido)}
          />
          <SumaryReceive
            className="col-12 col-sm-4 mb-2 mb-sm-0 mx-0 d-flex flex-row align-items-center p-2"
            label={'Valor a Receber:'}
            value={formatCurrency(valorAReceber)}
            color={'#097A5E'}
          />
          <SumaryReceive
            className="col-12 col-sm-4 mb-2 mb-sm-0 mx-0 d-flex flex-row align-items-center p-2"
            label={'Valor Glosado no Mês:'}
            value={formatCurrency(valorGlosado)}
            color={'#707070'}
          />
        </div>
        <div className="homePage_containerCard">
          <Card
            className={'col-12 col-sm-6 col-md-4'}
            label={'Em Analise'}
            action={() => {
              navigate('/cooperativeanalysis');
            }}
            helpText={
              'Esses atendimentos estão na fase de análise, que inclui as seguintes etapas: Conferência: Verificação da documentação e informações do atendimento. Valorização: Cálculo do valor do atendimento. Revisão de codificação: Verificação da codificação de procedimentos e regras de convênio. Padronização: Adaptação do atendimento às normas e procedimentos da cooperativa.'
            }
            height={180}
            image={require('../../assets/em-analise.png')}
          />
          <Card
            className={'col-12 col-sm-6 col-md-4'}
            action={() => {
              navigate('/awaitingpayment');
            }}
            label={'Faturamento Enviado'}
            helpText={
              'Lista com atendimentos que foram faturados e encaminhados ao convênio para avaliação. Esses atendimentos estão aguardando pagamento, mas podem sofrer alterações de valores e glosas.'
            }
            height={100}
            image={require('../../assets/icon-faturamento-enviado.png')}
          />
          <Card
            className={'col-12 col-sm-6 col-md-4'}
            action={() => {
              navigate('/expectedpayments');
            }}
            label={'Previsto'}
            helpText={
              'Lista com atendimentos que foram analisados e aprovados pelo convênio e que estão em processo de pagamento ao cooperado.'
            }
            height={90}
            image={require('../../assets/icon-previstos.png')}
          />
          <Card
            className={'col-12 col-sm-6 col-md-4'}
            action={() => {
              navigate('/glossedguides');
            }}
            label={'Glosados'}
            helpText={
              'Demonstrativo de pagamento do cooperado na cooperativa selecionada. O demonstrativo mostrará todos os repasses do cooperado, incluindo os atuais e de meses anteriores.'
            }
            height={100}
            image={require('../../assets/icon-glosados.png')}
          />
          <Card
            className={'col-12 col-sm-6 col-md-4'}
            action={() => {
              navigate('/paymentsmade');
            }}
            label={'Realizado'}
            helpText={
              'Demonstrativo de pagamento do cooperado na cooperativa selecionada. O demonstrativo mostrará todos os repasses do cooperado, incluindo os atuais e de meses anteriores.'
            }
            height={100}
            image={require('../../assets/icon-realizado.png')}
          />
          <Card
            className={'col-12 col-sm-6 col-md-4'}
            action={() => {
              navigate('/expectedpayments');
            }}
            helpText={'Protocolo Busca de Guia'}
            height={50}
            label={'Protocolo Busca de Guia'}
            image={require('../../assets/icon-protocolo.png')}
          />
        </div>
        <div className="homePage_Atendimento">
          <div
            className="homePage_Atendimento_btn"
            onClick={() => {
              navigate('/MedicalCare');
            }}
          >
            <img src={imageBtn} alt="icon-add-atendimento" />
            <span className="atalho_atendimento">NOVO ATENDIMENTO</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { load } = state.load;
  const { loggedIn, twoFactorAuthenticate } = state.authentication;
  return {
    loading: load,
    loggedIn,
    twoFactorAuthenticate,
  };
};

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(mapStateToProps, mapDispatch)(Home);
