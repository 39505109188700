import React from 'react';
import { isNil } from 'lodash';
import SascCardPersonalInformationProps from './props/SascCardPersonalInformationProps';
import './styles/SascCardPersonalInformation.css';

/**
 * SascCardPersonalInformation
 *
 * @param {string} name
 * @param {string | undefined} cpf
 * @param {string} insuranceName
 * @param {string} insuranceCardNumber
 * @return {JSX.Element}
 * @constructor
 */
export default function SascCardPersonalInformation({
  name,
  cpf,
  insuranceName,
  insuranceCardNumber,
}: SascCardPersonalInformationProps) {
  return (
    <div>
      <div className="title">Informações Pessoais</div>
      <div className="text">{name}</div>
      {!isNil(cpf) && <div className="text">{`CPF: ${cpf}`}</div>}
      <div className="text">{`Convênio: ${insuranceName}`}</div>
      <div className="text">{`Carteirinha: ${insuranceCardNumber}`}</div>
      {/* <div className="text">
        {`Caráter: ${character}`}
      </div> */}
      {/* <div className="text">
        {`Número da Guia: ${guideNumber}`}
      </div> */}
    </div>
  );
}
