/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import SascText from '../../../../text/SascText.tsx';
import * as styles from './styles/sascCardTreatmentsInformationsAppointmentStyles.css';
import SascCardTreatmentsInformationsProps from '../interface/SascCardTreatmentsInformationsProps';

/**
 * sascCardTreatmentsInformationsAppointment
 *
 * @constructor
 */
export default function sascCardTreatmentsInformationsAppointment({
  medicalInsuranceName,
  treatmentCode,
}: Pick<
  SascCardTreatmentsInformationsProps,
  'medicalInsuranceName' | 'treatmentCode'
>) {
  return (
    <div className="">
      <SascText className="medicalInsuranceNameText">
        {medicalInsuranceName}
      </SascText>
      <SascText className="treatmentNameText">Cód: {treatmentCode}</SascText>
    </div>
  );
}
