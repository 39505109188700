import React from 'react';
import SascText from '../../../../text/SascText.tsx'; // Presuming SascText is also a React component
import PropTypes from 'prop-types'; // For PropType validation
import './styles/SascCardTreatmentsInformationsName.css';

/**
 * SascCardTreatmentsInformationsName
 *
 * @constructor
 */
export default function SascCardTreatmentsInformationsName({ name }) {
  return (
    <div className="">
      <SascText className="text">{name}</SascText>
    </div>
  );
}

SascCardTreatmentsInformationsName.propTypes = {
  name: PropTypes.string.isRequired,
};

// The styles would have to be converted to CSS and placed in the respective .css file:
// Assuming the original React Native styles looked something like this:
// const sascCardTreatmentsInformationsNameStyles = StyleSheet.create({
//   viewContainer: {
//     ...some styles
//   },
//   text: {
//     ...some styles
//   }
// });
// They would now be in a file called SascCardTreatmentsInformationsNameStyles.css like this:
// .viewContainer {
//   /* ...some converted styles */
// }
// .text {
//   /* ...some converted styles */
// }
