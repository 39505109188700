
import React, { Component } from 'react';
import Select from '../Input/InputSelect'; // Assuming Select is adapted for React JS
import './styles/tabMenuItem.css';

class TabMenuItem extends Component {
  goTo = () => {
    const { page } = this.props;
    if (page) {
      this.props.navigation.navigate(page);
    }
  };

  render() {
    const { icon, text } = this.props;
    return (
      <div className="tabMenuItem-container">
        <button className="menuItem" onClick={this.goTo}>
          <img className="icon" src={icon} alt="" style={{ backgroundColor: '#0D4E33' }} />
          <span className="menuItemText"> {text}</span>
        </button>
      </div>
    );
  }
}

export default TabMenuItem;
