/* eslint-disable */
import _ from "lodash";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { Label } from "reactstrap";
import { formatterHelper } from "../../_helpers/formatter.helper";

const InputViewEdit = ({
  component: Component,
  viewMode,
  ...nextProps
}) => {
  let visiable = true;

  let value;

  if (!nextProps.defaultValue) {
    value = formatterHelper.getNameOrHifen(
      nextProps.value,
      nextProps.defaultValue
    );
  } else {
    value = nextProps.defaultValue;
  }

  // if (_.isObject(value)) {
  //   value = _.get(value, 'description', '');
  // }

  const outerRef = useRef(null);

  const handleCopyValue = event => {

    var textArea = document.createElement("textarea");
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
  };

  let menu = [
    { label: "Copiar", icon: "icon-copy", callback: handleCopyValue, legend: 'Ctrlr+C' }
  ];


  return (
    <React.Fragment>
      {/* <CustomContext outerRef={outerRef} items={menu}></CustomContext> */}
      {visiable && (
        <React.Fragment>
          {!viewMode && <Component {...nextProps} />}
          {viewMode && (
            <React.Fragment>
              {nextProps.icone && (
                <img src={nextProps.icone} />
              )}
              {nextProps.label && (
                <Label className={`${nextProps.viewLabelClassName ? nextProps.viewLabelClassName : ""}`}
                  for={nextProps.name}>{nextProps.label}</Label>
              )}
              <p
                className={`form-view ${nextProps.viewClassName ? nextProps.viewClassName : ""
                  }`}
                ref={outerRef}
              >
                <React.Fragment>{value}</React.Fragment>
              </p>

            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default connect()(InputViewEdit);
