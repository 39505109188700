
import React, { Component } from 'react';
import { IoEyeOff, IoEye } from 'react-icons/io5';  // Using Ionicons v5

// Assuming 'theme' is similarly structured in the ReactJS version
// importa o arquivo de temas
import { theme } from '../../theme'; // The path might need to be adjusted based on your project structure
import 'react-text-mask'; // NOTE: This package might not be feature-complete compared to 'react-native-masked-text'
import './styles/inputPassword.css';


class InputPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      error: '',
    };
  }

  componentDidMount() {
    const { name, value } = this.props;
    this.onChangeText(name, value);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value, name } = this.props;
    if (value !== prevProps.value) {
      this.onChangeText(name, value);
    }
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  onChangeText = (name, value) => {
    const { required } = this.props;
    if (required && !value) {
      this.setState({ error: 'Campo Obrigatório' }, () => {
        this.props.onChangeText(name, value, this.state.error);
      });
    } else {
      if (required && (!value || value.length < 6)) {
        this.setState(
          { error: 'Senha deve ter pelo menos 6 caracteres' },
          () => {
            this.props.onChangeText(name, value, this.state.error);
          }
        );
      } else {
        this.setState({ error: '' }, () => {
          this.props.onChangeText(name, value);
        });
      }
    }
  };

  render() {
    const { showPassword, error } = this.state;
    const { name, submit, placeholder, value } = this.props;
    const inputContainerClasses = `inputContainerPassword ${submit && error ? 'formInputErrorPassword' : ''}`;

    return (
      <div className={inputContainerClasses}>
        <input
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          className="formInputPassword"          
          value={value}
          onChange={(e) => {
            this.onChangeText(name, e.target.value);
          }}
        />
        <button className="eyePassword" onClick={this.togglePasswordVisibility} style={{ border: 'none', background: 'none' }}>
          {
            showPassword ? 
              <IoEyeOff size={24} color={theme.colors.black} /> :
              <IoEye size={24} color={theme.colors.black} />
          }
        </button>
      </div>
    );
  }
}

export default InputPassword;
