import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty, some } from 'lodash';
import { apiSasc, getGuideTypes } from '../../services/api.service';
import { FormGroup } from 'reactstrap';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import './styles/printGuides.css';
import '../../themes/defaultStyles.css';
import Header from '../Navigation/Header.jsx';
import SelectInput from '../../components/inputs/SelectInput.js';
import { Buffer } from 'buffer';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const printGuideValidate = Yup.object().shape({
  guideType: ITEM_REQUIRED,
  convenio: Yup.object().required('Obrigatório'),
});

function PrintGuides() {
  const [values, setValues] = useState({ convenio: '', guideType: '' });
  const [errors, setErrors] = useState({});
  const [conveniosList, setConveniosList] = useState([]);

  useEffect(() => {
    findConvenios(0);
  }, []);

  const findConvenios = async (guideType) => {
    apiSasc.get(`/convenio/findByEstabelecimento/${0}/${guideType}`).then(
      (r) => {
        const conveniosList = r.data.map((e) => {
          const name = e.nome ? e.nome : e.sigla;
          e.label = name;
          e.nome = name;
          e.value = e.id;
          return e;
        });
        setConveniosList(conveniosList);
        setValues((prevValues) => ({ ...prevValues, convenios: r.data }));
      },
      (e) => {}
    );
  };

  const clear = () => {
    setValues({});
  };

  const handleSubmit = async () => {
    if (isEmpty(errors, true) || !some(errors)) {
      let url;

      if (values.guideType === '0') {
        url = '/sadt';
      } else if (values.guideType === '1') {
        url = '/honorario';
      } else if (values.guideType === '2') {
        url = '/consulta';
      } else if (values.guideType === '3') {
        url = '/internacao';
      }

      try {
        const response = await apiSasc.post(`/guia${url}`, values, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        });

        const base64 = Buffer.from(response.data).toString('base64');
        let asciiString = atob(base64);
        let array = new Uint8Array(
          [...asciiString].map((char) => char.charCodeAt(0))
        );

        const file = new Blob([array], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } catch (error) {
        console.error('Erro ao baixar o PDF:', error);
      }
    }
  };

  return (
    <div className="container-fluid">
      <Header />

      <div className="container my-5 my-sm-0">
        <h1 className="defaultStyles-titlePageCenter">Impressão de guias</h1>
        <Formik
          validationSchema={printGuideValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form autoComplete="off" className="row">
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={SelectInput}
                  options={getGuideTypes}
                  labelKey="label"
                  valueKey="value"
                  label={'Tipo de guia'}
                  id="guideType"
                  name="guideType"
                  placeholder="Tipo da guia"
                  type="text"
                  value={values.guideType}
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  noSize={true}
                  erroMensagem={errors.guideType}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  isMulti={false}
                  returnFullObject={true}
                  component={SelectInput}
                  options={conveniosList}
                  labelKey="sigla"
                  valueKey="id"
                  label={'Convênio'}
                  id={'convenio'}
                  name={'convenio'}
                  placeholder="Convênio"
                  type="text"
                  value={values.convenio}
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  autoComplete={false}
                  erroMensagem={errors.convenio}
                  required={true}
                />
              </FormGroup>
              <div className="defaultStyles-buttonsContainer">
                <button className="btn btn-secondary" onClick={handleSubmit}>
                  GERAR GUIA
                </button>
                <button className="btn btn-dark" onClick={clear}>
                  LIMPAR
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(PrintGuides);
