//@flow
import ptBR from "date-fns/locale/pt-BR";
import moment from "moment";
import 'moment/locale/pt-br';
import React, { PureComponent } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactInputMask from "react-input-mask";
import { Label } from "reactstrap";
import { dateHelper } from "../../_helpers/date.helper";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import "./datePicker.css";



type Props = {
  label: string,
  id: string,
  name: string,
  size?: number,
  onChange: any,
  required: boolean,
  messageValidate?: string,
  value?: any,
  placeholder: string,
  style: any,
  disabled: boolean
};

export default class DateTimePicker extends PureComponent<Props> {
  constructor(props) {
    super(props);
    var value = props.value;
    moment.locale('pt-br')
    if (this.props.showTimeSelectOnly && value) {

      value = moment(value, 'HH:mm').toDate();
    }

    this.state = { value };
  }
  componentDidMount() {
    registerLocale("pt-BR", ptBR);
    moment.locale('pt-br')
  }
  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = "#dc3545";
    }
    return {
      borderColor
    };
  };

  handleChange = (date: any) => {
    if (this.props.onChange) {
      this.setState({ value: date }, function () {
        if (this.props.format) {
          this.props.onChange(
            this.props.name,
            dateHelper.format(date, { pattern: this.props.format })
          );
        } else {
          this.props.onChange(this.props.name, date);
        }
      });
    } else {
      this.setState({ value: date });
    }
  };

  getFormat = () => {
    let format = "DD/MM/YYYY";
    if (this.props.showTimeSelectOnly) {
      format = "HH:mm";
    }
    if (!this.props.showTimeSelectOnly && this.props.showTimeSelect) {
      format = format + " HH:mm";
    }

    return format;
  };

  getValue = () => {
    let selectedValue;


    if (this.props.showTimeSelectOnly && this.state.value) {
      selectedValue = moment(this.state.value, "HH:mm").toDate();
    } else {
      selectedValue = this.state.value ? moment(this.state.value).toDate() : "";
    }
    return selectedValue;
  };
  render() {
    const { messages, name } = this.props;
    let { messageValidate } = this.props;
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <div className={`input-hour ${this.props.required ? "input-required" : "no-required"}`}>
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
              {this.props.label}
              {this.props.required && "*"}
            </Label>{" "}
          </React.Fragment>
        )}
        {this.props.helpText && (
          <React.Fragment>
            <i className="icon-duvida" id={"help_" + this.props.id} />
            <ToolTipHelp
              target={"help_" + this.props.id}
              message={this.props.helpText}
            />
          </React.Fragment>
        )}
        <DatePicker
          disabled={this.props.disabled}
          id={this.props.id}
          name={this.props.name}
          className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
          selected={this.getValue()}
          onChange={this.handleChange}
          required={this.props.required}
          locale="pt-BR"
          style={{ borderColor: "red" }}
          placeholderText={this.props.placeholder}
          onBlur={this.props.onBlur}
          customInput={<ReactInputMask mask="99:99" value={this.props.value} />}
          popperPlacement={this.props.popperPlacement}
          maxDate={this.props.maxDate ? moment(this.props.maxDate) : null}
          minDate={this.props.minDate ? moment(this.props.minDate) : null}
          showTimeSelect={this.props.showTimeSelect}
          timeIntervals={this.props.timeIntervals}
          timeFormat="HH:mm"
          timeCaption="Horas"
          dateFormat={this.getFormat()}
          showTimeSelectOnly={this.props.showTimeSelectOnly}

        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}
