import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {
  return loggedIn ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/select-role-login" replace />
  );
};
export default PrivateRoute;
