import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import App from "./App";
import RememberGate from "./pages/RememberGate";
import { store } from "./store/store";


const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <RememberGate>
      <App />
    </RememberGate>
  </Provider>
);