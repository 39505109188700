//@flow
import React, { PureComponent } from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, InputType, Label } from 'reactstrap';
import { translate } from '../../_helpers/messages.helper';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  viewPassword?: boolean,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string,
  autoComplete?: string
};

export default class FormInput extends PureComponent<Props, state> {
  _input: any;
  constructor(props) {
    super(props);

    this.state = {
      forceShowPassword: false
    };
    this.showHide = this.showHide.bind(this);
  }



  static defaultProps = {
    required: false,
    size: 12,
    disabled: false,
    viewPassword: false
  };

  getInput = () => {
    return this._input;
  };

  showHide(e) {
    this.setState({
      forceShowPassword: !this.state.forceShowPassword
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.erroMensagem !== this.props.erroMensagem) {
      this.setState({
        prevErroMensagem: prevProps.erroMensagem,
      });
    }
  }
  render() {
    const { messages, name } = this.props;

    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName === name) {
          messageValidate = translate(message.message.code);
        }
      });
    }

    if (this.props.erroMensagem) {
      console.log(this.props.erroMensagem);
      messageValidate = this.props.erroMensagem;
    }
    const value = this.props.value ? this.props.value : "";
    return (
      <div className={`${this.props.required ? 'input-required' : 'no-required'}`}>
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name} className={`label-nowrap  ${this.props.labelClassName ? this.props.labelClassName : ""}`} title={this.props.label}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
          </React.Fragment>
        )}

        {this.props.helpText && (
          <React.Fragment>
            <i className="icon-duvida" id={'help_' + this.props.id} />
            <ToolTipHelp
              target={'help_' + this.props.id}
              message={this.props.helpText}
            />
          </React.Fragment>
        )}
        <div className={`${this.props.viewPassword ? 'input-group' : ''} ${this.props.className} ${messageValidate ? 'is-invalid' : ''
          }`}>
          <Input
            type={!this.state.forceShowPassword ? this.props.type : 'text'}
            id={this.props.id ? this.props.id : this.props.name}
            autoComplete={this.props.autoComplete ? this.props.autoComplete : 'new-password'}
            name={this.props.name}
            value={value}
            onBlur={this.props.onBlur}
            onChange={e => {
              const { name, type, value, checked } = e.currentTarget;
              this.props.onChange(name, value, type, checked);
            }}
            required={this.props.required}
            placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
            disabled={this.props.disabled ? this.props.disabled : false}
            maxLength={this.props.maxLength}

            onKeyPress={this.props.onKeyPress}
            className={`${this.props.className} ${messageValidate ? 'is-invalid' : ''
              }`}
          >
            {this.props.children}
          </Input>
          {this.props.viewPassword && (
            // <InputGroupAddon addonType="append">
            <div className='input-group-append'>
              <InputGroupText>
                {' '}
                <span
                  className={
                    this.state.forceShowPassword ? 'icon-ocultar' : 'icon-ver'
                  }
                  onClick={this.showHide}
                ></span>
              </InputGroupText>
            </div>
          )}
        </div>

        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id ? this.props.id : this.props.name}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   const { messages } = state.fieldMessage;
//   const { alert } = state;

//   return {
//     messages,
//     alert
//   };
// }

// export default connect(mapStateToProps)(FormInput);
