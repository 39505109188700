// ImageUploader.js
import React from 'react';
import './ImageUploader.css';

const ImageUploader = ({ images, handleAddImage, handleRemoveImage, showButton }) => {
    return (
        <div className="image-uploader mb-3">
            <div className={`${showButton ? '' : 'd-none'}`}>
                <p>Inserir anexos</p>
                <label className="custom-file-upload">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleAddImage}
                        multiple
                    />
                    Selecionar Imagens
                </label>
            </div>

            <div className='row'>
                {images && images.map((image, index) => (
                    <div key={index} className="col-12 col-md-2 mb-3">
                        <div className="fd-imagem d-flex flex-column align-items-center justify-content-between p-2">
                            <img
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index}`}
                                className='img-fluid'
                            />
                            <div className={`${showButton ? '' : 'd-none'}`}>
                                <button className='btn btn-sm btn-danger' onClick={() => handleRemoveImage(index)}>Excluir</button>
                            </div>
                        </div>
                    </div>
                ))}

            </div>
        </div >
    );
};

export default ImageUploader;
