import { Form, Formik } from 'formik';
import { get, isNil } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput.js';
import { urlConstants } from '../../constants/url.constants';
import {
  apiConnect,
  apiDynamic,
  getGuideTypes,
  oauthHeaderJson,
} from '../../services/api.service';
import './style/medicalCare.css';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const medicalCareValidate = Yup.object().shape({
  guideType: ITEM_REQUIRED,
  localHospital: ITEM_REQUIRED,
  healthInstitute: ITEM_REQUIRED,
});

const typesGuidesWithLocalHospital = ['0', '1'];

const MedicalCareFirstStep = forwardRef(
  (
    {
      values,
      system,
      errors,
      handleChange,
      loading,
      error,
      converterSascTreatmentsCreateSaveToContext,
      handleSubmit,
    },
    ref
  ) => {
    console.log('Valor de ref em MedicalCareFirstStep:', ref);
    const [listHospital, setListHospital] = useState([]);
    const [estabelecimentos, setEstabelecimentos] = useState([]);

    const validateStep = () => {
      console.log('valida passo 1');
      let isValid = true;
      const FIELD_REQUIRED = 'Campo obrigatório';
      if (!values.guideType) {
        handleChange('guideType', values.guideType, FIELD_REQUIRED);
        isValid = false;
      }
      if (!values.healthInstitute && system === 'SASCCONNECT') {
        handleChange('healthInstitute', values.healthInstitute, FIELD_REQUIRED);
        isValid = false;
      }
      return isValid;
    };

    useImperativeHandle(ref, () => ({
      validateStep: validateStep,
    }));

    useEffect(() => {
      console.log(5);
      findEstabelecimentos();
      if (get(values, 'guideType') != null) {
        findHospitalLocal(values.guideType);
      }
    }, []);

    const findEstabelecimentos = async () => {
      console.log('findEstabelecimentos');
      const headers = await oauthHeaderJson();
      loading(true);
      apiDynamic
        .post('/atendimento/getClinicaByFiltroAtendimento', {}, { headers })
        .then(
          (r) => {
            setEstabelecimentos(
              r.data.map((e) => {
                e.name = e.nome;
                e.label = e.nome;
                e.value = e.id;
                return e;
              })
            );
            loading(false);
          },
          (e) => {
            console.log(e);
            loading(false);
          }
        );
    };

    const findHospitalLocal = async (guideType) => {
      console.log('findHospitalLocal');
      const headers = await oauthHeaderJson();
      loading(true);
      apiDynamic
        .get(`/hospitalLocal/findByTipoGuia/${guideType}`, { headers })
        .then(
          (r) => {
            setListHospital(
              r.data.map((e) => {
                e.name = e.nome;
                e.label = e.nome;
                e.value = e.id;
                return e;
              })
            );
            loading(false);
          },
          (e) => {
            console.log(e);
            loading(false);
          }
        );
    };

    const converterToMidiaVo = (imageGuide) => {
      if (imageGuide.length > 0) {
        return {
          nome: imageGuide[0].fileName,
          data: imageGuide[0].base64.replace('data:image/png;base64,', ''),
        };
      }
      return {};
    };

    const converteGuia = (imageGuide) => {
      loading(true);
      handleChange('imageGuide', imageGuide);
      const cnpjHospital =
        !isNil(values.localHospital) && values.localHospital.cnpj
          ? values.localHospital.cnpj
          : '0';

      apiConnect
        .post(
          urlConstants.IMAGE_GUIDE + cnpjHospital,
          converterToMidiaVo(imageGuide)
        )
        .then(
          (response) => {
            loading(false);
            converterSascTreatmentsCreateSaveToContext(
              response.data,
              imageGuide
            );
          },
          (err) => {
            console.log(JSON.stringify(err));
            error('Ocorreu um erro ao obter os dados da imagem!');
            loading(false);
          }
        );
    };

    const getDisplayLocalHospital = (
      guideType,
      typesGuidesWithLocalHospital
    ) => {
      return !isNil(guideType) &&
        typesGuidesWithLocalHospital.includes(guideType)
        ? 'block'
        : 'none';
    };

    return (
      <Formik
        validationSchema={medicalCareValidate}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form autoComplete="off" className="row">
            <FormGroup className="col-12 col-sm-6">
              <InputViewEdit
                component={SelectInput}
                options={getGuideTypes}
                labelKey="label"
                valueKey="value"
                placeholder="Tipo da guia"
                id="guideType"
                name="guideType"
                value={values.guideType}
                onChange={(name, value) => {
                  findHospitalLocal(value);
                  handleChange(name, value);
                }}
                erroMensagem={errors.guideType}
                beforeAnotherDropDown={true}
              />
            </FormGroup>

            <FormGroup
              className="col-12 col-sm-6"
              style={{
                display: getDisplayLocalHospital(
                  values.guideType,
                  typesGuidesWithLocalHospital
                ),
              }}
            >
              <InputViewEdit
                component={SelectInput}
                options={listHospital}
                placeholder="Hospital Local"
                name="localHospital"
                id="localHospital"
                returnFullObject={true}
                labelKey="label"
                valueKey="value"
                value={values.localHospital}
                onChange={(name, value) => {
                  console.log(name + ': ' + value);
                  handleChange(name, value);
                }}
                erroMensagem={errors.localHospital}
              />
            </FormGroup>
            {/* {system === 'SASCCONNECT' &&
            values.guideType &&
            {
             <FormGroup className="col-12 col-sm-6">
              <SascAttachment
                setValue={async (value, index) => {
                  if (!isEmpty(value)) {
                    converteGuia(value);
                  } else {
                    handleChange('imageGuide', value);
                  }
                }}
                disabled={values.imageGuide.length > 1}
                type={SascAttachmentTypeEnum.IMAGE}
                titleButtom={
                  values.imageGuide.length >= 1
                    ? 'Continuação da guia'
                    : 'Foto da Guia'
                }
                specificProps={{
                  imageType: SascAttachmentImageTypeEnum.CAMERA,
                }}
                imageList={values.imageGuide}
                alert={values.imageGuide.length >= 1 ? true : false}
              /> 
            </FormGroup>
            }} */}
            {system === 'SASCCONNECT' && (
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={SelectInput}
                  options={estabelecimentos}
                  placeholder="Estabelecimento"
                  name="healthInstitute"
                  id="healthInstitute"
                  returnFullObject={true}
                  labelKey="label"
                  valueKey="value"
                  value={values.healthInstitute}
                  onChange={(name, value) => {
                    console.log(name + ': ' + value);
                    handleChange(name, value);
                  }}
                  erroMensagem={errors.healthInstitute}
                />
              </FormGroup>
            )}
          </Form>
        )}
      </Formik>
    );
  }
);

export default MedicalCareFirstStep;
