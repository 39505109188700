import React from 'react';
import { IoIosAlert } from 'react-icons/io';
import ControlledTooltip from '../../components/Tooltip/ControlledTooltip';
import './styles/card.css';

function Card({
  label,
  image,
  imageStyle,
  action,
  height,
  helpText,
  className,
}) {
  return (
    <div className={className}>
      <div className="cardContainer_home" onClick={action}>
        <div className="descriptionContainer_card">
          <img
            src={image}
            className="logo_card"
            alt={label}
            style={{ ...imageStyle }}
          />
          <span className="label_card">{label}</span>
        </div>
        <div className="mx-2">
          <ControlledTooltip
            width={window.innerWidth - 20}
            height={height}
            helpText={helpText}
          >
            <div>
              <IoIosAlert size={25} color="#0b7a5e" />
            </div>
          </ControlledTooltip>
        </div>
      </div>
    </div>
  );
}

export default Card;
