import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { AntDesignOutlinedOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import SascCard from '../../components/card/SascCard.tsx';
import { apiConnect, apiDynamic } from '../../services/api.service';
import { isEmpty, isArray } from 'lodash';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Navigation/Header.jsx';
import { AiOutlineLeft } from 'react-icons/ai';
import './styles/searchResult.css';

function SearchResult({ loading, error }) {
  const location = useLocation();
  const [filtro, setFiltro] = useState(
    location.state && location.state ? location.state : {}
  );
  const [listItems, setListItems] = useState([]);
  const [atualizou, setAtualizou] = useState(false);
  const navigate = useNavigate();

  // setFiltro({ medicalInsurance: filtro.medicalInsurance.idEntidade })

  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate.goBack();
    };

    window.addEventListener('popstate', handleBackButtonClick);
    getListItems();

    return () => {
      window.removeEventListener('popstate', handleBackButtonClick);
    };
  }, []);

  const getListItems = async () => {
    loading(true);
    console.log(filtro);
    apiDynamic.post(`/app/atendimento/list`, filtro).then(
      (r) => {
        console.log(r, filtro);
        setListItems(r.data);
        setAtualizou(true);
        loading(false);
      },
      (e) => {
        console.error(e);
        setAtualizou(true);
        error('Ocorreu um erro ao obter os dados! Tente mais tarde.');
        loading(false);
      }
    );
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid">
      <Header />
      <div className="defaultStyles-headerInput">
        <div className="defaultStyles-halfBack">
          <button
            className="defaultStyles-NewBackButton"
            onClick={handleBackButton}
          >
            <span className="defaultStyles-newBackButtonText">
              <AiOutlineLeft name={'left'} />
              Voltar
            </span>
          </button>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {isEmpty(listItems) && atualizou && (
            <p className="textCenterBold">
              Não existem registros para serem exibidos.
            </p>
          )}
          {listItems &&
            isArray(listItems) &&
            listItems.map((item, index) => (
              <SascCard
                className={'col-12 col-md-4 my-4'}
                key={index}
                type={{
                  informationProps: item,
                }}
                buttonProps={{
                  onClick: () => navigate('/TreatmentsView', { state: item }),
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

SearchResult.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.func.isRequired,
};

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(SearchResult);
