
import React, { Component } from 'react';
// import themes, { theme } from '../../theme';
import './styles/inputEmail.css';

class InputEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
        }
    }

    componentDidMount() {
        const { name, value } = this.props;
        this.onChangeText(name, value);
    }

    onChangeText = (name, value) => {
        const { required } = this.props;
        if (required && !value) {
            this.setState({ error: 'Campo Obrigatório' }, () => {
                this.props.onChange(name, value, this.state.error);
            })
        } else {
            if (!value || !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
                this.setState({ error: 'Email inválido' }, () => {
                    this.props.onChange(name, value, this.state.error);
                })
            } else {
                this.setState({ error: '' }, () => {
                    this.props.onChange(name, value);
                })
            }
        }
    }

    render() {
        const { value, placeholder, name, submit } = this.props;
        const { error } = this.state;

        return (
            <input
                className={`formInputEmail ${submit && error ? 'formInputErrorEmail' : ''}`}
                value={value}
                placeholder={placeholder}
                onChange={(e) => { this.onChangeText(name, e.target.value) }}
                type="email"
            />
        );
    }
}

export default InputEmail;
