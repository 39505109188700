
/**
 * Enum sasc circle
 *
 */
const SascCircleTypeEnum = {
  GREEN: '#6EA753',
  YELLOW: '#E7E168',
  GRAY: '#C4C4C4',
}

/**
 * Export default.
 *
 */
export default SascCircleTypeEnum;

export function getTypeEnumValue(key) {
  const indexOf = Object.keys(SascCircleTypeEnum).indexOf(key);
  return Object.values(SascCircleTypeEnum)[indexOf];
}
