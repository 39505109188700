import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './styles/SascCircle.css';
import { green } from '@mui/material/colors';

/**
 * Circle component
 *
 * @param type
 * @param style
 * @param size
 * @constructor
 */
export default function SascCircle({ type }) {
  console.log(type);

  return (
    <div>
      <div className="sinal" style={{ backgroundColor: type }}></div>
    </div>
  );
}
