import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, cloneDeep, omit } from 'lodash';
import { usersService } from '../../services/users.service';
import { MdEdit } from 'react-icons/md';
import './styles/perfil.css';
import '../../themes/defaultStyles.css';
import { useNavigate } from 'react-router-dom';
import Header from '../Navigation/Header.jsx';

function PerfilPage({ loading }) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      loading(true);
      const response = await usersService.getUser();
      const data = response.data;
      setUser(data);
      loading(false);
    } catch (error) {
      console.error(error);
      error('Ocorreu um erro ao obter os dados do perfil! Tente mais tarde.');
      loading(false);
    }
  };

  const editUser = (user) => {
    const newUser = cloneDeep(omit(user, 'password'));
    navigate(`/perfil/${newUser.id}`, { state: newUser });
  };

  return (
    <div className="container-fluid">
      <Header />
      <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">Perfil</h1>
      <div className="container">
        <div className="tableCardPerfilPage">
          <div
            className={`${'defaultStyles-rowLayoutCard'} profile-row-layout`}
          >
            <div className="defaultStyles-fullWidth">
              <div className="defaultStyles-tableCellCard">
                <div className="defaultStyles-rowCard">
                  <strong className="defaultStyles-boldText">Nome:</strong>
                  <span> {get(user, 'name')}</span>
                </div>
              </div>
              <div
                className="defaultStyles-tableCellCard"
                style={{ paddingTop: '15px' }}
              >
                <div className="defaultStyles-rowCard">
                  <strong className="defaultStyles-boldText">CPF:</strong>
                  <span> {get(user, 'cpf')}</span>
                </div>
              </div>
            </div>
            <div className="defaultStyles-iconsCard">
              <button
                onClick={() => editUser(user)}
                className="edit-user-button"
              >
                <MdEdit className="defaultStyles-iconCard" size={'24px'} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(PerfilPage);
