import React from 'react';
import './styles/switch.css';

const Switch = ({ isOn, handleToggle }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={isOn} onChange={handleToggle} />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
