import axios from 'axios';
import { encode as btoa } from 'base-64';
import { urlConstants } from '../constants/url.constants';
import { apiSasc, loginConnect } from './api.service';

export const authenticationService = {
  login,
  doLogout,
  getUser,
  setUser,
  setTwoFactorAuthenticate,
  getTwoFactorAuthenticate,
  getCredential,
  getSystem,
  updateSystem,
  setEntidade,
  getEntidade,
  setDefaultSystem,
  getDefaultSystem,
  hasToken,
};

async function updateSystem(sistemVO) {
  return apiSasc.post(`${urlConstants.GET_SYSTEM}`, sistemVO);
}

async function getSystem() {
  return apiSasc.get(`${urlConstants.GET_SYSTEM}`);
}

function login(username, password, saveCredential, type) {
  const client_id = `leonardoBernardino`;
  const grant_type = `password`;
  const module = type;
  const details = {
    client_id,
    grant_type,
    username,
    password,
    module,
  };
  const formBody = Object.keys(details).map(
    (key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])
  );

  const basicAuthorization = btoa(username + ':' + password);

  return new Promise((resolve, reject) => {
    return axios({
      url: `${urlConstants.LOGIN}`,
      method: 'POST',
      data: formBody.join('&'),
      headers: {
        Authorization: 'Basic ' + basicAuthorization,
      },
    })
      .then(async (response) => {
        console.log('UserData');
        console.log(response.data);
        try {
          await loginConnect(username, password);
        } catch (e) {}
        localStorage.setItem('jwt', JSON.stringify(response.data));
        if (saveCredential) {
          console.log('saveCredential');
          localStorage.setItem(
            'credential',
            JSON.stringify({ username, password })
          );
        }
        await saveUserlocalStorage();
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function doLogout() {
  console.log('doLogout');
  const cred = await authenticationService.getCredential();
  localStorage.clear();
  if (cred) {
    console.log('mantem credential');
    console.log(cred);
    localStorage.setItem(
      'credential',
      JSON.stringify({ username: cred.username, password: cred.password })
    );
  }
}

async function saveUserlocalStorage() {
  const jwt = localStorage.getItem('jwt');
  const { token } = JSON.parse(jwt);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const user = await axios.get(`${urlConstants.GET_PRINCIPAL}`, { headers });
  const coop = await apiSasc.get(`${urlConstants.GET_COOP}`, { headers });
  await setUser(user.data);
  await setEntidade(coop.data.portalEntidadeCooperado);
}

async function setUser(user) {
  let userStr = typeof user === 'string' ? user : JSON.stringify(user);
  localStorage.setItem('user', userStr);
}

async function getUser() {
  return localStorage.getItem('user');
}

async function setEntidade(entidade) {
  localStorage.setItem('entidade', JSON.stringify(entidade));
}

function hasToken() {
  return (
    localStorage.getItem('user') !== null &&
    localStorage.getItem('twoFactorAuthenticate') != null
  );
}

async function getEntidade() {
  return localStorage.getItem('entidade');
}

async function getCredential() {
  const credential = localStorage.getItem('credential');
  if (credential) {
    return JSON.parse(credential);
  }
}

async function getTwoFactorAuthenticate() {
  return localStorage.getItem('twoFactorAuthenticate');
}

function setTwoFactorAuthenticate(twoFactorAuthenticate) {
  return localStorage.setItem('twoFactorAuthenticate', twoFactorAuthenticate);
}

async function setDefaultSystem(defaultSystem) {
  localStorage.setItem('defaultSystem', JSON.stringify(defaultSystem));
}

async function getDefaultSystem() {
  return localStorage.getItem('defaultSystem');
}
