import React from 'react';
import Card from '@mui/material/Card';
import { has } from 'lodash';
import SascCardTables from '../card-tables/SascCardTables.tsx';
import SascCardTablesProps from '../card-tables/interface/SascCardTablesProps';
import SascCardTreatments from '../card-treatments/SascCardTreatments.tsx';
import SascCardTreatmentsProps from '../card-treatments/interface/SascCardTreatmentsProps';
import SascCardProps from '../interface/SascCardProps';
import SascCardDashboardProps from '../card-dashboard/interface/SascCardDashboardProps';
import SascCardDashboard from '../card-dashboard/SascCardDashboard.tsx';
import SascCardAttachmentProps from '../card-attachment/interface/SascCardAttachmentProps';
import SascCardAttachment from '../card-attachment/SascCardAttachment.tsx';
import SascCardPersonalInformation from '../card-personal-information/SascCardPersonalInformation.tsx';
import SascCardPersonalInformationProps from '../card-personal-information/props/SascCardPersonalInformationProps';
import SascCardTreatmentInformationProps from '../card-treatment-information/props/SascCardTreatmentInformationProps';
import SascCardTreatmentInformation from '../card-treatment-information/SascCardTreatmentInformation.tsx';
import SascCardProcedureInformationProps from '../card-procedure-information/props/SascCardProcedureInformationProps';
import SascCardProcedureInformation from '../card-procedure-information/SascCardProcedureInformation.tsx';
import './styles/sascCardCore.css'; // Assuming the styles have been converted to CSS and imported

export type elementTypeString =
  | 'SascCardTables'
  | 'SascCardTreatments'
  | 'SascCardDashboard'
  | 'SascCardAttachmentProps'
  | 'SascCardPersonalInformation'
  | 'SascCardTreatmentInformation'
  | 'SascCardProcedureInformation'
  | 'React.ReactElement';

export type elementType =
  | SascCardTablesProps
  | SascCardTreatmentsProps
  | SascCardDashboardProps
  | SascCardAttachmentProps
  | SascCardPersonalInformationProps
  | SascCardTreatmentInformationProps
  | SascCardProcedureInformationProps
  | React.ReactElement;

const getElementType = (type: elementType): elementTypeString => {
  if (!React.isValidElement(type)) {
    if (has(type, 'codigo')) {
      return 'SascCardTables';
    }
    if (has(type, 'informationProps')) {
      return 'SascCardTreatments';
    }
    if (has(type, 'totalPercent')) {
      return 'SascCardDashboard';
    }
    if (has(type, 'uriImage')) {
      return 'SascCardAttachmentProps';
    }
    if (has(type, 'insuranceCardNumber')) {
      return 'SascCardPersonalInformation';
    }
    if (has(type, 'guideType')) {
      return 'SascCardTreatmentInformation';
    }
    if (has(type, 'index')) {
      return 'SascCardProcedureInformation';
    }
  }
  return 'React.ReactElement';
};

const getChildren = (
  elementType: elementTypeString,
  type: elementType
): React.ReactElement => (
  <>
    {elementType === 'SascCardTables' && (
      <SascCardTables {...(type as SascCardTablesProps)} />
    )}
    {elementType === 'SascCardTreatments' && (
      <SascCardTreatments {...(type as SascCardTreatmentsProps)} />
    )}
    {elementType === 'SascCardDashboard' && (
      <SascCardDashboard {...(type as SascCardDashboardProps)} />
    )}
    {elementType === 'SascCardAttachmentProps' && (
      <SascCardAttachment {...(type as SascCardAttachmentProps)} />
    )}
    {elementType === 'SascCardPersonalInformation' && (
      <SascCardPersonalInformation
        {...(type as SascCardPersonalInformationProps)}
      />
    )}
    {elementType === 'SascCardTreatmentInformation' && (
      <SascCardTreatmentInformation
        {...(type as SascCardTreatmentInformationProps)}
      />
    )}
    {elementType === 'SascCardProcedureInformation' && (
      <SascCardProcedureInformation
        {...(type as SascCardProcedureInformationProps)}
      />
    )}
    {elementType === 'React.ReactElement' && type}
  </>
);

export default function SascCardCore({ type }: SascCardProps) {
  const elementType = getElementType(type);

  return <Card className="card-custom">{getChildren(elementType, type)}</Card>;
}
