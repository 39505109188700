
import 'intl';
import 'intl/locale-data/jsonp/pt-BR';

/**
 * Get Formatter
 *
 * @param locale
 * @param currency
 */
const getFormatter = (locale = 'pt-BR', currency = 'BRL') =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

/**
 * Format price
 *
 * @param price
 * @param locale
 * @param currency
 */
export default function formatPrice(
  price,
  locale = 'pt-BR',
  currency = 'BRL'
) {
  if (price) {
    return getFormatter(locale, currency).format(price);
  }
  return '';
}
