import React, { useState } from 'react';
import { cloneDeep, isEmpty, set, some } from 'lodash';
import InputPhone from '../../components/Input/InputPhone';
import { twoFactorAuthenticationService } from '../../services/twoFactorAuthentication.service';
import './styles/registerCodeAccessSmsPage.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

function RegisterCodeAccessSmsPage({ loading, success, error }) {
  const [state, setState] = useState({
    values: {},
    errors: {},
    submit: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (name, value, error) => {
    setState((prevState) => {
      const values = cloneDeep(prevState.values);
      const errors = cloneDeep(prevState.errors);
      set(values, name, value);
      set(errors, name, error);
      return { values, errors };
    });
  };

  const handleSubmit = () => {
    const mfaToken = location.state || {};
    if (isEmpty(state.errors, true) || !some(state.errors)) {
      const { values } = state;
      twoFactorAuthenticationService
        .authenticationSms(values.phone, mfaToken)
        .then(
          (r) => {
            success({ message: 'Telefone registrado com sucesso!'});
            navigate('/code-login-confirm', {
              mfaToken,
              authenticator_gate: true,
              // authenticatorType: 'sms_otp',
            });
          },
          (e) => {
            console.log(e);
            error({ message: 'Telefone inválido!' });
          }
        );
    } else {
      setState({ ...state, submit: true });
    }
  };

  const { values, errors, submit } = state;

  return (
    <div
      className="defaultStyles-mainContainer"
      style={{ flexDirection: 'column' }}
    >
      <div className="codeSmsPage_logoContainer">
        <img
          className="codeSmsPage_logo"
          src={require('../../assets/logo.png')}
          alt="Logo"
        />
      </div>
      <div className="codeSmsPage_containerTitle">
        <h1 className="codeSmsPage_title">Registro de Código de Acesso</h1>
        <p className="codeSmsPage_subtitle">
          Por favor, confirme o seu telefone para onde o Código de Acesso será
          enviado.
        </p>
      </div>
      <div className="codeSmsPage_inputContainer">
        <label className="inputLabel">Celular</label>
        <InputPhone
          onChange={handleChange}
          submit={submit}
          name={'phone'}
          required={true}
          value={values.phone || ''}
        />
        <p
          className={`formHelperText ${
            submit && errors['phone'] ? 'formErrorText' : ''
          }`}
        >
          {submit && errors['phone'] ? errors['phone'] : ''}
        </p>
        <div className="codeSmsPage_buttonContainer">
          <button className="codeSmsPage_buttonEnviar" onClick={handleSubmit}>
            ENVIAR CÓDIGO
          </button>
        </div>
      </div>
    </div>
  );
}
const mapDispatch = ({ alert: { success, error, clear, info } }) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch)(RegisterCodeAccessSmsPage);
