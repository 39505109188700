// Assuming urlConstants and apiSasc behave the same way in React as they do in React Native
import { urlConstants } from '../constants/url.constants';
import { apiDynamic, apiSasc, apiConnect } from './api.service';

export const procedimentosService = {
    findProcedures
};

async function findProcedures(name) {
    return apiConnect.get(`/procedimento/${name}`,);
}

