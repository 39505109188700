import React, { Component } from 'react';
import './styles/inputCheckbox.css';
import { IoIosCheckboxOutline } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

class InputCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || false,
    };
  }

  componentDidUpdate(prevProps) {
    // Atualize o estado se a propriedade `checked` mudar
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handlePress = () => {
    const { checked } = this.state;
    const { onChange, name } = this.props;

    this.setState({ checked: !checked }, () => {
      if (onChange) {
        onChange(name, !checked);
      }
    });
  };

  render() {
    const { checked } = this.state;
    const { label } = this.props;

    return (
      <div className="containerCheckbox" onClick={this.handlePress}>
        <div className="checkbox">
          {checked ? (
            <IoIosCheckboxOutline className="checkboxIconChecked" />
          ) : (
            <MdCheckBoxOutlineBlank className="checkboxIcon" />
          )}
        </div>
        <span className="labelTextCheckbox">{label}</span>
      </div>
    );
  }
}

export default InputCheckbox;
