import React, { Component } from 'react';
import './styles/customModalDetails.css';
import { translator } from '../../utils/string-utils.tsx';
import formatPrice from '../../utils/currency-utils.tsx';
import { formatStringDate } from '../../utils/date-utils.tsx';
import defaultStyles from '../../pages/DefaultStyles/default-styles.tsx';

class CustomModalDetails extends Component {
  renderDados = (key, label, texto, index) => {
    return (
      <div
        key={key}
        className={`${index % 2 === 0 ? 'evenRowCardGreen' : 'oddRowCard'} p-1`}
      >
        <span className="bold">
          {label}:{' '}
          <span className="normal">
            {key === 'data' ? formatStringDate(texto) : texto}
          </span>
        </span>
      </div>
    );
  };

  render() {
    const { visible, onClose, content, numeroRepasse } = this.props;

    return (
      <div className={visible ? `${'modal'}` : `${'hidden'}`}>
        <div className="centeredView">
          <div className="modalView col-12 offset-sm-4 col-sm-6">
            <span className="title">
              Detalhes do repasse: Nº {numeroRepasse}
            </span>
            <div className={`${'modalText'}`}>
              {content &&
                content.map((item, index) => {
                  return (
                    <div className="itemContainer" key={index}>
                      <div className="header-payment">
                        <div className=" d-flex align-items-center justify-content-center">
                          <span className="headerText">{item.paciente}</span>
                        </div>
                        <div className=" d-flex align-items-center justify-content-between">
                          <span className="normal headerText">
                            <span className="bold">Lançamento:</span>{' '}
                            {item.lancamento}
                          </span>
                          <span className="normal headerText">
                            <span className="bold">Valor: </span>
                            {formatPrice(item.valor)}
                          </span>
                        </div>
                      </div>
                      <div>
                        {item &&
                          Object.keys(item.atendimentos).map((key, index) => {
                            return this.renderDados(
                              key,
                              translator[key],
                              item.atendimentos[key],
                              index
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
            <button
              className={`${'button'} ${'buttonClose'}`}
              onClick={() => {
                onClose();
              }}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomModalDetails;
