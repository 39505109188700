//@flow
type State = {
  load: boolean
};

type Payload = {
  load: boolean
};

const initialState: State = { load: false };

const reducers = {
  loading: (state: State, payload: Payload) => {
    return {
      ...state,
      load: payload.load
    };
  }
};

const exportedObject = {
  state: initialState,
  reducers: reducers
};

export default exportedObject;
