import React, { Component } from 'react';
import './styles/buttonPrimary.css'; // Assuming you have a CSS file for your theme

/**
 * @component
 * @description Um botão primário personalizável
 *  .
 * @typedef {Object} Props
 * @prop {string} text - O texto do botão.
 * @prop {object} style - estilo personalizavel para o botão.
 * @prop {function} onPress - A função a ser chamada quando o botão é pressionado.
 *
 * @param {Props} props - As propriedades do botão.
 * @returns {JSX.Element} - O elemento de botão primário.
 * @example
 *  <ButtonPrimary text={'teste'}/>
 */

class ButtonPrimary extends Component {
  render() {
    const { style, onPress, text, id } = this.props;

    return (
      <button className="buttonPrimary" style={style} onClick={onPress} id={id}>
        <div className="textPrimary">{text}</div>
      </button>
    );
  }
}

export default ButtonPrimary;
