
/**
 * Export formatted CPF/CNPJ
 *
 * @param text
 */
export default function formatCpfCnpj(text) {
  let cpfCnpj = text.replace(/\D/g, '');

  if (cpfCnpj.length <= 11) {
    cpfCnpj = cpfCnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cpfCnpj = cpfCnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cpfCnpj = cpfCnpj.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    cpfCnpj = cpfCnpj.replace(/^(\d{2})(\d)/, '$1.$2');
    cpfCnpj = cpfCnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cpfCnpj = cpfCnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cpfCnpj = cpfCnpj.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return cpfCnpj;
}

export function onlyNumbers(text) {
  let numeros = text.replace(/[^0-9]/g, '');

  return numeros;
}
