import React from 'react';
import { getTypeEnumValue } from '../../../circle/enums/SascCircleTypeEnum.tsx';
import SascCircle from '../../../circle/SascCircle.tsx';
import SascCardTreatmentsCircleIconProps from './interface/SascCardTreatmentsCircleIconProps';

/**
 * SascCardTreatmentsCircleIcon
 *
 * @constructor
 */
export default function SascCardTreatmentsCircleIcon({
  type,
}: SascCardTreatmentsCircleIconProps) {
  return (
    <div className="mx-2">
      <SascCircle type={getTypeEnumValue(type)} />
    </div>
  );
}
