import React, { Component } from 'react';
import './styles/inputSelect.css';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options || [],
      selectedOption: null,
      modalVisible: false,
    };
  }
  componentDidMount() {
    if (this.props.service) {
      this.props.service().then((r) => {
        this.setState({ options: r.data });
      });
    }
  }

  handleOptionPress = (option) => {
    this.setState({ selectedOption: option, modalVisible: false });
    this.props.onSelect(option);
  };

  renderOption = ({ item }) => {
    const { labelKey, valueKey } = this.props;
    return (
      <div
        className="option"
        onClick={() => this.handleOptionPress(item)}
      >
        <span className="optionText">
          {labelKey ? item[labelKey] : item.label}
        </span>
      </div>
    );
  };

  render() {
    const { options, selectedOption, modalVisible } = this.state;
    const { renderValue } = this.props;

    return (
      <div className="container">
        <div
          className="button"
          onClick={() => this.setState({ modalVisible: true })}
        >
          {renderValue && renderValue(selectedOption)}
          {!renderValue && (
            <span
              className={`buttonText ${selectedOption ? 'selectedOptionText' : ''}`}
            >
              {selectedOption
                ? selectedOption.label
                : this.props.placeholder || 'SELECIONE UMA OPÇÃO'}
            </span>
          )}
        </div>
        {modalVisible && (
          <div className="modal" onClick={() => this.setState({ modalVisible: false })}>
            <div className="modalContent" onClick={(e) => e.stopPropagation()}>
              {options.map((item, index) => this.renderOption({ item, index }))}
            </div>
          </div>
        )}
        <div className="cardTriangle" />
      </div>
    );
  }
}

export default Select;
