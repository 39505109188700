
import React, { Component } from 'react';
import './styles/buttonSecondary.css';

/**
 * A customizable secondary button.
 *
 * @typedef {Object} Props
 * @prop {string} text - The text of the button.
 * @prop {object} style - Customizable style for the button.
 * @prop {function} onPress - The function to be called when the button is pressed.
 *
 * @param {Props} props - The properties of the button.
 * @returns {JSX.Element} - The primary button element.
 * @throws {Error} - If `props.label` is not provided.
 */

class ButtonSecondary extends Component {
    render() {
        const { style, onPress, text, id } = this.props;

        return (
            <button className="buttonSecondary" style={{...style}} onClick={onPress} id={id}>
                <span className="textSecondary">{text}</span>
            </button>
        );
    }
}

export default ButtonSecondary;
