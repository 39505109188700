import React from 'react';
import formatCpfCnpj from '../../../../../utils/identifier-utils.tsx';
import SascText from '../../../../text/SascText.tsx';
import SascCardTreatmentsInformationsProps from '../interface/SascCardTreatmentsInformationsProps';
import './styles/SascCardTreatmentsInformationsIdentifier.css';

// const identifierIcon = require('../../../../../../assets/icons/specific/identifier-icon.png');
const cardNumberIcon = require('../../../../../assets/icons/specific/card-number.png');

/**
 * SascCardTreatmentsInformationsIdentifier
 *
 * @constructor
 */
export default function SascCardTreatmentsInformationsIdentifier({
  identifier,
  identifierCardNumber,
}: Pick<
  SascCardTreatmentsInformationsProps,
  'identifier' | 'identifierCardNumber'
>) {
  return (
    <div className="">
      {identifier != null && (
        <>
          {/* <img alt="Identifier Icon" src={identifierIcon} className="identifierIcon" /> */}
          <SascText className="identifierText">
            {formatCpfCnpj(identifier)}
          </SascText>
        </>
      )}
      <img
        alt="Card Number Icon"
        src={cardNumberIcon}
        className="cardNumberIcon"
      />
      <SascText className="cardNumberText">{identifierCardNumber}</SascText>
    </div>
  );
}
