import { urlConstants } from '../constants/url.constants';
import { apiSasc } from './api.service';

export const glossedGuidesService = {
  findByMonth,
  getTotal,
  saveGlosa,
};

async function findByMonth(month, year) {
  return apiSasc.get(`${urlConstants.GLOSSED_GUIDES}/${month}/${year}`);
}

async function getTotal(ano, mes) {
  return apiSasc.get(`${urlConstants.GLOSSED_GUIDES_TOTAL}/${ano}/${mes}`);
}

async function saveGlosa(idGlosa, entity) {
  return apiSasc.post(`${urlConstants.SAVE_GLOSS}/${idGlosa}`, entity);
}
