import { Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState, useEffect, useRef } from 'react';
import { cloneDeep, isNil, set, get, has, isEmpty, some } from 'lodash';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import { FormGroup } from 'reactstrap';
import cpfInput from '../../components/Input/InputCpf.jsx';
import { setValue } from '../../utils/DropDownSetValueUtils.tsx';
import api, {
  apiConnect,
  apiSasc,
  apiDynamic,
  getGuideTypes,
  oauthHeaderJson,
} from '../../services/api.service';
import { Form } from 'reactstrap';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput.js';
import FormInput from '../../components/inputs/FormInput.js';
import {
  formatDateToString,
  formatStringToDate,
} from '../../utils/date-utils.tsx';
import './styles/search.css';
import '../../themes/defaultStyles.css';
import Header from '../Navigation/Header.jsx';
import DateTimePicker from '../../components/inputs/DateTimePicker.js';
import { usersService } from '../../services/users.service.jsx';
import { procedimentosService } from '../../services/procedimentos.service.jsx';
import DatePickerInput from '../../components/inputs/DatePickerInput.js';

function Search({ loading, navigation, error }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [pacientes, setPacientes] = useState([]);
  const [conveniosList, setConveniosList] = useState([]);
  const [filter, setFilter] = useState({ page: 0, limit: 20 });
  const dropController = useRef(null);
  const scrollRef = useRef(null);
  const navigate = useNavigate();

  const ITEM_REQUIRED = Yup.string().required('Obrigatório');
  const searchValidate = Yup.object().shape({
    guideType: ITEM_REQUIRED,
    convenio: ITEM_REQUIRED,
    finalDate: ITEM_REQUIRED,
    initialDate: ITEM_REQUIRED,
    name: ITEM_REQUIRED,
    cpf: ITEM_REQUIRED,
  });

  useEffect(() => {
    findConvenios(0);
  }, []);

  const findConvenios = async (guideType) => {
    loading(true);
    apiDynamic.get(`/convenio/findByEstabelecimento/${0}/${0}`).then(
      (r) => {
        const conveniosList = r.data.map((e) => {
          const name = e.nome ? e.nome : e.sigla;
          e.label = name;
          e.nome = name;
          e.value = e.id;
          return e;
        });
        setConveniosList(conveniosList);
        setValues((prevValues) => ({ ...prevValues, convenios: r.data }));
        loading(false);
      },
      (e) => {
        loading(false);
      }
    );
  };

  const handleChange = (name, value, error) => {
    setValues((prevValues) => {
      const newValues = cloneDeep(prevValues);
      set(newValues, name, value);
      return newValues;
    });
    setErrors((prevErrors) => {
      const newErrors = cloneDeep(prevErrors);
      set(newErrors, name, error);
      return newErrors;
    });
  };

  const findPaciente = async (name) => {
    handleChange(
      'patient',
      {
        nomeBeneficiario: name,
        numeroCarteira: values.cardNumber,
      },
      null
    );
    try {
      const r = await apiDynamic.get(
        `/beneficiario/find/similarNameOrCpfOrCarteirinha`,
        {
          params: { nameOrCpf: name },
        }
      );
      console.log(name, r);
      const data = r.data
        ? r.data.map((e) => {
            return {
              title: e.nomeBeneficiario,
              id: JSON.stringify(e),
              numeroCarteira: e.numeroCarteira,
            };
          })
        : [];

      return data;
    } catch (error) {
      console.error('error find paciente');
      console.error(JSON.stringify(error));
      return [];
    }
  };

  const filterMount = (values) => {
    const initialDate = !isNil(values.initialDate)
      ? formatStringToDate(values.initialDate)
      : undefined;
    const finalDate = !isNil(values.finalDate)
      ? formatStringToDate(values.finalDate)
      : undefined;
    const guide = values.guideType ? { id: values.guideType } : null;
    return {
      typeGuide: guide,
      medicalInsurance: values.convenio ? values.convenio.id : null,
      patientName: get(values.paciente, 'title'),
      initialDate: initialDate,
      finalDate: finalDate,
      page: 0,
      limit: 20,
    };
  };

  const handleSubmit = () => {
    const filtro = filterMount(values);
    navigate('/SearchResult', { state: filtro });
  };

  const clear = () => {
    setValues({});
  };

  const getInitialPatientData = (patientData) => {
    if (!isNil(patientData)) {
      const finalTitle = patientData.nomeBeneficiario;
      const id = patientData.numeroCarteira;
      const title =
        has(patientData, 'cpf') && !isNil(patientData.cpf)
          ? `${finalTitle} - ${patientData.cpf}`
          : finalTitle;
      return {
        title,
        id,
        numeroCarteira: id,
      };
    }
    return undefined;
  };

  const getOnKeyboardWillHide = (frames) => {
    dropController.current.clear();
  };

  return (
    <div className="container-fluid">
      <Header />

      <div className="container my-5 my-sm-0">
        <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">Pesquisar</h1>
        <Formik
          validationSchema={searchValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({ values, errors, handleChange, setFieldValue, index }) => (
            <Form autoComplete="off" className="row">
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={SelectInput}
                  options={getGuideTypes}
                  labelKey="label"
                  valueKey="value"
                  label={'Tipo de guia'}
                  id="guideType"
                  name="guideType"
                  placeholder="Tipo da guia"
                  type="text"
                  value={values.guideType}
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  noSize={true}
                  erroMensagem={errors.guideType}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  isMulti={false}
                  returnFullObject={true}
                  component={SelectInput}
                  options={conveniosList}
                  labelKey="sigla"
                  valueKey="id"
                  label={'Convênio'}
                  id={'convenio'}
                  name={'convenio'}
                  placeholder="Convênio"
                  type="text"
                  value={values.convenio}
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  autoComplete={false}
                  erroMensagem={errors.convenio}
                  required={false}
                />
                {/* <InputViewEdit
                  component={SelectInput}
                  valueKey="id"
                  labelKey="descricao"
                  label={'Procedimento'}
                  id="procedures"
                  name="procedures"
                  placeholder="Nome ou código do procedimento"
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  onFetchData={procedimentosService.findProcedures} 
                  value={values.procedures}
                  noSize={true}
                  returnFullObject={true}
                  multi={false}
                  beforeAnotherDropDown={true}
                  errorMessage={
                    errors.procedures && errors.procedures[index]
                      ? errors.procedures[index].procedure
                      : null
                  }
                /> */}
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={DatePickerInput}
                  label="Data Inicial"
                  placeholder={'--/--/----'}
                  value={values.initialDate}
                  name="initialDate"
                  id="initialDate"
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  mode="calendar"
                  format="DD/MM/YYYY"
                  maximumDate={formatDateToString(new Date())}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={DatePickerInput}
                  label="Data Final"
                  placeholder={'--/--/----'}
                  value={values.finalDate}
                  name="finalDate"
                  id="finalDate"
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  mode="calendar"
                />
              </FormGroup>

              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={SelectInput}
                  valueKey="id"
                  labelKey="title"
                  placeholder="Nome ou CPF do paciente"
                  label={'Paciente'}
                  id="paciente"
                  name="paciente"
                  value={values.paciente}
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  noSize={true}
                  returnFullObject={true}
                  multi={false}
                  onFetchData={findPaciente}
                  sortKey={'cpfCnpjJoinName'}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
        <div className="defaultStyles-buttonsContainer">
          <button className="btn btn-secondary" onClick={handleSubmit}>
            BUSCAR TABELA
          </button>
          <button className="btn btn-dark" onClick={clear}>
            LIMPAR
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(Search);
