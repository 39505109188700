
import React, { Component } from 'react';
// import './EmptyDropDownPickerStyles.css';

class SascDropDownPickerEmpty extends Component {
    
    render() {
        const { loading, ActivityIndicatorComponent } = this.props;

        return (
            <div className={this.props.listMessageContainer}>
                {loading ? (
                    <ActivityIndicatorComponent size={10} color="gray" />
                ) : (
                    <div className="containerView">
                        <span className="text">
                            Não foram encontrados resultados.
                        </span>
                    </div>
                )}
            </div>
        );
    }
}

export default SascDropDownPickerEmpty;
