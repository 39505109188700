import axios from 'axios';
import { urlConstants } from '../constants/url.constants';
import { apiSasc } from './api.service';

export const expectedPaymentsService = {
  findByMonth,
  getTotal,
};

async function findByMonth(month, year, situacaoAtendimento) {
  return apiSasc.post(
    `${urlConstants.EXPECTED_PAYMENTS}/${month}/${year}`,
    situacaoAtendimento
  );
}

async function getTotal(year, month) {
  return apiSasc.get(`${urlConstants.EXPECTED_PAYMENTS_TOTAL}${year}/${month}`);
}
