
import { urlConstants } from '../constants/url.constants';
import { formatDateToString } from '../utils/date-utils.tsx';
import axios from 'axios';
import { apiDynamic, apiSasc, apiConnect } from './api.service';


export const dashboardService = {
  findDetalheAtendimento,
};

async function findDetalheAtendimento() {
  let data = formatDateToString(new Date(), 'YYYY-MM-DD');
  return apiConnect.get(`${urlConstants.DETALHE_ATENDIMENTO}${data}`);
}
