import React from 'react';
import { isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import SascCardProps from './interface/SascCardProps';
import SascCardCore from './core/SascCardCore.tsx';
// import PropTypes from 'prop-types';
import './styles/sascCard.css';

/**
 * SascCard
 *
 * @param type
 * @param buttonProps
 * @constructor
 */
export default function SascCard({
  type,
  buttonProps,
  className,
}: SascCardProps) {
  const handleClick = () => {
    // Implement the logic that was meant to be in buttonProps.onClick
    if (
      !isNil(buttonProps) &&
      typeof buttonProps === 'object' &&
      'onClick' in buttonProps &&
      typeof buttonProps.onClick === 'function'
    ) {
      buttonProps.onClick();
    }
  };

  return (
    <div className={className} key={uuidv4()}>
      <div className="sasccard-containerCard">
        {!isNil(buttonProps) && typeof buttonProps === 'object' ? (
          <div className="card-button" {...buttonProps} onClick={handleClick}>
            <SascCardCore type={type} />
          </div>
        ) : (
          <SascCardCore type={type} />
        )}
      </div>
    </div>
  );
}
