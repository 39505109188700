import { find, get, isEmpty, isNil } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { FaPrint } from 'react-icons/fa';
import { MdHouse } from 'react-icons/md';
import { FormGroup } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import SascCard from '../../components/card/SascCard.tsx';
import ImageUploader from '../../components/image-uploader/imageUploader.js';
import FormInput from '../../components/inputs/FormInput.js';
import InputViewEdit from '../../components/inputs/InputViewEdit.js';
import SelectInput from '../../components/inputs/SelectInput.js';
import {
  BASE_URL_CONNECT,
  apiConnect,
  getAttachmentForwarded,
  getGuideTypes,
  getLabelOfValue,
} from '../../services/api.service';
import { authenticationService } from '../../services/authentication.service';
import { entitiesService } from '../../services/entities.service';
import { formatDateToString } from '../../utils/date-utils.tsx';
import './style/medicalCareFourthStep.css';

const PRINT_SADT = `${BASE_URL_CONNECT}/public/report/guiaSADT/{idAtendimento}`;
const PRINT_HONORARIO = `${BASE_URL_CONNECT}/public/report/guiaHonorarios/{idAtendimento}`;
const PRINT_CONSULTA = `${BASE_URL_CONNECT}/public/report/guiaConsulta/{idAtendimento}`;
const PRINT_INTERNACAO = `${BASE_URL_CONNECT}/public/report/guiaInternacao/{idAtendimento}`;

const validaQtdDigitosSenha = (digit, password) => {
  if ((digit && password && digit === password.length) || digit === 0) {
    return false;
  }
  return true;
};
const TIPOS_GUIA_REGRA_CONVENIO = [
  { name: 'SADT', label: 'Guia SADT', value: '0' },
  { name: 'Honorário', label: 'Guia Honorários', value: '1' },
  { name: 'Consulta', label: 'Guia Consulta', value: '2' },
  { name: 'Internação', label: 'Guia Internação', value: '3' },
];

const qtdDigitosSenha = (listaRegrasSenhaConvenio, guideType) => {
  if (listaRegrasSenhaConvenio) {
    for (let i = 0; i < listaRegrasSenhaConvenio.length; i += 1) {
      if (
        TIPOS_GUIA_REGRA_CONVENIO[listaRegrasSenhaConvenio[i].tipoGuia.id]
          .value === guideType
      ) {
        if (listaRegrasSenhaConvenio[i].quantDigitosSenha) {
          return listaRegrasSenhaConvenio[i].quantDigitosSenha;
        }
      }
    }
  }

  return 0;
};

const passwordIsRequired = (
  tiposGuiaSenhaObrigatoria,
  guideType,
  listaRegrasSenhaConvenio,
  procedureList
) => {
  if (tiposGuiaSenhaObrigatoria) {
    for (const guia of tiposGuiaSenhaObrigatoria) {
      if (TIPOS_GUIA_REGRA_CONVENIO[guia.id].value === guideType) {
        const regra = listaRegrasSenhaConvenio.find(
          (regra) => regra.tipoGuia.id === guia.id
        );

        if (regra.procedimentosSenhaObrigatoria.length === 0) {
          return true;
        }
        if (
          regra.procedimentosSenhaObrigatoria.find(
            (pro) =>
              procedureList.find(
                (ae) => JSON.parse(ae.procedure).codigo === pro.codigo
              ) !== undefined
          )
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const MedicalCareFourStep = forwardRef(
  (
    {
      values,
      system,
      errors,
      handleChange,
      loading,
      error,
      converterSascTreatmentsCreateSaveToContext,
      handleSubmit,
      printOrBack,
      patientDataParsed,
      medicalInsuranceDataParsed,
      sascTreatmentsCreateContextType,
      goBack,
    },
    ref
  ) => {
    const [guideNumberDisable, setGuideNumberDisable] = useState(false);
    const [coop, setCoop] = useState(null);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [images, setImages] = useState([]);
    const sascAttachmentResponse = values.imageList;

    useEffect(() => {
      generateGuideNumber();
      getDados();
      console.log(values);
    }, []);

    const getDados = () => {
      Promise.all([
        authenticationService.getEntidade(),
        entitiesService.findEntidadesCooperado(),
      ]).then(
        (response) => {
          let coopId = get(JSON.parse(response[0]), 'idEntidade');

          let listaCoop = response[1].data;
          let coopSelect = find(listaCoop, { idEntidade: coopId });
          setCoop(coopSelect);
          loading(false);
        },
        (error) => {
          console.log(error);
          loading(false);
          error(
            'Não foi possível carregar algumas informações, tente novamente.'
          );
        },
        () => {
          loading(false);
        }
      );
    };

    const generateGuideNumber = () => {
      if (!isNil(values.medicalInsurance)) {
        let medicalInsuranceParse;

        if (typeof values.medicalInsurance == 'string') {
          medicalInsuranceParse = JSON.parse(values.medicalInsurance);
        } else {
          medicalInsuranceParse = values.medicalInsurance;
        }
        console.log(medicalInsuranceParse);

        if (!medicalInsuranceParse.numeroGuiaObrigatoria) {
          apiConnect
            .get(
              `/atendimento/getNumeroGuiaConvenio/${medicalInsuranceParse.id}`
            )
            .then((response) => {
              handleChange('guideNumber', response.data.toString(), null);
              setGuideNumberDisable(true);
            });
        }
      }
    };

    const validateStep = () => {
      console.log('valida ultimo passo');
      let isValid = true;
      const PASSWORD_REQUIRED = 'Senha de convênio obrigatória';
      const FIELD_REQUIRED = 'Campo obrigatório';
      const proceduresInfo = values.procedures;
      const sascAttachmentResponse = values.imageList;
      const medicalInsurance = values.medicalInsurance;
      const localHospital = values.localHospital;
      const listaGrauComanexo = get(
        localHospital,
        'grauParticipacaoAnexoObrigatorio'
      );
      const grauComAnexoObrigatorio = ['00', '01', '02', '03', '04'];
      let preenchimentoAnexoObrigatorio = false;

      if (
        system === 'SASCCONNECT' &&
        (values.guideType === '0' || values.guideType === '1') &&
        coop &&
        coop.siglaEntidade === 'COOPMEDRS'
      ) {
        console.log('opa é aqui mesmo');
        console.log(values.attachmentForwarded);
        for (let i = 0; i < proceduresInfo.length; i += 1) {
          const procedure = proceduresInfo[i];
          if (procedure && procedure.participateRate) {
            if (
              grauComAnexoObrigatorio.includes(procedure.participateRate) &&
              !values.attachmentForwarded
            ) {
              console.log('É grau de 00 a 04');
              preenchimentoAnexoObrigatorio = true;
              isValid = false;
            }

            if (
              procedure.participateRate === '12' &&
              localHospital &&
              localHospital.nome !== 'HOSPMAEDEDEUS' &&
              !values.attachmentForwarded
            ) {
              preenchimentoAnexoObrigatorio = true;
              isValid = false;
            }
          }
        }
      }
      let guiaFisicaObrigatoria = false;
      // medicalInsurance
      if (
        medicalInsurance &&
        medicalInsurance.procedimentoCooperativaConvenioList
      ) {
        for (
          let w = 0;
          w < medicalInsurance.procedimentoCooperativaConvenioList.length;
          w += 1
        ) {
          if (
            medicalInsurance.procedimentoCooperativaConvenioList[w] &&
            medicalInsurance.procedimentoCooperativaConvenioList[w]
              .procedimentoCooperativaList
          ) {
            for (let z = 0; z < proceduresInfo.length; z += 1) {
              if (
                proceduresInfo[z].procedure &&
                proceduresInfo[z].procedure.codigo ===
                  medicalInsurance.procedimentoCooperativaConvenioList[w].codigo
              ) {
                console.log(11);
                for (
                  let x = 0;
                  x <
                  medicalInsurance.procedimentoCooperativaConvenioList[w]
                    .procedimentoCooperativaList.length;
                  x += 1
                ) {
                  console.log(22);
                  console.log(
                    medicalInsurance.procedimentoCooperativaConvenioList[w]
                      .procedimentoCooperativaList
                  );
                  if (
                    medicalInsurance.procedimentoCooperativaConvenioList[w]
                      .procedimentoCooperativaList[x].guiaFisicaObrigatoria
                  ) {
                    console.log(33);
                    if (!values.attachmentForwarded) {
                      preenchimentoAnexoObrigatorio = true;
                    }
                    guiaFisicaObrigatoria = true;
                  }
                }
              }
            }
          }
        }
      }

      if (preenchimentoAnexoObrigatorio) {
        handleChange(
          'attachmentForwarded',
          values.attachmentForwarded,
          FIELD_REQUIRED
        );
      }

      // ProcedimentoCooperativa
      for (let i = 0; i < proceduresInfo.length; i += 1) {
        const procedure = proceduresInfo[i].procedure;
        if (procedure && procedure.procedimentoCooperativaList) {
          for (
            let j = 0;
            j < procedure.procedimentoCooperativaList.length;
            j += 1
          ) {
            if (
              procedure.procedimentoCooperativaList[j] &&
              procedure.procedimentoCooperativaList[j].guiaFisicaObrigatoria
            ) {
              guiaFisicaObrigatoria = true;
            }
          }
        }
      }

      if (
        passwordIsRequired(
          medicalInsurance.tiposGuiaSenhaObrigatoria,
          values.guideType,
          medicalInsurance.listaRegrasSenhaConvenio,
          proceduresInfo
        ) &&
        !values.password
      ) {
        this.setState({ passwordErrorMessage: PASSWORD_REQUIRED });

        isValid = false;
      } else if (!isNil(values.password) || !isEmpty(values.password)) {
        const digit = qtdDigitosSenha(
          medicalInsurance.listaRegrasSenhaConvenio,
          values.guideType
        );
        if (validaQtdDigitosSenha(digit, values.password)) {
          this.setState({
            passwordErrorMessage: `Convênio exige ${digit} caracteres.`,
          });

          isValid = false;
        }
      }

      if (system === 'SASCCONNECT' && values.attachmentForwarded === '0') {
        if (
          guiaFisicaObrigatoria &&
          sascAttachmentResponse &&
          sascAttachmentResponse.length < 1
        ) {
          error('É necessário anexar a guia física');
          isValid = false;
        }
      }

      return isValid;
    };

    useImperativeHandle(ref, () => ({
      validateStep: validateStep,
      printProcedure: printProcedure,
    }));

    const getUrlPrintProcedure = (idAtendimento, guideType) => {
      let url = '';
      if (guideType === '0') {
        url = PRINT_SADT;
      }
      if (guideType === '1') {
        url = PRINT_HONORARIO;
      }
      if (guideType === '2') {
        url = PRINT_CONSULTA;
      }
      if (guideType === '3') {
        url = PRINT_INTERNACAO;
      }
      return url.replace('{idAtendimento}', idAtendimento);
    };

    const printProcedure = (idAtendimento, guideType) => {
      window.open(getUrlPrintProcedure(idAtendimento, guideType), '_blank');
    };

    const setTreatmentIsSaved = () => {
      goBack();
    };
    const handleAddImage = (e) => {
      const newImages = [...values.imageList];
      for (let i = 0; i < e.target.files.length; i++) {
        newImages.push(e.target.files[i]);
      }

      handleChange('imageList', newImages);
    };

    // Função para remover uma imagem
    const handleRemoveImage = (index) => {
      const newImages = [...values.imageList];
      newImages.splice(index, 1);
      handleChange('imageList', newImages);
    };
    return (
      <div className="container-fluid">
        <div className="row">
          <p className="text500ColorGray">
            {printOrBack
              ? `Atendimento Salvo ${
                  values.protocolo ? ' - Protocolo ' + values.protocolo : ''
                }`
              : 'Confirme os dados:'}
          </p>

          {printOrBack && (
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-secondary"
                onClick={() => setTreatmentIsSaved(true)}
              >
                + Novo Atendimento
              </button>
              <div className="space" />
              <button
                className="btn btn-dark"
                onClick={() => printProcedure(values.id, values.guideType)}
              >
                Imprimir
              </button>
            </div>
          )}

          <SascCard
            className="col-12 col-md-6 my-4"
            type={{
              name: values.patient.title,
              cpf: values.patient.cpf,
              insuranceName: values.medicalInsurance.name
                ? values.medicalInsurance.name
                : values.medicalInsurance.sigla,
              insuranceCardNumber: values.cardNumber,
            }}
          />
          <SascCard
            className="col-12 col-md-6 my-4"
            type={{
              guideType: getLabelOfValue(getGuideTypes, values.guideType),
              localHospital: !isNil(values.localHospital)
                ? values.localHospital.nome
                : null,
              healthInstitute: values.healthInstitute
                ? values.healthInstitute.nome
                : null,
            }}
          />

          {values.procedures.map((value, index) => (
            <SascCard
              className={'col-12 col-md-6 my-4'}
              key={uuidv4().toString()}
              type={{
                index,
                procedureName: ` ${value.procedure.title}`,
                participateRate: value.participateRate
                  ? value.participateRate.label
                  : null,
                procedureDate: formatDateToString(value.date),
                procedureStartTime: value.startTime,
                procedureEndTime: value.endTime,
                accessWay: value.accessWay ? value.accessWay.label : null,
                technique: value.technique ? value.technique.label : null,
                amount: value.amount,
              }}
            />
          ))}
        </div>
        <div className="row">
          <ImageUploader
            showButton={!printOrBack}
            images={values.imageList}
            handleAddImage={handleAddImage}
            handleRemoveImage={handleRemoveImage}
          />
        </div>
        <div className="row mb-custom">
          {!printOrBack && (
            <>
              {system === 'SASCCONNECT' && (
                <FormGroup className="col-12 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    options={getAttachmentForwarded}
                    label="Anexo encaminhado?"
                    placeholder="Selecione de a guia foi encaminhada fisicamente para a cooperativa?"
                    id={'attachmentForwarded'}
                    name={'attachmentForwarded'}
                    returnFullObject={false}
                    labelKey="label"
                    valueKey="value"
                    value={values.attachmentForwarded}
                    onChange={(name, value) => {
                      handleChange(name, value);
                    }}
                    erroMensagem={errors && errors.attachmentForwarded}
                  />
                </FormGroup>
              )}
              <FormGroup className="col-12 col-sm-4">
                <InputViewEdit
                  component={FormInput}
                  label={'Senha'}
                  type="text"
                  id={'password'}
                  name={'password'}
                  placeholder="inisra a senha"
                  value={values.password}
                  onChange={(name, value) => {
                    console.log(value);
                    handleChange(name, value);
                  }}
                  noSize={true}
                  erroMensagem={
                    (errors && errors.password) || passwordErrorMessage
                  }
                  required={false}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-4">
                <InputViewEdit
                  component={FormInput}
                  label={'Número da guia'}
                  type="text"
                  id={'guideNumber'}
                  name={'guideNumber'}
                  disabled={guideNumberDisable}
                  placeholder="Insira o número da guia"
                  value={values.guideNumber}
                  onChange={(name, value) => {
                    console.log(value);
                    handleChange(name, value);
                  }}
                  noSize={true}
                  erroMensagem={errors && errors.guideNumber}
                  required={false}
                />
              </FormGroup>
            </>
          )}

          {/* <div style={{ marginTop: 120, marginBottom: 120 }}>
            {printOrBack &&
              values.imageList &&
              values.imageList?.map((value) => (
                <SascCard
                  key={uuidv4().toString()}
                  type={{
                    uriImage: value.uri,
                    fileName: value.fileName,
                    fileCreationDate: value.creationDate,
                  }}
                />
              ))}
          </div> */}
        </div>
      </div>
    );
  }
);

export default MedicalCareFourStep;
