import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import includes from 'lodash/includes';
import { IoEye } from 'react-icons/io5';
import { connect } from 'react-redux';
import FormInput from '../../components/inputs/FormInput.js';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup } from 'reactstrap';
import InputViewEdit from '../../components/inputs/InputViewEdit.js';
import SelectInput from '../../components/inputs/SelectInput';
import { apiSasc } from '../../services/api.service';
import { expectedPaymentsService } from '../../services/expectedPayments.service';
import { theme } from '../../theme';
import '../../themes/defaultStyles.css';
import { formatStringDate } from '../../utils/date-utils.tsx';
import Header from '../Navigation/Header';
// import { AiOutlineLeft } from 'react-icons/ai';
// import { cloneDeep } from 'lodash';

function ExpectedPayments({ loading, error }) {
  const [filterData, setFilterData] = useState([]);
  const [activeMonth, setActiveMonth] = useState(moment().month() + 1);
  const [atualizou, setAtualizou] = useState(false);
  const [values, setValues] = useState({ year: moment().year() });
  // const [anos, setAnos] = useState([]);
  // const [meses, setMeses] = useState([]);
  // const [selectedYear, setSelectedYear] = useState({ ano: moment().year() });
  // const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  // const [selectedYear, setSelectedYear] = useState(null);
  // const [selectedMonth, setSelectedMonth] = useState(null);
  const navigate = useNavigate();

  const fetchData = (month, year) => {
    loading(true);
    expectedPaymentsService.findByMonth(month, year, [4]).then(
      (response) => {
        const data = response.data;
        setFilterData(data && data.convenio ? data.convenio : []);
        // setActiveMonth(month - 1);
        setAtualizou(true);
        loading(false);
      },
      (e) => {
        console.log(e);
        setAtualizou(true);
        loading(false);
      }
    );
  };
  const onSearch = (name, value, setFieldValue) => {
    if (value.length > 0) {
      loading(true);
      const filteredData = filterData.map((item) => ({
        ...item,
        atendimento: item.atendimento.filter((atendimento) =>
          atendimento.paciente.toLowerCase().includes(value.toLowerCase())
        ),
      }));
      setFilterData(filteredData);
      setFieldValue(name, value);
      loading(false);
    } else {
      setFieldValue(name, '');
      fetchData(activeMonth, moment().year());
    }
  };

  const handleBackButton = () => {
    navigate('/home');
  };

  const openDetail = (item) => {
    navigate('/ItemDetailPage', { state: item });
  };

  // const handleSelectMesChange = (name, value) => {
  //   setSelectedMonth(value);
  //   if (selectedYear) {
  //     fetchData(value, selectedYear.ano);
  //   }
  // };

  // const handleSelectAnoChange = (name, value) => {
  //   setSelectedYear(value);
  //   if (selectedMonth) {
  //     fetchData(selectedMonth, value.ano);
  //   } else {
  //     fetchData(activeMonth, value.ano);
  //   }
  // };

  const exportExcel = async () => {
    try {
      const response = await apiSasc.get(
        `/public/servicosAReceber/export/${activeMonth}/${moment().year()}`
      );

      const blobData = new Blob([response.data], { type: 'text/csv' });
      const fileName = `${activeMonth}_${moment().year()}_analise.csv`;
      saveAs(blobData, fileName);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  useEffect(() => {
    // const anoAtual = new Date().getFullYear();
    // const anos = Array.from({ length: 6 }, (v, i) => ({ ano: anoAtual - i }));
    // const monthNames = [
    //   'Janeiro',
    //   'Fevereiro',
    //   'Março',
    //   'Abril',
    //   'Maio',
    //   'Junho',
    //   'Julho',
    //   'Agosto',
    //   'Setembro',
    //   'Outubro',
    //   'Novembro',
    //   'Dezembro',
    // ];

    // const currentMonthIndex = new Date().getMonth();
    // const currentMonth = currentMonthIndex + 1;
    // const meses = [];

    // meses.push({
    //   id: currentMonth,
    //   name: monthNames[currentMonthIndex],
    //   mes: currentMonth,
    // });
    // monthNames.forEach((name, index) => {
    //   if (index !== currentMonthIndex) {
    //     meses.push({ id: index + 1, name, mes: index + 1 });
    //   }
    // });

    // setAnos(anos);
    // setMeses(meses);
    fetchData(activeMonth, moment().year());

    // setActiveMonth(currentMonth);
    // setSelectedMonth(currentMonth);
    // setSelectedYear({ ano: anoAtual });
  }, []);

  return (
    <div className="container-fluid">
      <Header handleBackButton={handleBackButton} />
      <div className="container">
        <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">
          {' '}
          Previsto
        </h1>
        <Formik
          validationSchema={{}}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
        >
          {({ values, errors, handleChange, setFieldValue, index }) => (
            <Form autoComplete="off" className="row">
              <div className="row section-form">
                {/* <FormGroup className="col-6 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    options={meses}
                    label="Mês"
                    valueKey="mes"
                    labelKey="name"
                    // placeholder="Selecione um mês"
                    placeholder={selectedMonth ? undefined : 'Selecione um mês'}
                    onChange={handleSelectMesChange}
                    value={selectedMonth}
                  ></InputViewEdit>
                </FormGroup>

                <FormGroup className="col-6 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    label="Ano"
                    name="ano"
                    id={'ano'}
                    // placeholder="Selecione um ano"
                    placeholder={selectedYear ? undefined : 'Selecione um ano'}
                    valueKey="ano"
                    labelKey="ano"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={false}
                    searchable={false}
                    options={anos}
                    onChange={handleSelectAnoChange}
                    value={selectedYear}
                  />
                </FormGroup> */}
                <FormGroup className="col-10 col-sm-10">
                  <InputViewEdit
                    component={FormInput}
                    label="Filtro"
                    placeholder="Pesquisar"
                    name="textoBusca"
                    type="text"
                    id="textoBusca"
                    value={values.textoBusca}
                    noSize={true}
                    erroMensagem={errors.nome}
                    required={false}
                    onChange={(name, value) => {
                      onSearch(name, value, setFieldValue);
                    }}
                  ></InputViewEdit>
                </FormGroup>
                <FormGroup className="col-2 col-sm-1">
                  <button onClick={exportExcel} className="bg-white border-0">
                    <img
                      className="defaultStyles-iconImgCard defaultStyles-btnExcelCard"
                      src={require('../../assets/icon-excel.png')}
                      alt="Export to Excel"
                    />
                  </button>
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>

        <div className="container">
          <div className="defaultStyles-tableCard">
            {filterData &&
              filterData.map((convenio, index) => (
                <React.Fragment key={index}>
                  {convenio.atendimento.map((item, indexAtendimento) => (
                    <div
                      key={indexAtendimento}
                      className={`${'defaultStyles-tableRowCard'} ${
                        index % 2 === 0
                          ? 'defaultStyles-evenRowCard'
                          : 'defaultStyles-oddRowCard'
                      }`}
                    >
                      <div className="defaultStyles-columnLayoutCard">
                        <div className="defaultStyles-tableCellCard">
                          <div className="defaultStyles-rowCard">
                            <strong>Status: </strong>
                            <span>{item.status}</span>
                          </div>
                        </div>
                        <div className="defaultStyles-tableCellCard">
                          <div className="defaultStyles-rowCard">
                            <strong>Núm.Atendimento: </strong>
                            <span>{item.numero}</span>
                          </div>
                        </div>
                        <div className="defaultStyles-tableCellCard">
                          <div className="defaultStyles-rowCard">
                            <strong>Data: </strong>
                            <span>{formatStringDate(item.data)}</span>
                          </div>
                        </div>
                        <div className="defaultStyles-tableCellCard">
                          <div className="defaultStyles-rowCard">
                            <strong>Protocolo: </strong>
                            <span>{item.protocolo}</span>
                          </div>
                        </div>
                        <div className="defaultStyles-tableCellCard">
                          <div className="defaultStyles-rowCard">
                            <strong>Convênio: </strong>
                            <span>{convenio.sigla}</span>
                          </div>
                        </div>
                        <div className="defaultStyles-tableCellCard">
                          <div className="defaultStyles-rowCard defaultStyles-wrap">
                            <strong>Beneficiário: </strong>
                            <span className="defaultStyles-wrap">
                              {item.paciente}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="defaultStyles-iconsCard">
                        <button
                          onClick={() => openDetail(item)}
                          style={{
                            border: 'none',
                            backgroundColor:
                              index % 2 === 0 ? '#EDEDED' : '#FFFFFF',
                          }}
                        >
                          <IoEye
                            className="defaultStyles-iconCard"
                            size={24}
                            color={theme.colors.gray}
                          />{' '}
                          Ver detalhes
                        </button>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            {filterData.length === 0 && atualizou && (
              <strong className="text-center m-5 p-5 fw-bold">
                Não existem registros para serem exibidos.
              </strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
  authentication: { login },
}) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  loading: (load) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  login: (user, password, saveCredentials, type) =>
    login({ user, password, saveCredentials, type }),
});

export default connect(null, mapDispatch)(ExpectedPayments);
