import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { apiConnect } from '../../services/api.service';
import { useLocation, useNavigate } from 'react-router-dom';
import SascCard from '../../components/card/SascCard.tsx';
import { isArray, isEmpty } from 'lodash';
import Header from '../Navigation/Header';
import { AiOutlineLeft } from 'react-icons/ai';
import './styles/tabela.css';

function TablesListByTreatments({ loading }) {
  const location = useLocation();
  console.log(location);
  const [filtro, setFiltro] = useState(
    location.state && location.state ? location.state : {}
  );
  const [listItems, setListItems] = useState([]);
  const [atualizou, setAtualizou] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getListItems();
  }, []);

  const getListItems = async () => {
    loading(true);
    try {
      const response = await apiConnect.post(
        `/tabelaProcedimento/list`,
        filtro
      );
      setListItems(response.data);
      setAtualizou(true);
      loading(false);
    } catch (error) {
      console.error(error);
      setAtualizou(true);
      loading(false);
    }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid">
      <Header />
      <div className="defaultStyles-headerInput">
        <div className="defaultStyles-halfBack">
          <button
            className="defaultStyles-NewBackButton"
            onClick={handleBackButton}
          >
            <span className="defaultStyles-newBackButtonText">
              <AiOutlineLeft name={'left'} />
              Voltar
            </span>
          </button>
        </div>
      </div>

      <div className="container">
        {isEmpty(listItems) && atualizou && (
          <div className="text-center m-5 p-5 fw-bold">
            Não existem registros para serem exibidos.
          </div>
        )}
        {listItems &&
          isArray(listItems) &&
          listItems.map((item, index) => (
            <SascCard
              key={index}
              type={{
                codigo: item.codigo,
                descricao: item.descricao,
                valorHonorario: item.valorHonorario,
                valorCustoOperacional: item.valorCustoOperacional,
                valorFilme: item.valorFilme,
                valorAcrescimo: item.valorAcrescimo,
                hospital: item.hospital,
                convenio: item.convenio,
                complemento: item.complemento,
                tipo: item.tipo,
              }}
            />
          ))}
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(TablesListByTreatments);
