import React from 'react';
import formatPrice from '../../../../../utils/currency-utils.tsx';
import SascText from '../../../../text/SascText.tsx';
import SascCardTreatmentsInformationsProps from '../interface/SascCardTreatmentsInformationsProps';
import './styles/SascCardTreatmentsInformationsDataAndPrice.css';

/**
 * SascCardTreatmentsInformationsDataAndPrice
 *
 * @constructor
 */
export default function SascCardTreatmentsInformationsDataAndPrice({
  date,
  price,
}: Pick<SascCardTreatmentsInformationsProps, 'date' | 'price'>) {
  return (
    <div className="">
      <SascText className="dataText" style={{}}>
        {date}
      </SascText>
      <SascText className="priceText" style={{}}>
        {price ? formatPrice(price) : ''}
      </SascText>
    </div>
  );
}
