// Assuming urlConstants and apiSasc behave the same way in React as they do in React Native
import { urlConstants } from '../constants/url.constants';
import { apiDynamic, apiSasc } from './api.service';

export const usersService = {
  findPageble,
  save,
  remover,
  getUser,
};

async function findPageble(page) {
  return apiSasc.get(`${urlConstants.OPERATOR}${page}`);
}

async function save(entity) {
  if (entity.id === null || entity.id === undefined) {
    return apiSasc.post(`${urlConstants.OPERATOR}`, entity);
  } else {
    return apiSasc.put(`${urlConstants.OPERATOR + entity.id}`, entity);
  }
}

async function remover(idUser) {
  return apiSasc.delete(`${urlConstants.OPERATOR}${idUser}`);
}

async function getUser() {
  return apiSasc.get(`${urlConstants.USER}`);
}
