import { authenticationService } from './authentication.service';
import axios from 'axios';
import { urlConstants } from '../constants/url.constants';
import { apiSasc } from './api.service';

export const paymentsMadeService = {
  findByMonth,
  getTotal,
  getTransferDetails,
};

async function findByMonth(month, year) {
  return apiSasc.get(`${urlConstants.PAYMENTS_MADE}/${month}/${year}`);
}
async function getTotal(year, month) {
  return apiSasc.get(`${urlConstants.PAYMENTS_MADE_TOTAL}${year}/${month}`);
}

async function getTransferDetails(transferNumber) {
  return apiSasc.get(`${urlConstants.TRANSFER_DETAILS}${transferNumber}`);
}
