//@flow
import { init } from '@rematch/core';
import { thunk } from 'redux-thunk';
import * as models from '../models';
import { reducer as toastrReducer } from 'react-redux-toastr';

export const store = init({
    models,
    redux: {
        reducers: {
            toastr: toastrReducer,
        },
        middlewares: [thunk]
    }
});
