import { urlConstants } from '../constants/url.constants';
import { apiSasc } from './api.service';

export const entitiesService = {
  findEntidadesCooperado,
  saveEntidadeCooperado,
};

async function findEntidadesCooperado() {
  return apiSasc.get(`${urlConstants.ENTITIES_COOP}`);
}

async function saveEntidadeCooperado(entity) {
  return apiSasc.post(`${urlConstants.SAVE_ENTITIES_COOP}`, entity);
}
