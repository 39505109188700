import React from 'react';
import formatPrice from '../../../utils/currency-utils.tsx';
import SascCardTablesProps from './interface/SascCardTablesProps';

export default function SascCardTables({
  codigo,
  descricao,
  valorHonorario,
  valorCustoOperacional,
  valorFilme,
  valorAcrescimo,
  hospital,
  convenio,
  complemento,
}: SascCardTablesProps) {
  return (
    <div>
      <div className="containerNumber">
        <span className="textNumber">{codigo}</span>
        <span className="textTabelaNome">{hospital}</span>
      </div>
      <div className="containerDescription">
        <span className="textTabelaDescription">{descricao}</span>
      </div>
      <div className="containerPrice">
        <span className="textTabelaNome">{convenio}</span>
        <span className="textTabelaDescription">{complemento}</span>
      </div>
      <div className="containerPrice">
        <span className="textTabelaPrice">
          {formatPrice(
            valorAcrescimo + valorHonorario + valorCustoOperacional + valorFilme
          )}
        </span>
      </div>
    </div>
  );
}
