import axios from 'axios';

export const BASE_URL_CONNECT = process.env.REACT_APP_API_CONNECT_URL;
export const BASE_URL_SASC = process.env.REACT_APP_API_SASC_URL;
export const REACT_APP_API_DYNAMIC_URL = process.env.REACT_APP_API_DYNAMIC_URL;

export const apiDynamic = axios.create({ baseURL: REACT_APP_API_DYNAMIC_URL });
export const apiConnect = axios.create({ baseURL: BASE_URL_CONNECT });
export const apiSasc = axios.create({ baseURL: BASE_URL_SASC });

apiSasc.interceptors.request.use(async (config) => {
  const headers = await getHeader();
  config.headers = { ...config.headers, ...headers };
  return config;
});

apiConnect.interceptors.request.use(async (config) => {
  const headers = await oauthHeaderJson();
  config.headers = { ...config.headers, ...headers };
  return config;
});

apiDynamic.interceptors.request.use(async (config) => {
  const headersConnect = await oauthHeaderJson();
  const headersSasc = await getHeader();
  config.headers = {
    ...config.headers,
    'Authorization-Connect': headersConnect['Authorization'],
    'Authorization-Sasc': headersSasc['Authorization'],
  };
  return config;
});

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const yearsAgo = [];

  for (let i = 0; i < 5; i++) {
    const year = currentYear - i;
    yearsAgo.push({
      name: year.toString(),
      label: year.toString(),
      value: year,
    });
  }

  return yearsAgo;
};

export const getGuideTypes = [
  { name: 'SADT', label: 'Guia SADT', value: '0' },
  { name: 'Honorário', label: 'Guia Honorários', value: '1' },
  { name: 'Consulta', label: 'Guia Consulta', value: '2' },
  { name: 'Internação', label: 'Guia Internação', value: '3' },
];

export const getParticipateRate = [
  { label: '00 - Cirurgião', value: '00' },
  { label: '01 - Primeiro Auxiliar', value: '01' },
  { label: '02 - Segundo Auxiliar', value: '02' },
  { label: '03 - Terceiro Auxiliar', value: '03' },
  { label: '04 - Quarto Auxiliar', value: '04' },
  { label: '05 - Instrumentador', value: '05' },
  { label: '06 - Anestesista', value: '06' },
  { label: '07 - Auxiliar de Anestesista', value: '07' },
  { label: '08 - Consultor', value: '08' },
  { label: '09 - Perfusionista', value: '09' },
  { label: '10 - Pediatra na sala de parto', value: '10' },
  { label: '11 - Auxiliar SADT', value: '11' },
  { label: '12 - Clínico', value: '12' },
  { label: '13 - Intensivista', value: '13' },
];

export const getAttachmentForwarded = [
  { label: 'Sim', value: '1' },
  { label: 'Não', value: '0' },
];

export const getAccessWay = [
  { label: 'Única', value: 'Única' },
  { label: 'Mesma via', value: 'Mesma via' },
  { label: 'Diferentes vias', value: 'Diferentes vias' },
];

export const getMenuPerfil = [
  { label: 'Perfil', value: '1' },
  { label: 'Trocar Sistema', value: '2' },
];

export const getTechnique = [
  { label: 'Convencional', value: 'Convencional' },
  { label: 'Robótica', value: 'Robótica' },
  { label: 'Vídeo', value: 'Vídeo' },
];

export const getLabelOfValue = (listItemType, value) => {
  if (value) {
    const item = listItemType.find((item) => item.value === value);
    return item?.label;
  }
  return '';
};

export const getCharacter = [
  { label: 'Eletivo', value: '1' },
  { label: 'Urgência/Emergência', value: '2' },
];

export const loginConnect = (username, password) => {
  const clientId = 'web_client';
  const clientPassword = 'p@ssw0rdSascConect_SpSecret_code4';

  const details = {
    client_id: clientId,
    grant_type: 'password',
    username,
    password,
  };

  const formBody = Object.keys(details).map(
    (key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])
  );

  const basicAuthorization = btoa(clientId + ':' + clientPassword);

  return axios
    .post(`${BASE_URL_CONNECT}/oauth/token`, formBody.join('&'), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + basicAuthorization,
      },
    })
    .then(
      (response) => {
        let token = response.data;
        if (token) {
          localStorage.setItem('tokenConnect', token.access_token);
        } else {
          localStorage.removeItem('tokenConnect');
        }
      },
      (e) => {
        console.log('reject');
        console.log(e);
        localStorage.removeItem('tokenConnect');
      }
    );
};

export async function oauthHeaderJson() {
  const token = await getToken();

  if (token) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Expose-Headers': 'Location',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + token,
    };
  } else {
    return {};
  }
}

async function getHeader() {
  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    const parsedJwt = JSON.parse(jwt);
    const { token } = parsedJwt;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return headers;
  }
  return {};
}

export async function getToken() {
  return localStorage.getItem('tokenConnect');
}
