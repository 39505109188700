import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { apiDynamic } from '../../services/api.service';
import { cloneDeep, set, get, isNil } from 'lodash';
import { useNavigate } from 'react-router-dom';
import SelectInput from '../../components/inputs/SelectInput.js';
import './styles/tabela.css';
import '../../themes/defaultStyles.css';
import Header from '../Navigation/Header';
import { procedimentosService } from '../../services/procedimentos.service.jsx';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const tabelasValidate = Yup.object().shape({
  convenio: ITEM_REQUIRED,
  Procedimento: ITEM_REQUIRED,
});

function Tabelas({ navigation, loading }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [pacientes, setPacientes] = useState([]);
  const [conveniosList, setConveniosList] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [loadingProcedure, setLoadingProcedure] = useState(false);
  const dropController = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    findConvenios();
  }, []);

  useEffect(() => {
    findConvenios();
  }, [navigation]);

  const findConvenios = async (guideType) => {
    loading(true);
    apiDynamic.get(`/convenio/findByEstabelecimento/${0}/${0}`).then(
      (r) => {
        const conveniosList = r.data.map((e) => {
          const name = e.nome ? e.nome : e.sigla;
          e.label = name;
          e.nome = name;
          e.value = e.id;
          return e;
        });
        setConveniosList(conveniosList);
        setValues((prevValues) => ({ ...prevValues, convenios: r.data }));
        loading(false);
      },
      (e) => {
        loading(false);
      }
    );
  };

  const handleChange = (name, value, error) => {
    setValues((prevState) => cloneDeep({ ...prevState, [name]: value }));
    setErrors((prevState) => cloneDeep({ ...prevState, [name]: error }));
  };

  const handleSubmit = () => {
    let filtro = {
      procedimento: get(values, 'procedures'),
      convenio: get(values, 'medicalInsurance'),
    };

    navigate('/TablesListByTreatments', { state: filtro });
  };

  const clear = () => {
    setValues({});
  };

  // const findProcedure = async (name) => {
  //   setLoadingProcedure(true);
  //   loading(true);
  //   return apiConnect.get(`/procedimento/${name}`).then(
  //     (r) => {
  //       setProcedures(
  //         r.data.map((e) => ({
  //           title: `${e.codigo} - ${e.descricao}`,
  //           id: e.codigo,
  //         }))
  //       );
  //       loading(false);
  //       setLoadingProcedure(false);
  //     },
  //     (e) => {
  //       loading(false);
  //       console.error('error find procedure', e);
  //     }
  //   );
  // };

  console.log('procedures aqui', procedures);

  const getInitialProcedureData = (procedureData) => {
    if (!isNil(procedureData)) {
      return {
        title: `${procedureData.codigo} - ${procedureData.descricao}`,
        id: procedureData,
      };
    }
    return undefined;
  };

  return (
    <div className="container-fluid">
      {console.log()}
      <Header />

      <div className="container my-5 my-sm-0">
        <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">Tabelas</h1>
        <Formik
          validationSchema={tabelasValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({ values, errors, handleChange, setFieldValue, index }) => (
            <Form autoComplete="off" className="row">
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  isMulti={false}
                  returnFullObject={true}
                  component={SelectInput}
                  options={conveniosList}
                  valueKey="value"
                  labelKey="nome"
                  label={'Convênio'}
                  id={'medicalInsurance'}
                  name={'medicalInsurance'}
                  placeholder="Convênio"
                  type="text"
                  value={values.medicalInsurance}
                  autoComplete={false}
                  onChange={(name, value) =>
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }))
                  }
                  erroMensagem={errors.medicalInsurance}
                  beforeAnotherDropDown={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                {values && values.medicalInsurance && (
                  <InputViewEdit
                    component={SelectInput}
                    valueKey="id"
                    labelKey="descricao"
                    label={'Procedimento'}
                    id="procedures"
                    name="procedures"
                    placeholder="Nome ou código do procedimento"
                    onChange={(name, value) =>
                      setValues((prevValues) => ({
                        ...prevValues,
                        [name]: value,
                      }))
                    }
                    onFetchData={procedimentosService.findProcedures}
                    value={values.procedures}
                    noSize={true}
                    returnFullObject={true}
                    multi={false}
                    controller={dropController}
                    clear={() => handleChange('procedures', undefined, null)}
                    beforeAnotherDropDown={true}
                    errorMessage={
                      errors.procedures && errors.procedures[index]
                        ? errors.procedures[index].procedure
                        : null
                    }
                    loading={loadingProcedure}
                  />
                )}
              </FormGroup>
            </Form>
          )}
        </Formik>

        <div className="defaultStyles-buttonsContainer">
          <button className="btn btn-secondary" onClick={handleSubmit}>
            BUSCAR TABELA
          </button>
          <button className="btn btn-dark" onClick={clear}>
            LIMPAR
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(Tabelas);
