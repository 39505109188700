import React, { useState, useEffect, useRef } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import SascDropDownPicker from '../../components/Input/SascDropDownPicker';
import SascInput from '../../components/Input/SascInput';
import shallowCompare from 'react-addons-shallow-compare';
import { cloneDeep, isNil, pullAt } from 'lodash';
import { formatDateToString } from '../../utils/date-utils.tsx';
import {
  apiDynamic,
  getAccessWay,
  getParticipateRate,
  getTechnique,
  oauthHeaderJson,
} from '../../services/api.service';
import { connect } from 'react-redux';
import './style/atendimento.css';
import InputViewEdit from '../../components/inputs/InputViewEdit.js';
import SelectInput from '../../components/inputs/SelectInput.js';
import { usersService } from '../../services/users.service.jsx';
import FormInput from '../../components/inputs/FormInput.js';
import DatePickerInput from '../../components/inputs/DatePickerInput.js';
import DateTimePicker from '../../components/inputs/DateTimePicker.js';
import { FormGroup } from 'reactstrap';
import { procedimentosService } from '../../services/procedimentos.service.jsx';
import { dateHelper } from '../../_helpers/date.helper.js';

function Atendimento({
  values,
  index,
  errors,
  handleChange,
  getDisplayAmount,
  getDisplayDate,
  getDisplayBool,
  getDisplayParticipateRate,
  getDisplayParticipateRateBool,
  getDisplay,
  loading,
}) {
  console.log(values);

  useEffect(() => {
    updateDate();
  }, []);

  const updateDate = () => {
    if (values.guideType === '0') {
      handleChange('procedures[0].date', new Date(), null);
    }
    if (values.guideType === '2') {
      handleChange('procedures[0].startTime', '00:00', null);
      handleChange('procedures[0].endTime', '00:00', null);
      handleChange('procedures[0].procedure', setProcedureDataConsulta(), null);
    }
  };

  const findProfessionalByCpfOrNome = async (name) => {
    try {
      const r = await apiDynamic.get('/cooperado/findByNameOrCpfOrCrm', {
        params: { nameOrCpfOrCrm: name },
      });
      const data = r.data.map((e) => {
        return {
          title: e.nome
            ? e.nome + ' - CPF: ' + e.cpf + ' - CRM: ' + e.numeroConselho
            : e.nomeCooperado + ' - ' + 'CPF:' + e.cpf,
          id: e.id ? e.id : e.idCooperadoSascWeb,
        };
      });
      console.log(data);
      return data;
    } catch (error) {
      console.error('error find profissional');
      console.error(JSON.stringify(error));
    }
  };

  const findProcedure = async (name) => {
    const headers = await oauthHeaderJson();

    try {
      const r = await apiDynamic.get(
        `/procedimento/${name}/${
          values.medicalInsurance ? values.medicalInsurance.id : 0
        }/${values.guideType}`,
        {
          headers,
          params: { nameOrCode: name },
        }
      );
      const data = r.data.map((e) => {
        return {
          title: e.codigo + ' - ' + e.descricao,
          codigo: e.codigo,
          descricao: e.descricao,
          id: e.codigo,
        };
      });
      console.log(data);
      return data;
    } catch (error) {
      console.error('error find procedimentos');
      console.error(JSON.stringify(error));
    }
  };

  const setProcedureDataConsulta = () => {
    return {
      codigo: '10101012',
      descricao: 'Em consultório (no horário normal ou preestabelecido)',
      id: '10101012',
      title: '10101012 - Em consultório (no horário normal ou preestabelecido)',
    };
  };

  return (
    <React.Fragment key={index}>
      <div className="stylesDetail-containerView">
        <div className="stylesDetail-containerViewDashed">
          <div className="stylesDetail-viewDashed" />
        </div>
        <div className="stylesDetail-containerViewProcedureNumber">
          <span className="stylesDetail-procedureNumber">{index + 1}</span>
        </div>
        <div className="stylesDetail-containerViewDashed">
          <div className="stylesDetail-viewDashed" />
        </div>
        {values.procedures.length > 1 && (
          <div className="stylesDetail-containerViewIcon">
            <AiFillDelete
              name="delete"
              size={17}
              color="#F76165"
              onClick={() => {
                let procedures = cloneDeep(values.procedures);
                pullAt(procedures, index);

                handleChange('procedures', procedures, null);
              }}
            />
          </div>
        )}
      </div>
      <div className="row mt-1">
        <FormGroup className="col-12 col-sm-6">
          <InputViewEdit
            component={SelectInput}
            valueKey="id"
            labelKey="title"
            placeholder="Nome, CPF ou CRM do profissional"
            label={'Profissional Executante'}
            id={`professional${index}p`}
            name={`procedures[${index}].professional`}
            value={values.procedures[index].professional}
            onChange={(name, value) => {
              console.log(value);
              handleChange(name, value);
            }}
            noSize={true}
            returnFullObject={true}
            multi={false}
            erroMensagem={
              errors && errors.procedures && errors.procedures[index]
                ? errors.procedures[index].professional
                : null
            }
            onFetchData={findProfessionalByCpfOrNome}
          />
        </FormGroup>

        <FormGroup className="col-12 col-sm-6">
          <InputViewEdit
            component={SelectInput}
            valueKey="id"
            labelKey="title"
            placeholder="Nome ou código do procedimento"
            label={'Procedimento'}
            id={`procedure${index}p`}
            name={`procedures[${index}].procedure`}
            value={values.procedures[index].procedure}
            onChange={(name, value) => {
              console.log(value);
              handleChange(name, value);
            }}
            noSize={true}
            returnFullObject={true}
            multi={false}
            disabled={values.guideType === '2'}
            defaultValue={
              values.guideType === '2'
                ? setProcedureDataConsulta()
                : values.procedures[index].procedure
            }
            erroMensagem={
              errors && errors.procedures && errors.procedures[index]
                ? errors.procedures[index].procedure
                : null
            }
            onFetchData={findProcedure}
          />
        </FormGroup>

        {/* Quantidade */}

        <FormGroup
          className="col-12 col-sm-3"
          style={{ display: getDisplayAmount(index) }}
        >
          <InputViewEdit
            component={FormInput}
            label={'Quantidade'}
            type="text"
            id={`amount${index}p`}
            name={`procedures[${index}].amount`}
            placeholder="Quantidade"
            value={values.procedures[index].amount}
            onChange={(name, value) => {
              console.log(value);
              handleChange(name, value);
            }}
            noSize={true}
            erroMensagem={
              errors && errors.procedures && errors.procedures[index]
                ? errors.procedures[index].amount
                : null
            }
            required={false}
          />
        </FormGroup>

        <FormGroup
          className="col-12 col-sm-3"
          style={{ display: values.guideType !== '3' }}
        >
          <InputViewEdit
            component={DatePickerInput}
            label="Data do procedimento"
            id={`date${index}p`}
            name={`procedures[${index}].date`}
            value={values.procedures[index].date}
            onChange={(name, value) => {
              console.log(value);
              handleChange(name, value);
            }}
            placeholder={'--/--/----'}
            defaultValue={
              values.procedures[index].date
                ? dateHelper.format(values.procedures[index].date, {
                    mode: 'DATE',
                  })
                : '--'
            }
            erroMensagem={
              errors && errors.procedures && errors.procedures[index]
                ? errors.procedures[index].date
                : null
            }
          />
        </FormGroup>

        <FormGroup
          className="col-12 col-sm-3"
          style={{ display: getDisplayParticipateRate(index) }}
        >
          <InputViewEdit
            component={DateTimePicker}
            label="hora inicial"
            id={`startTime${index}p`}
            format={'HH:mm'}
            name={`procedures[${index}].startTime`}
            value={values.procedures[index].startTime}
            onChange={(name, value) => {
              console.log(value);
              handleChange(name, value);
            }}
            placeholder={'--:--'}
            defaultValue={
              values.procedures[index].startTime
                ? values.procedures[index].startTime
                : '--'
            }
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={30}
            erroMensagem={
              errors && errors.procedures && errors.procedures[index]
                ? errors.procedures[index].startTime
                : null
            }
          />
        </FormGroup>

        <FormGroup
          className="col-12 col-sm-3"
          style={{ display: getDisplayParticipateRate(index) }}
        >
          <InputViewEdit
            component={DateTimePicker}
            label="Hora Final"
            id={`endTime${index}p`}
            format={'HH:mm'}
            name={`procedures[${index}].endTime`}
            value={values.procedures[index].endTime}
            onChange={(name, value) => {
              console.log(value);
              handleChange(name, value);
            }}
            placeholder={'--:--'}
            defaultValue={
              values.procedures[index].endTime
                ? values.procedures[index].endTime
                : '--'
            }
            showTimeSelect={true}
            showTimeSelectOnly={true}
            timeIntervals={30}
            erroMensagem={
              errors && errors.procedures && errors.procedures[index]
                ? errors.procedures[index].endTime
                : null
            }
          />
        </FormGroup>

        {getDisplayParticipateRateBool(index) && (
          <FormGroup className="col-12 col-sm-4">
            <InputViewEdit
              component={SelectInput}
              options={getParticipateRate}
              label="Grau de Participação"
              placeholder="Selecione Grau de Participação"
              id={`participateRate${index}p`}
              name={`procedures[${index}].participateRate`}
              returnFullObject={false}
              labelKey="label"
              valueKey="value"
              value={values.procedures[index].participateRate}
              onChange={(name, value) => {
                handleChange(name, value);
              }}
              erroMensagem={
                errors && errors.procedures && errors.procedures[index]
                  ? errors.procedures[index].participateRate
                  : null
              }
            />
          </FormGroup>
        )}

        {getDisplayBool(index) && (
          <FormGroup className="col-12 col-sm-4">
            <InputViewEdit
              component={SelectInput}
              options={getAccessWay}
              label="Via de Acesso"
              placeholder="Selecione Via de Acesso"
              id={`accessWay${index}p`}
              name={`procedures[${index}].accessWay`}
              returnFullObject={false}
              labelKey="label"
              valueKey="value"
              value={values.procedures[index].accessWay}
              onChange={(name, value) => {
                handleChange(name, value);
              }}
              erroMensagem={
                errors && errors.procedures && errors.procedures[index]
                  ? errors.procedures[index].accessWay
                  : null
              }
            />
          </FormGroup>
        )}

        {getDisplayBool(index) && (
          <FormGroup className="col-12 col-sm-4">
            <InputViewEdit
              component={SelectInput}
              options={getTechnique}
              label="Técnica"
              placeholder="Selecione Técnica"
              id={`technique${index}p`}
              name={`procedures[${index}].technique`}
              returnFullObject={false}
              labelKey="label"
              valueKey="value"
              value={values.procedures[index].technique}
              onChange={(name, value) => {
                handleChange(name, value);
              }}
              erroMensagem={
                errors && errors.procedures && errors.procedures[index]
                  ? errors.procedures[index].technique
                  : null
              }
            />
          </FormGroup>
        )}
      </div>
    </React.Fragment>
  );
}

export default Atendimento;
