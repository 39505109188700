import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { some, isEmpty } from 'lodash';
import { usersService } from '../../services/users.service';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import { AiOutlineLeft } from 'react-icons/ai';
import './styles/perfil.css';
import Header from '../Navigation/Header';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const EditPerfilValidateModel = Yup.object().shape({
  name: ITEM_REQUIRED,
  cpf: ITEM_REQUIRED,
  password: ITEM_REQUIRED,
});

function Perfil({ loading, success, error }) {
  const location = useLocation();
  const [values, setValues] = useState(location.state ? location.state : {});
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);
  const [userBoolean, setUserBoolean] = useState(
    location.state?.id ? true : false
  );
  const navigate = useNavigate();

  const handleChange = (name, value, error) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = (formikValues) => {
    const { cpf, name } = formikValues;
    console.log(formikValues);
    if (isEmpty(errors, true) || !some(errors)) {
      usersService.save(formikValues).then(
        (r) => {
          console.log(r);
          success(
            userBoolean
              ? 'Operador editado com sucesso!'
              : 'Operador cadastrado com sucesso!'
          );
          navigate('/perfilpage');
        },
        (e) => {
          console.error(e);
          const err = e.status;
          console.log(err);
          if (err === 400) {
            error('CPF já cadastrado!');
            setSubmit(true);
            handleChange('cpf', cpf, 'CPF já cadastrado');
            handleChange('name', name, 'Nome já cadastrado');
          }
        }
      );
    } else {
      setSubmit(true);
    }
  };

  // const handleSubmit = (formikValues) => {
  //   if (isEmpty(errors, true) || !some(errors)) {
  //     let newValues = cloneDeep(formikValues);
  //     set(
  //       newValues,
  //       'portalEntidadeCooperado',
  //       get(newValues, 'portalEntidadeCooperado.idEntidade')
  //     );
  //     if (!get(newValues, 'password')) {
  //       newValues = omit(newValues, 'password');
  //     }
  //     usersService.save(newValues).then(
  //       (r) => {
  //         navigate('/UsuarioList');
  //       },
  //       (e) => {
  //         const err = JSON.stringify(e);
  //         console.log(err);
  //         if (err === 400) {
  //           setSubmit(true);
  //           handleChange('cpf', values.cpf, 'CPF já cadastrado');
  //         }
  //       }
  //     );
  //   } else {
  //     setSubmit(true);
  //   }
  // };

  const clear = () => {
    setValues({});
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid my-5 my-sm-0">
      <Header />

      <div className="defaultStyles-headerInput">
        <div className="defaultStyles-halfBack">
          <button
            className="defaultStyles-NewBackButton"
            onClick={handleBackButton}
          >
            <span className="defaultStyles-newBackButtonText">
              <AiOutlineLeft name={'left'} />
              Voltar
            </span>
          </button>
        </div>
      </div>
      <h1 className="defaultStyles-titlePageCenter">Editar Perfil</h1>

      <div className="container">
        <Formik
          validationSchema={EditPerfilValidateModel}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off" className="row">
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={FormInput}
                  label={'Escreva o nome'}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Escreva o nome"
                  value={values.name}
                  onChange={setFieldValue}
                  noSize={true}
                  erroMensagem={errors.name}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={CpfCnpjInput}
                  label={'CPF'}
                  type="text"
                  id="cpf"
                  name="cpf"
                  placeholder="Digite o CPF"
                  value={values.cpf}
                  onChange={setFieldValue}
                  noSize={true}
                  erroMensagem={errors.cpf}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={FormInput}
                  label={'Senha'}
                  name={'password'}
                  id={'password'}
                  value={values.password}
                  autoComplete={false}
                  type={'password'}
                  onChange={setFieldValue}
                  placeholder="Insira sua senha"
                  maxLength={30}
                  viewPassword={true}
                  erroMensagem={errors.password}
                  autocomplete="off"
                  required={true}
                />
              </FormGroup>
              <div className="defaultStyles-buttonsContainer">
                <button
                  className="btn btn-secondary"
                  onClick={handleSubmit}
                  type="button"
                >
                  Editar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(Perfil);
