import React, { useState, useEffect } from 'react';
import { authenticationService } from '../../services/authentication.service';
import Switch from '../../components/switch/Switch';
import './styles/selectDefaultSystemPage.css';
import { useNavigate } from 'react-router-dom';

const SelectDefaultSystemPage = ({ history }) => {
  const navigate = useNavigate();
  const [demonstrativoPagamentoEnabled, setDemonstrativoPagamentoEnabled] =
    useState(false);
  const [sascConnectEnabled, setSascConnectEnabled] = useState(false);

  useEffect(() => {
    authenticationService.getSystem().then(
      ({ data }) => {
        if (data && typeof data.demonstrativoPagamento === 'boolean') {
          setDemonstrativoPagamentoEnabled(data.demonstrativoPagamento);
          setSascConnectEnabled(data.sascConnect);
        }
      },
      (error) => {
        console.error('Erro ao obter sistema:', error); // Corrigido para usar console.error
      }
    );
  }, []);

  useEffect(() => {
    if (sascConnectEnabled) {
      setDemonstrativoPagamentoEnabled(false);
    }
  }, [sascConnectEnabled]);
  useEffect(() => {
    if (demonstrativoPagamentoEnabled) {
      setSascConnectEnabled(false);
    }
  }, [demonstrativoPagamentoEnabled]);

  // const toggleSwitch = (switchName) => {
  //   console.log(switchName)
  //   if (switchName === 'demonstrativoPagamento') {
  //     setDemonstrativoPagamentoEnabled((prevEnabled) => !prevEnabled);
  //   } else if (switchName === 'sascConnect') {
  //     setSascConnectEnabled((prevEnabled) => !prevEnabled);
  //   }
  // };

  const goToHome = () => {
    const dataToSend = {
      demonstrativoPagamento: demonstrativoPagamentoEnabled,
      sascConnect: sascConnectEnabled,
    };
    authenticationService.updateSystem(dataToSend).then((data) => {
      const system = data.data;
      authenticationService.setDefaultSystem(system);
      navigate('*');
    });
  };

  return (
    <>
      <div
        className="defaultStyles-mainContainer"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingTop: '30px',
        }}
      >
        <div className="logoContainer">
          <img
            className="defaultStyles-logo"
            src={require('../../assets/logo.png')}
            alt="logo"
          />
        </div>
        <div
          className="defaultStyles-containernoPadding"
          style={{ alignItems: 'center' }}
        >
          <h1 className="defaultStyles-titleLoginPages">
            Selecione um sistema padrão
          </h1>
          <p className="defaultStyles-subtitle">
            Olá descobrimos que você é um usuário que utiliza mais de um sistema
            da Fencom!
          </p>
          <p className="defaultStyles-subtitle">
            Selecione qual sistema que deseja utilizar como padrão.
          </p>
          <div className="defaultStyles-columnLayoutCard">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Switch
                isOn={demonstrativoPagamentoEnabled}
                handleToggle={() =>
                  setDemonstrativoPagamentoEnabled(
                    !demonstrativoPagamentoEnabled
                  )
                }
              />
              <span style={{ paddingLeft: '10px' }}>
                Demonstrativo Pagamento
              </span>
            </div>
            <div
              style={{
                paddingTop: '10px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Switch
                isOn={sascConnectEnabled}
                handleToggle={() => setSascConnectEnabled(!sascConnectEnabled)}
              />
              <span style={{ paddingLeft: '10px' }}>SASC Connect</span>
            </div>
          </div>
          <button onClick={goToHome} className="buttonDSP-defaultSystem">
            Prosseguir
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectDefaultSystemPage;
