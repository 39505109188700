//@flow

const URL_SASC: string = process.env.REACT_APP_API_SASC_URL || '';

export const urlConstants = {
  LOGIN: `${URL_SASC}/token`,
  GET_USER: `${URL_SASC}/token`,
  GET_PRINCIPAL: `${URL_SASC}/principal`,
  COFFEE_BEAN_GET_VALIDATIONS: `${URL_SASC}/twoFactorAuthentication/getValidations`,
  COFFEE_BEAN_CREATE_VALIDATIONS: `${URL_SASC}/twoFactorAuthentication/authenticator/`,
  EXPECTED_PAYMENTS: `${URL_SASC}/public/servicosAReceber`,
  OPERATOR: `${URL_SASC}/api/operadores/`,
  USER: `/user`,
  GLOSSED_GUIDES: `${URL_SASC}/public/atendimentosGlosados`,
  SAVE_GLOSS: `${URL_SASC}/app/atendimento/saveGlosa/`,
  PAYMENTS_MADE: `${URL_SASC}/public/demonstrativoPagamento`,
  HEALTH_INSURANCE: `${URL_SASC}/convenio/`,
  FIND_ALL_MEDICAL_INSURANCE: `/convenio/findAll`,
  LOCAL_HOSPITAL_BY_GUIDE_TYPE: `/hospitalLocal/findByTipoGuia`,
  FIND_RECIPIENT_BY_SIMILAR_NAME_OR_CPF: `/beneficiario/find/similarNameOrCpf`,
  GET_CLINICA_BY_FILTRO_ATENDIMENTO: `/atendimento/getClinicaByFiltroAtendimento`,
  GET_SYSTEM: `${URL_SASC}/api/system`,
  PAYMENTS_MADE_TOTAL: `${URL_SASC}/api/servicosRecebidosTotal/`,
  GLOSSED_GUIDES_TOTAL: `${URL_SASC}/api/glosaTotal/`,
  EXPECTED_PAYMENTS_TOTAL: `${URL_SASC}/api/servicosAReceberTotal/`,
  TRANSFER_DETAILS: `${URL_SASC}/public/servicosRecebidos/`,
  ENTITIES_COOP: `/cooperado/findEntidadesCooperado`,
  GET_COOP: `/cooperado`,
  SAVE_ENTITIES_COOP: `/cooperado/saveEntidade`,
  DASHBOARD: `/atendimento/dashboard/`,
  DETALHE_ATENDIMENTO: `/app/atendimento/detalheAtendimento/`,
  ATENDIMENTO_LIST: `/app/atendimento/list`,
  IMAGE_GUIDE: `/app/atendimento/textact/`,
};
