import React from 'react';
import { isNil } from 'lodash';
import './styles/SascCardTreatmentInformation.css';
import SascCardTreatmentInformationProps from './props/SascCardTreatmentInformationProps';

/**
 * SascCardTreatmentInformation
 *
 * @param {string} guideType
 * @param {string} localHospital
 * @param {string} healthInstitute
 * @return {JSX.Element}
 * @constructor
 */
export default function SascCardTreatmentInformation({
  guideType,
  localHospital,
  healthInstitute,
}: SascCardTreatmentInformationProps) {
  return (
    <div>
      <h2 className="title">Informações do Atendimento</h2>

      <p className="text">{`Tipo da Guia: ${guideType}`}</p>
      {!isNil(localHospital) && (
        <p className="text">{`Hospital Local: ${localHospital}`}</p>
      )}
      {!isNil(healthInstitute) && (
        <p className="text">{`Estabelecimento: ${healthInstitute}`}</p>
      )}
    </div>
  );
}
