
import React from 'react';
import PropTypes from 'prop-types';
// import merge from 'lodash/merge';
import './styles/sascText.css';

/**
 * getStyleTextProps
 *
 * @param style
 */
// const getStyleTextProps = (style) => style;

/**
 * Sasc text
 *
 * @constructor
 */
export default function SascText({ ...textProps }) {
  return (
    <span {...textProps} className="text" />
  );
}
 /* Retirei a propriedade style por estarmos usando arquivo css para estilizar */

SascText.propTypes = {
  style: PropTypes.object
};

SascText.defaultProps = {
  style: {}
};
