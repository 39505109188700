
import { urlConstants } from '../constants/url.constants';
import { apiSasc } from './api.service';

export const twoFactorAuthenticationService = {
  getValidations,
  authenticationEmail,
  authenticationSms,
  removeAuthenticator,
  verify,
  challenge,
};

async function challenge(mfaToken, type) {
  return apiSasc.get(
    `${urlConstants.COFFEE_BEAN_CREATE_VALIDATIONS}challenge/${mfaToken}/${type}`
  );
}

async function verify(code, authenticatorType, mfaToken) {
  return apiSasc.get(
    `${urlConstants.COFFEE_BEAN_CREATE_VALIDATIONS}verify/${authenticatorType}/${code}/${mfaToken}`
  );
}

async function removeAuthenticator(authenticator, mfaToken) {
  return apiSasc.get(
    `${urlConstants.COFFEE_BEAN_CREATE_VALIDATIONS}remove/${authenticator.authenticator_id}/${mfaToken}`
  );
}

async function authenticationSms(phone, mfaToken) {
  return apiSasc.get(
    `${urlConstants.COFFEE_BEAN_CREATE_VALIDATIONS}sms/${phone}/${mfaToken}`
  );
}

async function authenticationEmail(email, mfaToken) {
  return apiSasc.get(
    `${urlConstants.COFFEE_BEAN_CREATE_VALIDATIONS}email/${email}/${mfaToken}`
  );
}

async function getValidations() {
  return apiSasc.get(`${urlConstants.COFFEE_BEAN_GET_VALIDATIONS}`);
}
