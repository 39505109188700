import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep, set } from 'lodash';
import { twoFactorAuthenticationService } from '../../services/twoFactorAuthentication.service';
import { connect } from 'react-redux';
import './styles/registerCodeAccessEmailPage.css';

function RegisterCodeAccessEmailPage({ success, error }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    values: {},
    errors: {},
    submit: false,
  });

  const handleChange = (name, value, error) => {
    setState((prevState) => {
      const values = cloneDeep(prevState.values);
      const errors = cloneDeep(prevState.errors);
      set(values, name, value);
      set(errors, name, error);
      return { values, errors };
    });
  };

  const handleSubmit = () => {
    const { values } = state;
    const mfaToken = location.state || {};

    if (values.email) {
      twoFactorAuthenticationService
        .authenticationEmail(values.email, mfaToken)
        .then(
          (r) => {
            success({ message: 'E-mail registrado com sucesso!' });
            navigate('/code-login-confirm', {
              mfaToken,
              authenticator_gate: true,
            });
          },
          (e) => {
            error("Não foi possível registrar o e-mail");
            console.log(e);
          }
        )
    }
  };

  const { values, errors, submit } = state;

  return (
    <div
      className="defaultStyles-mainContainer"
      style={{ flexDirection: 'column' }}
    >
      <div className="logoContainer_registerCodeEmail">
        <img
          className="logo_registerCodeEmail"
          src={require('../../assets/logo.png')}
          alt="Logo"
        />
      </div>
      <div className="containerTitle_registerCodeEmail">
        <h1 className="title_registerCodeEmail">
          Registro de Código de Acesso
        </h1>
        <p className="subtitle_registerCodeEmail">
          Por favor, confirme o email para onde o Código de Acesso será enviado.
        </p>
      </div>
      <div className="inputContainer_registerCodeEmail">
        <label className="inputLabel_registerCodeEmail">Email</label>
        <input
          className="inputEmail_registerCodeEmail"
          onChange={(e) => handleChange('email', e.target.value)}
          // submit={state.submit}
          name={'email'}
          required={true}
          value={values.email || ''}
        />
        <span
          className={`formHelperText ${
            submit && errors['email'] ? 'formErrorText' : ''
          }`}
        >
          {submit && errors['email'] ? errors['email'] : ''}
        </span>

        <div className="buttonContainer_registerCodeEmail">
          <button
            className="buttonEnviar_registerCodeEmail"
            onClick={handleSubmit}
          >
            ENVIAR CÓDIGO
          </button>
        </div>
      </div>

      <div />
    </div>
  );
}

const mapDispatch = ({ alert: { success, error, clear, info } }) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch)(RegisterCodeAccessEmailPage);
