import React from 'react';
import SascCardTreatmentsCircleIcon from './circle-icon/SascCardTreatmentsCircleIcon.tsx';
import SascCardTreatmentsInformations from './informations/SascCardTreatmentsInformations.tsx';
import SascCardTreatmentsProps from './interface/SascCardTreatmentsProps';
import SascCardTreatmentsArrow from './arrow/SascCardTreatmentsArrow.tsx';
import './styles/SascCardTreatments.css';

/**
 * SascCardTreatments
 *
 * @constructor
 */
export default function SascCardTreatments({
  informationProps,
}: SascCardTreatmentsProps) {
  return (
    <div className="d-flex align-items-center">
      <SascCardTreatmentsCircleIcon {...informationProps} />
      <SascCardTreatmentsInformations {...informationProps} />
      {/* <SascCardTreatmentsArrow /> */}
    </div>
  );
}
