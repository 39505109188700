import { saveAs } from 'file-saver';
import { cloneDeep, isEmpty, set } from 'lodash';
import moment from 'moment';
import includes from 'lodash/includes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import justifyIcon from '../../assets/icon-justificar-glosa.png';
import SelectInput from '../../components/inputs/SelectInput';
import { apiSasc } from '../../services/api.service';
import { glossedGuidesService } from '../../services/glossedGuides.service';
import formatPrice from '../../utils/currency-utils.tsx';
import { formatStringDate } from '../../utils/date-utils.tsx';
import '../../themes/defaultStyles.css';
import Header from '../Navigation/Header.jsx';
import InputViewEdit from '../../components/inputs/InputViewEdit.js';
import FormInput from '../../components/inputs/FormInput.js';
// import { AiOutlineLeft } from 'react-icons/ai';

function GlossedGuides({ loading }) {
  const [filterData, setFilterData] = useState([]);
  const [activeMonth, setActiveMonth] = useState(moment().month() + 1);
  const [atualizou, setAtualizou] = useState(false);
  const [values, setValues] = useState({ year: moment().year() });
  // const [anos, setAnos] = useState([]);
  // const [meses, setMeses] = useState([]);
  // const [selectedYear, setSelectedYear] = useState({ ano: moment().year() });
  // const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  // const [selectedYear, setSelectedYear] = useState(null);
  // const [selectedMonth, setSelectedMonth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(activeMonth, moment().year());
  }, []);

  const fetchData = (month, year) => {
    loading(true);
    glossedGuidesService.findByMonth(month, year).then(
      (response) => {
        const data = response.data;

        setFilterData(data && data.glosa ? data.glosa : []);
        // setActiveMonth(month);
        setAtualizou(true);
        loading(false);
      },
      (e) => {
        console.log(e);
        setAtualizou(true);
        loading(false);
      }
    );
  };

  const openDetail = (item, justificarGlosa) => {
    navigate('/ItemDetailPage/' + justificarGlosa, {
      state: item,
    });
  };

  const onSearch = (name, value, setFieldValue) => {
    if (value.length > 0) {
      loading(true);
      const filteredData = filterData.map((convenio) => ({
        ...convenio,
        atendimento: convenio.atendimento.filter(
          (atendimento) =>
            includes(atendimento.paciente.toLowerCase(), value.toLowerCase()) ||
            includes(atendimento.hospital.toLowerCase(), value.toLowerCase())
        ),
      }));

      setFilterData(filteredData);
      setFieldValue(name, value);
      loading(false);
    } else {
      setFieldValue(name, '');
      fetchData(activeMonth, moment().year());
    }
  };

  // const handleSelectMesChange = (name, value) => {
  //   setSelectedMonth(value);
  //   if (selectedYear) {
  //     fetchData(value, selectedYear.ano);
  //   }
  // };

  // const handleSelectAnoChange = (name, value) => {
  //   setSelectedYear(value);
  //   if (selectedMonth) {
  //     fetchData(selectedMonth, value.ano);
  //   } else {
  //     fetchData(activeMonth, value.ano);
  //   }
  // };

  const exportExcel = async () => {
    try {
      const response = await apiSasc.get(
        `/public/servicosAReceber/export/${activeMonth}/${moment().year()}`
      );

      const blobData = new Blob([response.data], { type: 'text/csv' });
      const fileName = `${activeMonth}_${moment().year()}_analise.csv`;
      saveAs(blobData, fileName);
    } catch (error) {
      console.error('Erro ao baixar o arquivo:', error);
    }
  };

  const handleBackButton = () => {
    navigate('/home');
  };

  useEffect(() => {
    // const anoAtual = new Date().getFullYear();
    // const anos = Array.from({ length: 6 }, (v, i) => ({ ano: anoAtual - i }));
    // const monthNames = [
    //   'Janeiro',
    //   'Fevereiro',
    //   'Março',
    //   'Abril',
    //   'Maio',
    //   'Junho',
    //   'Julho',
    //   'Agosto',
    //   'Setembro',
    //   'Outubro',
    //   'Novembro',
    //   'Dezembro',
    // ];

    // const currentMonthIndex = new Date().getMonth();
    // const currentMonth = currentMonthIndex + 1;
    // const meses = [];

    // meses.push({
    //   id: currentMonth,
    //   name: monthNames[currentMonthIndex],
    //   mes: currentMonth,
    // });

    // monthNames.forEach((name, index) => {
    //   if (index !== currentMonthIndex) {
    //     meses.push({ id: index + 1, name, mes: index + 1 });
    //   }
    // });

    // setAnos(anos);
    // setMeses(meses);
    fetchData(activeMonth, moment().year());

    // setActiveMonth(currentMonth);
    // setSelectedMonth(currentMonth);
    // setSelectedYear({ ano: anoAtual });
  }, []);

  return (
    <div className="container-fluid">
      <Header handleBackButton={handleBackButton} />
      <div className="container">
        <h1 className="defaultStyles-titlePageCenter my-5 my-sm-0">
          {' '}
          Glosados
        </h1>
        <Formik
          validationSchema={{}}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
        >
          {({ values, errors, handleChange, setFieldValue, index }) => (
            <Form autoComplete="off" className="row">
              <div className="row section-form">
                {/* <FormGroup className="col-6 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    options={meses}
                    label="Mês"
                    valueKey="mes"
                    labelKey="name"
                    // placeholder="Selecione um mês"
                    placeholder={selectedMonth ? undefined : 'Selecione um mês'}
                    onChange={handleSelectMesChange}
                    value={selectedMonth}
                  ></InputViewEdit>
                </FormGroup>

                <FormGroup className="col-6 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    label="Ano"
                    name="ano"
                    id={'ano'}
                    // placeholder="Selecione um ano"
                    placeholder={selectedYear ? undefined : 'Selecione um ano'}
                    valueKey="ano"
                    labelKey="ano"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={false}
                    searchable={false}
                    options={anos}
                    onChange={handleSelectAnoChange}
                    value={selectedYear}
                  />
                </FormGroup> */}

                <FormGroup className="col-10 col-sm-10">
                  <InputViewEdit
                    component={FormInput}
                    label="Filtro"
                    placeholder="Pesquisar"
                    name="textoBusca"
                    type="text"
                    id="textoBusca"
                    value={values.textoBusca}
                    noSize={true}
                    erroMensagem={errors.nome}
                    required={false}
                    onChange={(name, value) => {
                      onSearch(name, value, setFieldValue);
                    }}
                  ></InputViewEdit>
                </FormGroup>

                <FormGroup className="col-2 col-sm-1">
                  <button onClick={exportExcel} className="bg-white border-0">
                    <img
                      className="defaultStyles-iconImgCard defaultStyles-btnExcelCard"
                      src={require('../../assets/icon-excel.png')}
                      alt="Export to Excel"
                    />
                  </button>
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>

        <div className="row">
          <div className="defaultStyles-tableCard">
            {filterData &&
              filterData.map((item, index) => (
                <div
                  key={index}
                  className={[
                    'defaultStyles-tableRowCard',
                    index % 2 === 0
                      ? 'defaultStyles-evenRowCard'
                      : 'defaultStyles-oddRowCard',
                    'defaultStyles-columnLayoutCard',
                  ].join(' ')}
                >
                  <div className="defaultStyles-tableCellCard">
                    <div className="defaultStyles-rowCard">
                      <span className="defaultStyles-boldText">
                        Núm.Atendimento:{' '}
                      </span>
                      <span className="defaultStyles-itemCard">
                        {' '}
                        {item.numeroAtendimento}
                      </span>
                    </div>
                  </div>

                  <div className="defaultStyles-tableCellCard">
                    <div className="defaultStyles-rowCard">
                      <span className="defaultStyles-boldText">
                        Realização:{' '}
                      </span>
                      <span
                        className="defaultStyles-itemCard"
                        ellipsizeMode="tail"
                      >
                        {formatStringDate(item.dataRealizacao)}
                      </span>
                    </div>
                  </div>
                  <div className="defaultStyles-tableCellCard">
                    <div className="defaultStyles-rowCard">
                      <span className="defaultStyles-boldText">Valor: </span>
                      <span
                        className="defaultStyles-itemCard"
                        ellipsizeMode="tail"
                      >
                        {' '}
                        {formatPrice(item.valorGlosado)}
                      </span>
                    </div>
                  </div>
                  <div className="defaultStyles-iconsCard">
                    <div
                      onClick={() => {
                        openDetail(item, true);
                      }}
                    >
                      <img
                        className="defaultStyles-iconCard"
                        src={justifyIcon}
                        alt="Justify icon"
                      />
                    </div>
                    <div
                      onClick={() => {
                        openDetail(item, false);
                      }}
                    >
                      <i className="defaultStyles-iconCard ionicons ion-md-eye" />
                    </div>
                  </div>
                </div>
              ))}
            {isEmpty(filterData) && atualizou && (
              <span className="text-center m-5 p-5 fw-bold d-flex align-items-center justify-content-center">
                Não existem registros para serem exibidos.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
const mapDispatch = ({ load: { loading } }) => ({
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(GlossedGuides);
