
import { filter, isNil } from 'lodash';

export const setValue = (list, value, prop) => {
  if (!isNil(value)) {
    let filterParam = {};
    filterParam[prop] = value;

    return filter(list, filterParam)[0];
  }
  return null;
};
