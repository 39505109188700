import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, set, isNil, get } from 'lodash';
import MedicalCareFirstStep from './MedicalCareFirstStep';
import ProgressComponent from '../../components/wizard/ProgressComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import MedicalCareSecondStep from './MedicalCareSecondStep';
import MedicalCareThirthStep from './MedicalCareThirthStep';
import MedicalCareFourStep from './MedicalCareFourStep';
import { MdCancel } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import api, {
  apiDynamic,
  apiSasc,
  oauthHeaderJson,
} from '../../services/api.service';
import {
  formatStringToDate,
  formatDateToStringEng,
  formatDateToString,
} from '../../utils/date-utils.tsx';
import Header from '../Navigation/Header';
import './style/medicalCare.css';
import '../../themes/defaultStyles.css';

const screenHeight = window.innerHeight;

const MedicalCare = ({ loading, success, error }) => {
  const [valuesState, setValuesState] = useState({
    procedures: [{}],
    imageList: [],
    imageGuide: [],
  });
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(4);
  const [printOrBack, setPrintOrBack] = useState(false);
  const [system, setSystem] = useState(null);
  const stepRef = useRef(null);

  const navigate = useNavigate();

  const handleBackButtonClick = (event) => {
    if (currentStep === 1) {
      console.log('vai pra home');
      navigate('/home');
    } else {
      prevStep();
    }
    event.preventDefault();
  };

  useEffect(() => {
    window.addEventListener('popstate', handleBackButtonClick);
    apiSasc.get('/getUserSystem').then(({ data }) => {
      setSystem(data);
    });
    return () => {
      window.removeEventListener('popstate', handleBackButtonClick);
    };
  }, []);

  const handleChange = (name, value, error) => {
    setValuesState((prevValues) => {
      const newValues = cloneDeep(prevValues);
      set(newValues, name, value);
      return newValues;
    });
    setErrors((prevErrors) => {
      const newErrors = cloneDeep(prevErrors);
      set(newErrors, name, error);
      return newErrors;
    });
  };

  const nextStep = () => {
    if (stepRef.current && stepRef.current.validateStep()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const converterContextToSascTreatmentsCreateSave = async (
    sascTreatmentsCreateContextType
  ) => {
    const patientData = sascTreatmentsCreateContextType.patient;
    const medicalInsuranceData =
      sascTreatmentsCreateContextType.medicalInsurance;
    const healthInstituteData = sascTreatmentsCreateContextType.healthInstitute;
    const localHospitalData = !isNil(
      sascTreatmentsCreateContextType.localHospital
    )
      ? sascTreatmentsCreateContextType.localHospital
      : null;

    const attachments = sascTreatmentsCreateContextType.imageList
      ? await Promise.all(
          sascTreatmentsCreateContextType.imageList.map(async (file) => {
            const fileBase64 = await fileToBase64(file);
            return {
              fileName: file.name,
              fileBase64,
            };
          })
        )
      : [];
    return {
      cpfPaciente: get(patientData, 'cpf'),
      carteirinha: sascTreatmentsCreateContextType.cardNumber,
      nomePaciente: patientData.nomeBeneficiario
        ? patientData.nomeBeneficiario
        : patientData.title,
      convenio: {
        id: medicalInsuranceData.id,
        nome: medicalInsuranceData.nome,
        valorConsulta: medicalInsuranceData.valorConsulta,
      },
      estabelecimento: healthInstituteData
        ? {
            id: healthInstituteData.id,
            nome: healthInstituteData.nome,
          }
        : null,
      hospitalLocalVO: !isNil(localHospitalData)
        ? { id: localHospitalData.id, nome: localHospitalData.nome }
        : null,
      tipoGuia: { id: sascTreatmentsCreateContextType.guideType },
      type: 'atendimentoApp',
      anexoEncaminhado:
        sascTreatmentsCreateContextType.attachmentForwarded === '1',
      numeroGuia: sascTreatmentsCreateContextType.guideNumber,
      procedimentoList: sascTreatmentsCreateContextType.procedures.map(
        (procedureInfo) => {
          const procedureData = procedureInfo.procedure;
          const professionalData = procedureInfo.professional;
          return {
            codigoProcedimento: procedureData.codigo,
            descricao: procedureData.descricao,
            cooperado: {
              id: professionalData.id,
              nome: professionalData.nome
                ? professionalData.nome
                : professionalData.nomeCooperado,
            },
            grau: procedureInfo.participateRate,
            dataAtendimento: !isNil(procedureInfo.date)
              ? formatDateToStringEng(formatStringToDate(procedureInfo.date))
              : undefined,
            horaAtendimento: procedureInfo.startTime,
            horaFinalAtendimento: procedureInfo.endTime,
            tec: procedureInfo.technique,
            via: procedureInfo.accessWay,
            tabela: '22',
            quantAutorizada: procedureInfo.amount,
          };
        }
      ),
      attachments: attachments,
      caraterAtendimento: sascTreatmentsCreateContextType.character,
      senha: sascTreatmentsCreateContextType.password,
    };
  };

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const saveTreatment = async () => {
    if (stepRef.current.validateStep()) {
      let conversao = await converterContextToSascTreatmentsCreateSave(
        valuesState
      );

      loading(true);
      try {
        const response = await apiDynamic.post(
          '/app/atendimento/save',
          conversao
        );
        let values = cloneDeep(valuesState);
        if (system !== 'SASCCONNECT') {
          values.id = response.data.id;
        } else {
          const location = response.headers.location.split('/');
          values.id = location[location.length - 1];
        }
        success('Atendimento salvo com sucesso.');
        loading(false);
        setValuesState(values);
        setPrintOrBack(true);

        // stepRef.current.printProcedure(values.id, values.guideType);
        return response.data;
      } catch (erro) {
        console.log('erro');
        console.log(JSON.stringify(erro));
        loading(false);
        let msg = erro?.response?.data.messages
          ? erro?.response?.data.messages[0].message.code
          : erro.message;

        console.log(msg);
        error(JSON.stringify(msg));
        loading(false);
      }
    } else {
      error('O formulário contém erros!');
    }
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const goBack = () => {
    setValuesState({ procedures: [{}], imageList: [], imageGuide: [] });
    setPrintOrBack(false);
    setCurrentStep(1);
  };

  const showConfirm = (user) => {
    const confirm = window.confirm(
      `Deseja realmente cancelar? Você perderá todos os dados preenchidos.`
    );
    if (confirm) {
      goBack();
    } else {
      console.log('Cancel');
    }
  };

  const converterSascTreatmentsCreateSaveToContext = (
    sascTreatmentsCreateSave,
    imageGuide
  ) => {
    const newValues = cloneDeep(valuesState);
    console.log('converterSascTreatmentsCreateSaveToContext');
    console.log(sascTreatmentsCreateSave);

    set(newValues, 'patient', sascTreatmentsCreateSave.beneficiario);
    set(newValues, 'medicalInsurance', sascTreatmentsCreateSave.convenio);
    set(
      newValues,
      'cardNumber',
      sascTreatmentsCreateSave.carteirinha
        ? sascTreatmentsCreateSave.carteirinha
        : get(sascTreatmentsCreateSave, 'beneficiario.numeroCarteira', null)
    );
    set(
      newValues,
      'attachmentForwarded',
      sascTreatmentsCreateSave.anexoEncaminhado ? 'true' : 'false'
    );
    set(newValues, 'guideNumber', sascTreatmentsCreateSave.numeroGuia);
    let listProcedure = [];

    sascTreatmentsCreateSave.procedimentoList &&
      sascTreatmentsCreateSave.procedimentoList.forEach((procedureSave) => {
        listProcedure.push({
          procedure: {
            codigo: get(procedureSave, 'codigoProcedimento'),
            descricao: get(procedureSave, 'descricao'),
            id: get(procedureSave, 'codigoProcedimento'),
            title:
              get(procedureSave, 'codigoProcedimento') +
              ' - ' +
              get(procedureSave, 'descricao'),
          },
          professional: get(sascTreatmentsCreateSave, 'cooperado'),
          // participateRate: '09',
          participateRate: get(procedureSave, 'grau'),
          date:
            newValues.guideType === '0'
              ? formatDateToString(new Date())
              : get(procedureSave, 'dataAtendimento'),
          startTime: get(procedureSave, 'horaAtendimento'),
          endTime: get(procedureSave, 'horaFinalAtendimento'),
          // technique: '1',
          technique: get(procedureSave, 'tec'),
          // accessWay: '3',
          accessWay: get(procedureSave, 'via'),
          // amount: '23',
          amount: JSON.stringify(procedureSave.quantAutorizada),
        });
      });

    // console.log(omit(newValues, ['SascAttachmentResponse', 'imageGuide']));
    set(newValues, 'procedures', listProcedure);
    set(newValues, 'SascAttachmentResponse', imageGuide);
    // console.log('novo Values');
    // console.log(get(newValues, 'procedures'));

    setValuesState(newValues);
  };

  return (
    <div className="container-fluid">
      <Header />

      <div className="container my-5 my-sm-0">
        <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">Novo Atendimento</h1>
        <div style={{ flex: 4 }}>
          <ProgressComponent actualStep={currentStep} totalSteps={totalSteps} />
          <div>
            {currentStep === 1 && (
              <MedicalCareFirstStep
                system={system}
                ref={stepRef}
                values={valuesState}
                handleChange={handleChange}
                errors={errors}
                converterSascTreatmentsCreateSaveToContext={
                  converterSascTreatmentsCreateSaveToContext
                }
                loading={loading}
              />
            )}
            {currentStep === 2 && (
              <MedicalCareSecondStep
                system={system}
                ref={stepRef}
                values={valuesState}
                handleChange={handleChange}
                errors={errors}
                loading={loading}
              />
            )}
            {currentStep === 3 && (
              <MedicalCareThirthStep
                system={system}
                ref={stepRef}
                values={valuesState}
                handleChange={handleChange}
                errors={errors}
                loading={loading}
              />
            )}
            {currentStep === 4 && (
              <MedicalCareFourStep
                system={system}
                ref={stepRef}
                values={valuesState}
                handleChange={handleChange}
                goBack={goBack}
                errors={errors}
                printOrBack={printOrBack}
                loading={loading}
                navigation={navigate}
              />
            )}
          </div>
        </div>
        {!printOrBack && (
          <div className="fixed-bottom d-flex align-items-center justify-content-center mb-5">
            {currentStep > 1 && (
              <button
                onClick={prevStep}
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                <FontAwesomeIcon
                  icon={faArrowCircleLeft}
                  size="2x"
                  color="#0D4E33"
                />
              </button>
            )}
            {currentStep <= totalSteps && (
              <button
                onClick={showConfirm}
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                <MdCancel size={39} color="#cc0303" />
              </button>
            )}
            {currentStep < totalSteps && (
              <button
                onClick={nextStep}
                style={{ backgroundColor: 'transparent', border: 'none' }}
              >
                <FontAwesomeIcon
                  icon={faArrowCircleRight}
                  size="2x"
                  color="#0D4E33"
                />
              </button>
            )}
            {currentStep === totalSteps && (
              <button
                className="btn btn-secondary"
                onClick={saveTreatment}
                title="Salvar"
              >
                Salvar
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(MedicalCare);
