import React from 'react';
import { isNil } from 'lodash';
import { formatDateTimeToString } from '../../../utils/date-utils.tsx';
import SascCardAttachmentProps from './interface/SascCardAttachmentProps';
import shortenFileName from '../../../utils/string-utils.tsx';
// import { ReactComponent as IconDelete } from '@ant-design/icons/DeleteOutlined'; // Assuming we're using Ant Design icons in React
import './styles/sascCardAttachment.css';

/**
 * SascCardAttachment
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function SascCardAttachment({
  identifier,
  uriImage,
  fileName,
  fileCreationDate,
  onDelete,
}: SascCardAttachmentProps) {
  return (
    <div className="viewContainer">
      <div className="viewImage">
        <img className="image" src={uriImage} alt="Attachment" />
        <div className="viewText">
          <span className="textFileName">{shortenFileName(fileName)}</span>
          <span className="textDate">
            {formatDateTimeToString(fileCreationDate)}
          </span>
        </div>
      </div>

      {/* TODO não vamos usar o ant, verificar novo componente */}
      {/* {!isNil(identifier) && !isNil(onDelete) && (
        <IconDelete
          style={{ fontSize: '20px', color: '#F76165' }} // Setting the styles inline for the icon
          onClick={() => onDelete(identifier)}
        />
      )} */}
    </div>
  );
}
