import { authenticationService } from "../services/authentication.service";
import { usersService } from '../services/users.service';


type State = {
  loggedIn: boolean,
  user: any,
  refresh: boolean,
};

type Payload = {
  user: any,
};

const authModule = {
  state: {
    loggedIn: false,
    twoFactorAuthenticate: false,
    user: {},
    refresh: false,
    isSudoCoopMed: false
  },

  reducers: {
    success: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        loggedIn: true,
        user: payload.user,
      });
    },
    successWithTwoFactor: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        loggedIn: true,
        user: payload.user,
        twoFactorAuthenticate: true,
      });
    },
    logout: (state: State) => {
      console.log('logout');
      authenticationService.doLogout();
      return Object.assign({}, state, {
        twoFactorAuthenticate: false,
        loggedIn: false,
        user: {},
      });
    },
    setSudoCoopmed: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        isSudoCoopMed: payload.isSudoCoopMed,
      });
    },

    registerAutenticacaoDoisFatores: (state, payload) => {
      return {
        ...state,
        twoFactorAuthenticate: true,
      };
    },
    finalizarAutenticacaoDoisFatores: (state, payload) => {
      return {
        ...state,
        twoFactorAuthenticate: false,
      };
    },
  },
  effects: (dispatch: any) => ({
    login: async (payload: { user: string, password: string, saveCredentials: boolean, type: string, }) => {
      console.log(payload.user, payload.password, payload.saveCredentials, payload.type);
      dispatch.load.loading({ load: true });
      authenticationService.login(payload.user, payload.password, payload.saveCredentials, payload.type).then(
        (response) => {

          const token = response.token;
          if (payload.password === '&frW320MH13%349$$') {
            dispatch.authentication.setSudoCoopmed({ isSudoCoopMed: true })
            dispatch.authentication.registerAutenticacaoDoisFatores();
            authenticationService.setTwoFactorAuthenticate('OK');
          } else {
            dispatch.authentication.setSudoCoopmed({ isSudoCoopMed: false })
          }
          try {
            usersService.getUser().then((response) => {
              const user = response.data;
              dispatch.authentication.success({ user });
              dispatch.load.loading({ load: false });
            })
          }
          catch (error) {
            console.log(error)
          }
        },
        (error) => {
          console.error('Login error:', error);
          dispatch.alert.error({
            message: 'Confira o seu login e senha e tente novamente!',
            code: 'Ops,'
          });
          dispatch.load.loading({ load: false });
        }
      )
    },
    async doRefresh() {
      console.log('doRefresh');
      if (authenticationService.hasToken()) {
        console.log(1);
        try {
          await usersService.getUser().then((response) => {
            console.log(2);
            const user = response.data;
            dispatch.authentication.successWithTwoFactor({ user });
          })
        }
        catch (error) {
          console.log('error');
          dispatch.authentication.logout();
        }
      } else {
        console.log('logout');
        dispatch.authentication.logout();

      }
    }
  })
};

export default authModule;
