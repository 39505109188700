//@flow
type State = {
  messages: Array<string>
};

type Payload = Array<string>;

type Effects = (dispatch: any) => ({
  addAllMessage: (Payload) => Promise<void>
});

const fieldMessage = {
  state: { messages: [] },
  reducers: {
    addMessage: (state: State, payload: Payload): State => {
      return { ...state, messages: payload };
    },
    clearMessage: (state: State, payload: Payload): State => {
      return { ...state, messages: [] };
    }
  },
  effects: (dispatch: any): Effects => ({
    addAllMessage: async (payload: Payload) => {
      if (payload && payload.length > 0) {
        dispatch.alert.clear();
        dispatch.alert.error({
          message:
            'Existem erros no formulário, verifique os campos em destaque.'
        });
      }
      dispatch.fieldMessage.addMessage(payload);
    }
  })
};

export default fieldMessage;
