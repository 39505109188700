import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import InputCheckbox from '../../components/Input/InputCheckbox';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import { objectConstants } from '../../constants/objects.constants';
import { authenticationService } from '../../services/authentication.service';
import './styles/loginDoctor.css';

const ITEM_REQUIRED = Yup.string().required('Campo obrigatório');

const loginValidate = Yup.object().shape({
  cpf: ITEM_REQUIRED
    .max(14, 'O CPF deve conter 11 digitos'),
  password: ITEM_REQUIRED,
});

const LoginDoctor = ({
  login,
  loggedIn,
  twoFactorAuthenticate,
  isSudoCoopMed,
  loading,
}) => {
  const navigate = useNavigate();
  const { type } = useParams();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);

  // const [renderizaCheck, setRenderizaCheck] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log('loggedIn disparado');
  }, [loggedIn]);

  const fetchData = async () => {
    try {
      // Simulating async call
      const credential = await authenticationService.getCredential();
      if (credential) {
        setValues({
          cpf: credential.username,
          password: credential.password,
          saveCredentials: true,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleChange = (name, value, error) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const goDirectToHome = async () => {
    let systemDefault = await authenticationService.getDefaultSystem();
    systemDefault = { sascConnect: false, demonstrativoPagamento: true };
    if (systemDefault) {
      navigate('/private-screens-navigator', { systemDefault });
    } else {
      navigate('/select-default-system');
    }
  };

  const handleSubmit = (formikValues, formikBag) => {
    const { cpf, password, saveCredentials } = formikValues;
    login(cpf, password, saveCredentials, type);
  };

  if (loggedIn && twoFactorAuthenticate) {
    return <Navigate to={'*'} />;
  } else if (loggedIn && !twoFactorAuthenticate && isSudoCoopMed) {
    goDirectToHome();
  } else if (loggedIn && !twoFactorAuthenticate && !isSudoCoopMed) {
    return <Navigate to={'/authentication-factors'} />;
  }

  return (
    <div className="container-login">
      <div className="headerLoginDoctor">
        <button
          className="backButtonLoginDoctor"
          onClick={handleBackButtonClick}
        >
          <span className="backButtonText">
            <AiOutlineLeft name={'left'} />
            Voltar
          </span>
        </button>
      </div>
      <div className="formContainerLoginDoctor">
        <div className="logoContainer">
          <img
            src={require('../../assets/logo.png')}
            alt="Logo"
            className="logoLoginDoctor"
          />
        </div>
        <div className="welcomeView">
          <span className="welcomeTextLoginDoctor">
            Seja bem vindo, Demonstrativo de Pagamento:
          </span>
        </div>

        <Formik
          validationSchema={loginValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values, { setSumit }) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldTouched,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <InputViewEdit
                component={CpfCnpjInput}
                label={'CPF'}
                type="text"
                id="cpf"
                name="cpf"
                placeholder="Digite o CPF"
                value={values.cpf}
                onChange={(name, value) => setFieldValue(name, value)}
                noSize={true}
                erroMensagem={errors.cpf}
                required={true}
              />
              <InputViewEdit
                component={FormInput}
                label={'Senha'}
                name={'password'}
                id={'password'}
                value={values.password}
                autoComplete={false}
                type={'password'}
                onChange={(name, value) => setFieldValue(name, value)}
                placeholder="Insira sua senha"
                maxLength={30}
                viewPassword={true}
                erroMensagem={errors.password}
                autocomplete="off"
                required={true}
              />
              <div className="checkboxContainer">
                <InputCheckbox
                  label={'Salvar login e senha.'}
                  onChange={setFieldValue}
                  checked={values.saveCredentials}
                  name={'saveCredentials'}
                />
              </div>
              <div className="buttonsContainer">
                <ButtonPrimary
                  style={{ marginLeft: 15 }}
                  text={'ENTRAR'}
                  onPress={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
        <div className="esqueciSenhaView">
          {type === objectConstants.TIPO_LOGIN_MEDICO && (
            <span
              className="esqueciSenhaTextLoginDoctor"
              onClick={() => {
                window.open('https://app.fencom.coop.br/recuperarAcesso.php');
              }}
            >
              Esqueci minha senha
            </span>
          )}
          {type === objectConstants.TIPO_LOGIN_OPERADOR && (
            <span
              className="esqueciSenhaTextLoginDoctor"
              onClick={() => {
                navigate('/recover-password');
              }}
            >
              Esqueci minha senha
            </span>
          )}
        </div>
        <span className="footerText">
          Ao continuar, você concorda em aceitar nosso Política de privacidade e
          termos de serviço.
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { load } = state.load;
  const { loggedIn, twoFactorAuthenticate, isSudoCoopMed } =
    state.authentication;
  return {
    loading: load,
    loggedIn,
    twoFactorAuthenticate,
    isSudoCoopMed,
  };
};

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
  authentication: { login },
}) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  login: (user, password, saveCredentials, type) =>
    login({ user, password, saveCredentials, type }),
});

export default connect(mapStateToProps, mapDispatch)(LoginDoctor);
