//@flow
import _ from 'lodash';
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import Async from "react-select/async";
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';


import { Label } from "reactstrap";
import { translate } from '../../_helpers/messages.helper';
//import "react-select/dist/react-select.css";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import './SelectInput.css';

type Props = {
  id?: string,
  label: string,
  name: string,
  value?: any,
  size?: number,
  onChange: any,
  parent?: any,
  onFetchData?: any,
  valueKey: string,
  labelKey: string,
  options?: any,
  multi?: boolean,
  required: boolean,
  messageValidate?: string,
  returnFullObject: boolean,
  clearable: boolean,
  creatable: boolean,
  onNewOptionClick: any,
  newOptionCreator: any,
  cache: boolean,
  placeholder: string,
  style: any,
  searchable?: boolean,
  disabled?: boolean,
  hideToolTip?: boolean
};

type State = {};
let timoutVar;
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default class SelectInput extends React.Component<Props, State> {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.state = {
      tooltipOpen: false,
      value: props.value ? props.value : null
    };
  }
  static defaultProps = {
    valueKey: "value",
    labelKey: "label",
    async: true,
    multi: false,
    label: "",
    required: false,
    returnFullObject: false,
    clearable: false,
    creatable: false,
    cache: true,
    placeholder: "Selecione",
    size: 12,
    id: moment.now.toString(),
    loadingPlaceholder: "Carregando"
  };

  handleSelectChange = (input: any) => {
    let props = this.props;

    if (this.props.returnFullObject) {
      //set valor com all opion no state e retorna sem all option
      let resultSemAll = props.isMulti && input ? input.filter(option => _.get(option, props.valueKey) !== _.get(props.allOption, props.valueKey)) : input;
      this.setState({ value: input }, () => {
        this.props.onChange(this.props.name, resultSemAll);
      });
    } else {
      this.props.onChange(
        this.props.name,
        input ? input[this.props.valueKey] : null
      );
    }
  };

  onChange = (selected, event) => {
    let props = this.props;
    if (props.allowSelectAll) {
      if (selected !== null && selected.length > 0) {
        if (
          selected[selected.length - 1][props.valueKey] ===
          props.allOption[props.valueKey]
        ) {
          return this.handleSelectChange([props.allOption, ...props.options]);
        }
        let result = [];
        if (selected.length === props.options.length) {
          if (selected.includes(props.allOption)) {
            result = selected.filter(
              option =>
                option[props.valueKey] !== props.allOption[props.valueKey]
            );
          } else if (event.action === "select-option") {
            result = [props.allOption, ...props.options];
          }
          return this.handleSelectChange(result);
        }
      }
    }

    return this.handleSelectChange(selected);
  };

  handleBorderColor = messageValidate => {
    let borderColor, borderWidth;
    if (messageValidate) {
      borderColor = "#ea3a45";
      borderWidth = 2;
    }
    return {
      borderColor,
      borderWidth
    };
  };

  handleFocus = () => {
    const { messageValidate } = this.props;
    if (messageValidate) {
      return (
        <ToolTipFeedBack
          target={this.props.id}
          messageValidate={messageValidate}
        />
      );
    }
  };

  clearThisTimout = () => {
    clearTimeout(timoutVar);
  };

  fetchData = (input: string, callback) => {
    this.clearThisTimout();
    let p: Promise<any> = Promise.resolve({ options: [] });
    if (!input) {
      return p;
    }
    timoutVar = setTimeout(() => {
      if (this.props.onFetchData) {
        let fetch;
        if (this.props.parent !== null && this.props.parent) {
          if (this.props.defaultParent) {
            fetch = this.props.onFetchData(input, this.props.parent, this.props.defaultParent);
          } else {
            fetch = this.props.onFetchData(input, this.props.parent);
          }

        } else {
          fetch = this.props.onFetchData(input);
        }
        fetch.then(
          json => {
            let result = []
            if (_.isArray(json)) {
              result = json;
            } else {
              result = json.data.data ? json.data.data : json.data;
            }

            callback(result);
          },
          () => {
            callback(p);
          }
        );
      } else {
        callback(p);
      }
    }, 1000);
  };

  componentDidUpdate(prevProps: Props) {

    if (prevProps.erroMensagem !== this.props.erroMensagem) {
      this.setState({
        prevErroMensagem: prevProps.erroMensagem,
      });
    }
  }

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  arrowRenderer = () => {
    return (
      <div className={""}>{!this.props.removeDropDown && <ChevronDown />}</div>
    );
  };

  render() {
    const { messages, name, valueKey } = this.props;
    let colSize;
    if (this.props.size) {
      colSize = "col";
      colSize += "-" + this.props.size;
    } else {
      colSize = ''
    }


    let value = this.props.value || null;

    if (!this.props.returnFullObject && value !== "" && this.props.options) {
      this.props.options.forEach(element => {
        if (element[valueKey] === value) {
          value = element;
        }
      });
    }
    if (!this.props.value && this.props.defaultValue) {
      value = this.props.defaultValue;
    }
    let TypeComponent;
    if (this.props.onFetchData) {
      if (this.props.creatable) {
        TypeComponent = AsyncCreatableSelect;
      } else {
        TypeComponent = Async;
      }
    } else {
      if (this.props.creatable) {
        TypeComponent = CreatableSelect;
      } else {
        TypeComponent = Select;
      }
    }
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName === name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    let hideToolTip;

    if (this.props.hideToolTip) {
      hideToolTip = this.props.hideToolTip;
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    let props = this.props;
    const menuPortalTarget = document.getElementById("root");

    value = !_.isNil(value) && !_.isEmpty(value) ? value : null;

    return (
      <div
        className={`${this.props.required ? "input-required" : "no-required"} ${colSize}`}
      >
        {this.props.icone && (
          <img src={this.props.icone} className={`icone-label ${this.props.classIcone}`} />
        )}
        {this.props.label && (
          <React.Fragment>
            <Label
              for={this.props.name}
              className="label-nowrap"
              title={this.props.label}
            >
              {this.props.label}
              {this.props.required && "*"}
            </Label>{" "}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={"help_" + this.props.id} />
                <ToolTipHelp
                  target={"help_" + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <TypeComponent
          components={this.props.components}
          formatOptionLabel={this.props.formatOptionLabel}
          optionRenderer={this.props.optionRender && this.props.optionRender}
          id={this.props.id ? this.props.id : this.props.name}
          name={this.props.name}
          loadOptions={this.fetchData}
          onChange={this.onChange}
          autoComplete='new-password'
          getOptionLabel={data => {
            // "data" contains the current option object, so you just use your "labelKey" to return the value of that property.
            return _.get(data, this.props.labelKey);
          }}
          getOptionValue={data => {
            // "data" contains the current option object, so you just use your "valueKey" to return the value of that property.
            return _.get(data, this.props.valueKey);
          }}
          getNewOptionData={(inputValue, optionLabel) => {
            let option = {}
            if (this.props.formatValue) {
              optionLabel = this.props.formatValue(optionLabel);
            }
            option[this.props.labelKey] = optionLabel;
            return option;
          }}
          value={value}
          searchable={this.props.searchable}
          noOptionsMessage={() =>
            !this.props.noResultsText
              ? "Nenhum resultado encontrado."
              : this.props.noResultsText
          }
          searchPromptText="Digite para buscar"
          placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
          clearValueText="Limpar valor"
          loadingPlaceholder={this.props.loadingPlaceholder}
          clearAllText="Limpar todos"
          formatCreateLabel={(label: string) => {
            return `Deseja cadastrar "${label}"?`;
          }}
          createOptionPosition="first"
          menuPlacement="auto"
          menuPortalTarget={this.props.apendRoot && menuPortalTarget}
          cacheOptions={this.props.cache}
          style={this.handleBorderColor(messageValidate)}
          multi={this.props.multi}
          options={
            !props.allowSelectAll
              ? this.props.options
              : [props.allOption, ...props.options]
          }
          isClearable={this.props.clearable}
          isCreatable={this.props.creatable}
          onCreateOption={this.props.onNewOptionClick}
          required={this.props.required}
          isDisabled={this.props.disabled}
          className={`Select ${this.props.className} ${messageValidate ? "is-invalid" : ""
            }`}
          optionClassName={"selectMenuItem"}
          classNamePrefix="react-select"
          isMulti={this.props.isMulti}
          closeMenuOnSelect={this.props.closeMenuOnSelect}
          hideSelectedOptions={this.props.hideSelectedOptions}
          onClickDropDown={this.props.onClickDropDown}
          onBlur={this.props.onBlur}

        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id ? this.props.id : this.props.name}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

SelectInput.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    nome: PropTypes.string,
    id: PropTypes.string
  })
};

SelectInput.defaultProps = {
  allOption: {
    nome: "Selecionar todos",
    id: "-1"
  }
};

// function mapStateToProps(state) {
//   const { messages } = state.fieldMessage;
//   const { alert } = state;

//   return {
//     messages,
//     alert
//   };
// }

// export default connect(mapStateToProps)(SelectInput);
