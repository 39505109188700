import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { usersService } from '../../services/users.service';
import { theme } from '../../theme';
import { useNavigate } from 'react-router-dom';
import { IoTrash } from 'react-icons/io5';
import { IoPencilOutline } from 'react-icons/io5';
import './styles/usuario.css';
import '../../themes/defaultStyles.css';
import { omit } from 'lodash';
import Header from '../Navigation/Header';

function UsuarioList({ loading, error, success }) {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(0);
  }, []);

  const fetchData = async (page) => {
    try {
      loading(true);
      const response = await usersService.findPageble(page);
      const data = response.data;
      setUsers(data);
      loading(false);
    } catch (error) {
      console.log(error);
      // TODO não vamos usar, verificar novo componente
      error(
        'Ocorreu um erro ao obter os dados dos operadores! Tente mais tarde.'
      );
      loading(false);
    }
  };

  const addUser = () => {
    navigate('/Usuario');
  };

  const showConfirm = (user) => {
    if (
      window.confirm(`Deseja realmente apagar esse operador ${user.name} ?`)
    ) {
      remover(user);
    }
  };

  const editUser = (user) => {
    navigate('/Usuario', { state: omit(user, 'password') });
  };

  const remover = async (user) => {
    try {
      loading(true);
      await usersService.remover(user.id);
      success('Operador excluído com sucesso!');
      fetchData(0);
      loading(false);
    } catch (error) {
      error('Ops!!! Erro ao excluir usuário');
      console.log(error);
      loading(false);
    }
  };

  return (
    <div className="container-fluid">
      <Header />

      <div className="container my-5 my-sm-0">
        <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">OPERADOR</h1>
        <div className="text-center">
          <button className="btn btn-secondary" onClick={addUser}>
            CADASTRAR OPERADOR
          </button>
        </div>
        <div className="tableCard">
          {users.map((user, index) => (
            <div
              key={index}
              className={`${
                index % 2 === 0 ? 'evenRowCard' : 'oddRowCard'
              } rowLayoutCard userRow`}
            >
              <div className="fullWidth">
                <div className="tableCellCard">
                  <div className="rowCard">
                    <strong className="boldText">Nome:</strong>
                    <span> {user.name}</span>
                  </div>
                </div>
                <div className="tableCellCard">
                  <div className="rowCard">
                    <strong className="boldText">CPF:</strong>
                    <span> {user.cpf}</span>
                  </div>
                </div>
                <div className="tableCellCard">
                  <div className="rowCard">
                    <strong className="boldText">Email:</strong>
                    <span> {user.email}</span>
                  </div>
                </div>
                <div className="tableCellCard">
                  <div className="rowCard">
                    <strong className="boldText">Telefone:</strong>
                    <span> {user.telefone}</span>
                  </div>
                </div>
              </div>
              <div className="iconsCard">
                <button
                  onClick={() => editUser(user)}
                  className="iconCard bg-transparent"
                >
                  <IoPencilOutline size={24} color={theme.colors.black} />
                </button>
                <button
                  onClick={() => showConfirm(user)}
                  className="iconCard bg-transparent"
                >
                  <IoTrash size={24} color={theme.colors.error} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(UsuarioList);
