import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputPassword from '../../components/Input/InputPassword';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import InputCheckbox from '../../components/Input/InputCheckbox';
import InputCPF from '../../components/Input/InputCpf';
import { authenticationService } from '../../services/authentication.service';
import { objectConstants } from '../../constants/objects.constants';
import { onlyNumbers } from '../../utils/identifier-utils.tsx';
import { cloneDeep, isEmpty, set, some } from 'lodash';
import { apiSasc } from '../../services/api.service';
import { AiOutlineLeft } from 'react-icons/ai';
import { connect } from 'react-redux';

import './styles/recoverPassword.css';

function RecoverPassword({ success, loading }) {
  const navigate = useNavigate();
  const [state, setState] = useState({
    values: {},
    errors: {},
    submit: false,
  });

  useEffect(() => {
    const getCredential = async () => {
      const credential = await authenticationService.getCredential();
      if (credential) {
        setState((prevState) => ({
          ...prevState,
          values: { cpf: credential.username, password: credential.password },
        }));
      }
    };
    getCredential();
  }, []);

  const handleBackButtonClick = () => {
    navigate.goBack();
  };

  const handleChange = (name, value, error) => {
    setState((prevState) => {
      const values = cloneDeep(prevState.values);
      const errors = cloneDeep(prevState.errors);
      set(values, name, value);
      set(errors, name, error);
      return { values, errors };
    });
  };

  const handleSubmit = () => {
    const { errors, values } = state;
    if (isEmpty(errors, true) || !some(errors)) {
      loading(true);
      const cpf = onlyNumbers(values.cpf);
      apiSasc.post(`/api/operadores/send-temp-password/${cpf}`).then(
        (response) => {
          loading(false);
          success('Uma nova senha foi enviada para o e-mail cadastrado');
          handleBackButtonClick();
        },
        (error) => {
          loading(false);
          console.error(JSON.stringify(error));
        }
      );
    } else {
      setState((prevState) => ({ ...prevState, submit: true }));
    }
  };

  const { submit, errors, values } = state;

  return (
    <div className="containerRecoverPassword">
      <div className="card">
        <div className="header">
          {/* <button className="backButton" onClick={handleBackButtonClick}>
            <span className="backButtonText">
              <AiOutlineLeft name={'left'} />
              Voltar
            </span>
          </button> */}
        </div>
        <div className="logoContainer">
          <img
            src={require('../../assets/logo.png')}
            className="logo"
            alt="logo"
          />
        </div>

        <div className="formContainer">
          <div className="CPFinputContainer">
            <label className="formLabel">CPF</label>
            <InputCPF
              required={true}
              value={values.cpf}
              submit={submit}
              onChangeText={handleChange}
              name={'cpf'}
              className="w100"
            />
            <span
              className={`formHelperText ${
                submit && errors['cpf'] ? 'formErrorText' : ''
              }`}
            >
              {submit && errors['cpf'] ? errors['cpf'] : ''}
            </span>
          </div>

          <div className="buttonsContainer_">
            <ButtonPrimary
              text={'RECUPERAR SENHA'}
              className="buttonContainer_password"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  info: (msg) => info(msg),
  loading: (load) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

const connectedRecoverPassword = connect(null, mapDispatch)(RecoverPassword);

export default connectedRecoverPassword;
