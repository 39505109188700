import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { some, isEmpty } from 'lodash';
import { Formik } from 'formik';
import { Form, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import { usersService } from '../../services/users.service';
import FormInput from '../../components/inputs/FormInput';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles/usuario.css';
import Header from '../Navigation/Header';
import { validateCPF } from '../../utils/input-utils.tsx';
// import PhoneInput from '../../components/inputs/PhoneInput.js';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');

const UsuarioValidateModel = Yup.object().shape({
  name: ITEM_REQUIRED,
  email: Yup.string()
    .email('Formato de e-mail inválido')
    .required('Obrigatório'),
  telefone: Yup.string()
    .matches(
      /^\(\d{2}\)\s\d{4,5}-\d{4}$/,
      'Formato de telefone inválido. Formato esperado: (XX) XXXXX-XXXX'
    )
    .required('Obrigatório'),
  cpf: Yup.string()
    .matches(
      /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
      'Formato de cpf inválido. Formato esperado: XXX.XXX.XXX-XX'
    )
    .test(
      'is-valid-cpf',
      'CPF inválido',
      (value) => value && validateCPF(value)
    )
    .required('Obrigatório'),
  password: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .required('Obrigatório'),
});

function Usuario({ error, loading, success }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(location.state ? location.state : {});
  const [userBoolean, setUserBoolean] = useState(
    location.state?.id ? true : false
  );
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate('/UsuarioList');
      return true;
    };

    window.addEventListener('popstate', handleBackButtonClick);

    return () => {
      window.removeEventListener('popstate', handleBackButtonClick);
    };
  }, [navigate]);

  const handleChange = (name, value, error) => {
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = (formikValues) => {
    const { cpf } = formikValues;
    if (isEmpty(errors, true) || !some(errors)) {
      usersService.save(formikValues).then(
        (r) => {
          success(
            userBoolean
              ? { message: 'Operador editado com sucesso!' }
              : { message: 'Operador cadastrado com sucesso!' }
          );
          navigate('/UsuarioList');
        },
        (e) => {
          console.error(e);
          const err = e.status;
          console.log(err);
          if (err === 400) {
            error('CPF já cadastrado!');
            setSubmit(true);
            handleChange('cpf', cpf, 'CPF já cadastrado');
          }
        }
      );
    } else {
      setSubmit(true);
    }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className="container-fluid">
      <Header handleBackButton={handleBackButton} />

      <div className="container my-5 my-sm-0">
        <h1 className="defaultStyles-titlePageCenter mt-5 mt-sm-0">
          {userBoolean ? 'editar operador' : 'novo operador'}
        </h1>
        <Formik
          validationSchema={UsuarioValidateModel}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off" className="row">
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={FormInput}
                  label={'Escreva o nome'}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Escreva o nome"
                  value={values.name}
                  onChange={(name, value) => setFieldValue(name, value)}
                  noSize={true}
                  erroMensagem={errors.name}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={FormInput}
                  label={'Escreva o E-mail'}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Escreva o E-mail"
                  value={values.email}
                  onChange={(name, value) => setFieldValue(name, value)}
                  noSize={true}
                  erroMensagem={errors.email}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={FormInput}
                  label={'Escreva o Telefone'}
                  type="text"
                  id="telefone"
                  name="telefone"
                  placeholder="Celular (Somente números com DDD)"
                  value={values.telefone}
                  onChange={(name, value) => setFieldValue(name, value)}
                  noSize={true}
                  erroMensagem={errors.telefone}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={CpfCnpjInput}
                  label={'CPF'}
                  type="text"
                  id="cpf"
                  name="cpf"
                  placeholder="Digite o CPF"
                  value={values.cpf}
                  onChange={(name, value) => setFieldValue(name, value)}
                  noSize={true}
                  erroMensagem={errors.cpf}
                  required={true}
                />
              </FormGroup>
              <FormGroup className="col-12 col-sm-6">
                <InputViewEdit
                  component={FormInput}
                  label={'Senha'}
                  name={'password'}
                  id={'password'}
                  value={values.password}
                  autoComplete={false}
                  type={'password'}
                  onChange={(name, value) => setFieldValue(name, value)}
                  placeholder={'Mínimo de 6 caracteres'}
                  maxLength={30}
                  viewPassword={true}
                  erroMensagem={errors.password}
                  autocomplete="off"
                  required={true}
                />
              </FormGroup>
              <div className="defaultStyles-buttonsContainer">
                <button
                  className="btn btn-secondary"
                  onClick={handleSubmit}
                  type="button"
                >
                  {userBoolean ? 'EDITAR' : 'CADASTRAR'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(Usuario);
