import React, { Component } from 'react';
import InputEmail from '../../components/Input/InputEmail';
// import './RegisterCodeAccessQrCodePage.css';

export default class RegisterCodeAccessQrCodePage extends Component {
  handleSubmit = () => {
    // You will need to implement this method according to your needs
  };

  render() {
    return (
      <div className="card">
        <div className="logoContainer">
          <img
            className="logo"
            src={require('../../assets/logo.png')}
            alt="Logo"
          />
        </div>
        <h1 className="title">Registro de Código de Acesso</h1>
        <p className="subtitle">
          Por favor, confirme o QrCode para onde o Código de Acesso será
          enviado.
        </p>
        <div className="inputContainer">
          <label className="inputLabel" htmlFor="inputEmail">
            Email
          </label>
          <InputEmail id="inputEmail" onChange={() => {}} />
        </div>

        <div className="buttonContainer">
          <button className="buttonPesquisar" onClick={this.handleSubmit}>
            ENVIAR CÓDIGO
          </button>
        </div>
      </div>
    );
  }
}

// The CSS content below is a direct conversion from the React Native StyleSheet
// Some styles need to be scoped to match the components' classNames
// Additional CSS properties like box-sizing are added for consistency in web styling
// The exact values are kept, but the translate to web-specific properties where necessary

/* RegisterCodeAccessQrCodePage.css */
// .card {
//   width: 100%;
//   border-radius: 8px;
//   border: 1px solid #707070;
//   background-color: #FFFFFF;
//   padding: 50px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   box-sizing: border-box;
// }

// .logoContainer {
//   align-items: center;
//   display: flex;
//   justify-content: center;
// }

// .logo {
//   width: 312px;
//   height: 65px;
//   object-fit: contain;
// }

// .title {
//   font-size: 25px;
//   /*  fontFamily: 'Montserrat-Regular', */
//   color: #0d4e33;
//   margin: 24px 16px;
// }

// .subtitle {
//   font-size: 18px;
//   /* fontFamily: 'Montserrat-Regular', */
//   color: #000;
//   margin: 0 16px 24px;
// }

// .inputContainer {
//   margin: 0 16px 24px;
// }

// .inputLabel {
//   font-size: 12px;
//   /* fontFamily: 'Montserrat-Medium', */
//   font-weight: 500;
// }

// .input {
//   height: 48px;
//   border-radius: 8px;
//   border: 1px solid #c4c4c4;
//   padding: 0 16px;
//   margin-top: 8px;
// }

// .buttonContainer {
//   display: flex;
//   justify-content: space-between;
//   margin: 0 16px;
// }

// .buttonPesquisar {
//   background-color: #097a5e;
//   border-radius: 8px;
//   height: 48px;
//   flex: 1;
//   margin-right: 8px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: none;
//   color: #fff;
//   cursor: pointer;
// }

// .buttonText {
//   font-size: 15px;
//   /* fontFamily: 'Montserrat-Bold', */
//   font-weight: 700;
//   color: #fff;
//   letter-spacing: 0.05em;
// }

// /* Note: The buttonCancelar class was not used in the React Native component,
//    but is included here for completeness. If you don't need it, you can remove it. */
// .buttonCancelar {
//   background-color: #697670;
//   border-radius: 8px;
//   height: 48px;
//   flex: 1;
//   margin-left: 8px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: none;
//   color: #fff;
//   cursor: pointer;
// }
