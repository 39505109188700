import { get, isArray, isEmpty, isNil } from 'lodash';

export default function converterDropdownItem(from, labelName, valueName) {
  if (!isNil(from) && !isEmpty(from)) {
    const newItemType = [];
    from.forEach((value) => {
      newItemType.push({
        label: !isArray(labelName)
          ? get(value, labelName)
          : labelName
              .map((label) => {
                const innerValue = get(value, label);
                return !isNil(innerValue) ? innerValue : null;
              })
              .filter((label) => !isNil(label))
              .join(' - '),
        value:
          valueName === 'JSON' ? JSON.stringify(value) : get(value, valueName),
      });
    });
    return newItemType;
  }
  return [];
}
