//@flow
type State = {
    menuAberto: boolean,
};

const initialState: State = { menuAberto: false };

const reducers = {
    toggleMenu: (state: State) => {
        return { ...state, menuAberto: !state.menuAberto };
    },
    abreMenu: (state: State) => {
        return { ...state, menuAberto: true };
    },
    fechaMenu: (state: State) => {
        return { ...state, menuAberto: false };
    },
};

const menu = {
    state: initialState,
    reducers,
};

export default menu;
