import moment from 'moment-timezone';

/**
 * TimeZone default.
 *
 */
const timeZoneDefault = 'America/Sao_Paulo';
const defaultFormatDate = 'DD/MM/YYYY';
const defaultFormatDateEng = 'YYYY-MM-DD';
const defaultFormatTime = 'HH:mm';

/**
 * Export date to string
 *
 * @param date
 * @param format
 */
export const formatDateToString = (date, format = defaultFormatDate) =>
  moment(date).tz(timeZoneDefault).format(format);

/**
 * Export date to string
 *
 * @param date
 * @param format
 */
export const formatDateToStringEng = (date, format = defaultFormatDateEng) =>
  moment(date).tz(timeZoneDefault).format(format);

/**
 * Export date time to string
 *
 * @param date
 * @param format
 */
export const formatDateTimeToString = (
  date,
  format = `${defaultFormatDate} [às] ${defaultFormatTime}`
) => moment(date).tz(timeZoneDefault).format(format);

/**
 * Export time to string
 *
 * @param date
 */
export const formatTimeToString = (date) =>
  moment(date).tz(timeZoneDefault).format(defaultFormatTime);

/**
 * Export date to string
 *
 * @param date
 */
export const formatStringToDate = (date) =>
  moment(date, defaultFormatDate, timeZoneDefault).toDate();

/**
 * Export date to string
 *
 * @param date
 * @param months
 */
export const addMonthInDate = (date, months) =>
  moment(date).add(months, 'M').toDate();

/**
 * Export date to string
 *
 * @param date
 * @param months
 */
export const decreaseMonthInDate = (date, months) =>
  moment(date).subtract(months, 'months').toDate();

/**
 * Export date to string
 *
 * @param date
 * @param days
 */
export const addDaysInDate = (date, days) =>
  moment(date).add(days, 'days').toDate();

/**
 * Export date to string
 *
 * @param date
 * @param days
 */
export const decreaseDaysInDate = (date, days) =>
  moment(date).subtract(days, 'days').toDate();

/**
 * Export date to string
 *
 * @param date
 */
export const formatStringDate = (date) => {
  if (date) {
    const dateFormat = moment(date).format('DD/MM/YYYY');
    return dateFormat;
  }
  return null;
};

/**
 * Export date to string
 *
 * @param date1
 * @param date2
 */
export const checkIsBefore = (date1, date2) => {
  const date1Format = moment(date1, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const date2Format = moment(date2, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return moment(date1Format).isBefore(date2Format);
};

/**
 * Export date to string
 *
 * @param date1
 * @param date2
 */
export const checkIsAfter = (date1, date2) => moment(date1).isAfter(date2);

/**
 * Export date to string
 *
 * @param date
 */
export const checkIsBeforeToday = (date) => {
  const dateFormat = moment(date, 'YYYY-MM-DD').toDate();
  return moment(dateFormat).isBefore(new Date());
};

/**
 * Export date to string
 *
 * @param date
 */
export const checkIsAfterToday = (date) => {
  const dateFormat = moment(date, 'YYYY-MM-DD').toDate();
  return moment(dateFormat).isAfter(new Date());
};
