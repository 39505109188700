import React, { useState, useEffect, useRef } from 'react';
import {
  union,
  omitBy,
  isEmpty,
  cloneDeep,
  includes,
  filter,
  set,
} from 'lodash';
import moment from 'moment';
import formatPrice from '../../utils/currency-utils.tsx';
import CustomModalDetails from '../../components/modal/CustomModalDetails';
import { paymentsMadeService } from '../../services/paymentsMade.service';
import { getYears } from '../../services/api.service';
import SummaryMenu from '../../components/menu/SumaryMenu';
import { formatDateToString } from '../../utils/date-utils.tsx';
import { AiOutlineLeft } from 'react-icons/ai';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SelectInput from '../../components/inputs/SelectInput';
import Header from '../Navigation/Header';
import '../../themes/defaultStyles.css';
import './styles/paymentsMade.css';
import InputViewEdit from '../../components/inputs/InputViewEdit.js';
import { FormGroup } from 'reactstrap';
import { Form, Formik } from 'formik';

function PaymentsMade({ loading }) {
  const [payments, setPayments] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [activeMonth, setActiveMonth] = useState(moment().month() + 1);
  const [atualizou, setAtualizou] = useState(false);
  const [values, setValues] = useState({ year: moment().year() });
  const [numeroRepasse, setNumeroRepasse] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [contentModalDetails, setContentModalDetails] = useState(null);
  const refModal = useRef(null);
  const [anos, setAnos] = useState([]);
  const [meses, setMeses] = useState([]);
  // const [selectedYear, setSelectedYear] = useState({ ano: moment().year() });
  // const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(activeMonth, moment().year());
  }, []);

  const fetchData = (month, year) => {
    loading(true);
    paymentsMadeService.findByMonth(month, year).then(
      (response) => {
        const paymentsData = response.data;
        setPayments(paymentsData);
        setActiveMonth(month);
        setAtualizou(true);
        loading(false);
      },
      (e) => {
        console.log(e);
        setAtualizou(true);
        loading(false);
      }
    );
  };

  // const handleSelectMesChange = (name, value) => {
  //   setSelectedMonth(value);
  //   fetchData(value, selectedYear.ano || moment().year());
  // };

  // const handleSelectAnoChange = (name, value) => {
  //   setSelectedYear(value);
  //   fetchData(selectedMonth || activeMonth, value.ano);
  // };

  const handleSelectMesChange = (name, value) => {
    setSelectedMonth(value);
    if (selectedYear) {
      fetchData(value, selectedYear.ano);
    }
  };

  const handleSelectAnoChange = (name, value) => {
    setSelectedYear(value);
    if (selectedMonth) {
      fetchData(selectedMonth, value.ano);
    } else {
      fetchData(activeMonth, value.ano);
    }
  };

  const getItemBox = (item, i) => {
    return (
      <div
        key={i + '_' + item.valor}
        className={`d-flex justify-content-between align-items-center py-2 ${
          i % 2 === 0 ? 'evenRowCardGreen' : 'oddRowCard'
        }`}
      >
        <span className="tipo">{item.tipo}</span>
        <span className="lancamento">{item.lancamento}</span>
        <span
          className={`valor ${
            item.tipo === 'C'
              ? 'credito'
              : item.tipo === 'L'
              ? 'liquido'
              : 'debito'
          }`}
        >
          {item.valor}
        </span>
      </div>
    );
  };

  const formataListaRepasse = (array) => {
    let totalCredito = 0.0;
    let totalDespesas = 0.0;
    let totalLiquido = 0.0;
    let lancamentos = [];
    let lancamentosLiquidos = [];
    let listaFormatada = {};
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const result = omitBy(item, (value) => value === 0 || value === null);
      if (result.hasOwnProperty('credito')) {
        result.tipo = 'C';
        result.valor = '+ ' + formatPrice(result.credito);
        totalCredito = totalCredito + parseFloat(result.credito);
        delete result.credito;
      }
      if (result.hasOwnProperty('desconto')) {
        result.tipo = 'D';
        result.valor = '- ' + formatPrice(result.desconto);
        totalDespesas = totalDespesas + parseFloat(result.desconto);
        delete result.desconto;
      }
      if (result.hasOwnProperty('liquido')) {
        result.tipo = 'L';
        result.valor = ' ' + formatPrice(result.liquido);
        totalLiquido = totalLiquido + parseFloat(result.liquido);
        delete result.liquido;
      }

      if (result.tipo === 'L') {
        lancamentosLiquidos.push(result);
      } else {
        lancamentos.push(result);
      }
    }

    listaFormatada.lancamentos = lancamentos.concat(lancamentosLiquidos);
    listaFormatada.totalCredito = formatPrice(totalCredito);
    listaFormatada.totalDespesas = formatPrice(totalDespesas);
    listaFormatada.totalLiquido = formatPrice(totalLiquido);

    return listaFormatada;
  };

  const formataListaDetalhes = (array) => {
    let listaDetalheFormatada = [];

    for (let i = 0; i < array.length; i++) {
      let atendimento = array[i].atendimentos[0];
      let novoObjeto = {};
      if (atendimento.hasOwnProperty('paciente')) {
        set(novoObjeto, 'paciente', atendimento.paciente);
        delete atendimento.paciente;
      }
      if (atendimento.hasOwnProperty('valor')) {
        set(novoObjeto, 'valor', atendimento.valor);
        delete atendimento.valor;
      }
      if (atendimento.hasOwnProperty('lancamento')) {
        set(novoObjeto, 'lancamento', atendimento.lancamento);
        delete atendimento.lancamento;
      }
      const chavesParaExcluir = [
        'convenioOrigem',
        'dataInternacao',
        'guia',
        'senha',
        'tipoLancamento',
      ];

      let novoObjetoFormat = excluirItensComChaves(
        atendimento,
        chavesParaExcluir
      );

      set(novoObjeto, 'atendimentos', novoObjetoFormat);
      listaDetalheFormatada.push(novoObjeto);
    }
    return listaDetalheFormatada;
  };

  const excluirItensComChaves = (objeto, chavesParaExcluir) => {
    let newObject = cloneDeep(objeto);
    for (const chave of chavesParaExcluir) {
      if (newObject.hasOwnProperty(chave)) {
        delete newObject[chave];
      }
    }
    return newObject;
  };

  const verDetalhesRepasse = (numeroRepasse) => {
    loading(true);
    paymentsMadeService.getTransferDetails(numeroRepasse).then(
      (response) => {
        const contentModal = formataListaDetalhes(response.data);
        setNumeroRepasse(numeroRepasse);
        setContentModalDetails(contentModal);
        setShowModal(true);
        loading(false);
      },
      (erro) => {
        loading(false);
      }
    );
  };

  const handleBackButton = () => {
    navigate('/home');
  };

  const handleChange = (name, value) => {
    setValues((prevValues) => {
      const newValues = cloneDeep(prevValues);
      set(newValues, name, value);
      return newValues;
    });
    fetchData(activeMonth, value);
  };

  const onCloseModal = () => {
    setShowModal(!showModal);
    setNumeroRepasse(null);
    setContentModalDetails(null);
  };

  useEffect(() => {
    const anoAtual = new Date().getFullYear();
    const anos = Array.from({ length: 6 }, (v, i) => ({ ano: anoAtual - i }));
    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    const currentMonthIndex = new Date().getMonth();
    const currentMonth = currentMonthIndex + 1;
    const meses = [];

    meses.push({
      id: currentMonth,
      name: monthNames[currentMonthIndex],
      mes: currentMonth,
    });

    monthNames.forEach((name, index) => {
      if (index !== currentMonthIndex) {
        meses.push({ id: index + 1, name, mes: index + 1 });
      }
    });
    setAnos(anos);
    setMeses(meses);
    fetchData(activeMonth, moment().year());

    setActiveMonth(currentMonth);
    setSelectedMonth(currentMonth);
    setSelectedYear({ ano: anoAtual });
  }, []);

  return (
    <div className="container-fluid">
      <Header handleBackButton={handleBackButton} />
      <div className="container my-5">
        <h1 className="defaultStyles-titlePageCenter"> Glosados</h1>

        {contentModalDetails && (
          <CustomModalDetails
            ref={refModal}
            visible={showModal}
            onClose={onCloseModal}
            content={contentModalDetails}
            numeroRepasse={numeroRepasse}
          />
        )}
        <Formik
          validationSchema={{}}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
        >
          {({ values, errors, handleChange, setFieldValue, index }) => (
            <Form autoComplete="off" className="row">
              <div className="row section-form">
                <FormGroup className="col-6 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    options={meses}
                    label="Mês"
                    valueKey="mes"
                    labelKey="name"
                    // placeholder="Selecione um mês"
                    placeholder={selectedMonth ? undefined : 'Selecione um mês'}
                    onChange={handleSelectMesChange}
                    value={selectedMonth}
                  ></InputViewEdit>
                </FormGroup>

                <FormGroup className="col-6 col-sm-4">
                  <InputViewEdit
                    component={SelectInput}
                    label="Ano"
                    name="ano"
                    id={'ano'}
                    // placeholder="Selecione um ano"
                    placeholder={selectedYear ? undefined : 'Selecione um ano'}
                    valueKey="ano"
                    labelKey="ano"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={false}
                    searchable={false}
                    options={anos}
                    onChange={handleSelectAnoChange}
                    value={selectedYear}
                  />
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>

        <div className="row">
          <div className="mt-3 mt-sm-0">
            {payments &&
              payments.repasses &&
              payments.repasses.map((payment, index) => (
                <div key={index + '_' + payment.numero}>
                  <div className="d-flex justify-content-center">
                    <span
                      style={{
                        textAlign: 'center',
                        marginVertical: 10,
                        fontWeight: 'bold',
                      }}
                    >
                      {formatDateToString(payment.dataCredito)}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span className="headerText_Paymentsmade">
                      {`Repasse nº: ${payment.numero}`}
                    </span>
                    <div className="detailsButton_Paymentsmade">
                      <button
                        className="btn btn-secondary"
                        onClick={() => verDetalhesRepasse(payment.numero)}
                      >
                        + Detalhes
                      </button>
                    </div>
                  </div>

                  <div>
                    {formataListaRepasse(payment.pagamentos).lancamentos.map(
                      (item, i) => getItemBox(item, i)
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div className="boxLinha_Paymentsmade">
                      <span className="textTit_Paymentsmade">Débito: </span>
                      <span className="textDesc_Paymentsmade">
                        {formataListaRepasse(payment.pagamentos).totalDespesas}
                      </span>
                    </div>
                    <div className="boxLinha_Paymentsmade">
                      <span className="textTit_Paymentsmade">Crédito: </span>
                      <span className="textDesc_Paymentsmade">
                        {formataListaRepasse(payment.pagamentos).totalCredito}
                      </span>
                    </div>
                    <div className="boxLinha_Paymentsmade">
                      <span className="textTit_Paymentsmade">Líquido: </span>
                      <span className="textDesc_Paymentsmade">
                        {formataListaRepasse(payment.pagamentos).totalLiquido}
                      </span>
                    </div>
                  </div>
                  <hr></hr>
                </div>
              ))}
            {isEmpty(payments.repasses) && atualizou && (
              <p className="text-center m-5 p-5 fw-bold">
                Não existem registros para serem exibidos.
              </p>
            )}

            {!isEmpty(payments) && (
              <>
                <div className="tit-payment">TOTAL MENSAL</div>
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <span className="lbl_Paymentsmade">Base do IRPF: </span>
                    <span className="normal_Paymentsmade">
                      {formatPrice(payments.baseBrutaIR)}
                    </span>
                  </div>

                  <div className="col-12 col-sm-4">
                    <span className="lbl_Paymentsmade">
                      Base líquida do IRPF:{' '}
                    </span>
                    <span className="normal_Paymentsmade">
                      {formatPrice(payments.baseLiquidaIR)}
                    </span>
                  </div>

                  <div className="col-12 col-sm-4">
                    <span className="lbl_Paymentsmade">Valor do IRPF: </span>
                    <span className="normal_Paymentsmade">
                      {formatPrice(payments.valorIR)}
                    </span>
                  </div>

                  <div className="col-12 col-sm-4">
                    <span className="lbl_Paymentsmade">
                      Total de créditos:{' '}
                    </span>
                    <span className="normal_Paymentsmade">
                      {formatPrice(payments.totalCreditos)}
                    </span>
                  </div>

                  <div className="col-12 col-sm-4">
                    <span className="lbl_Paymentsmade">
                      Total de descontos:{' '}
                    </span>
                    <span className="normal_Paymentsmade">
                      {formatPrice(payments?.totalDescontos)}
                    </span>
                  </div>

                  <div className="col-12 col-sm-4">
                    <span className="lbl_Paymentsmade">Total recebido: </span>
                    <span className="normal_Paymentsmade">
                      {' '}
                      {formatPrice(payments.totalRecebido)}
                    </span>
                  </div>

                  <div className="col-12">
                    <span className="lbl_Paymentsmade">
                      Total do demonstrativo:
                    </span>
                    <span className="normal_Paymentsmade">
                      {' '}
                      {formatPrice(payments.totalDemonstrativo)}
                    </span>
                  </div>
                </div>
                <hr></hr>
              </>
            )}

            {payments && payments.lancamentosTotalMensal && (
              <>
                <div className="tit-payment">LANÇAMENTOS </div>

                <div style={{ marginTop: 5, marginBottom: 80 }}>
                  {payments &&
                    payments.lancamentosTotalMensal &&
                    formataListaRepasse(
                      payments.lancamentosTotalMensal
                    ).lancamentos.map((item, i) => getItemBox(item, i))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(PaymentsMade);
