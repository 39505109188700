//@flow
import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Toast from "./components/Toasts/Toast";
import Loading from "./components/loading/Loading";
import PrivateRoute from "./components/routes/PrivateRoute";
import AuthenticationFactorsPage from './pages/Login/AuthenticationFactorsPage';
import CodeLoginConfirmPage from './pages/Login/CodeLoginConfirmPage';
import LoginDoctor from './pages/Login/LoginDoctor';
import RecoverPassword from './pages/Login/RecoverPassword';
import RegisterCodeAccessEmailPage from './pages/Login/RegisterCodeAccessEmailPage';
import RegisterCodeAccessQrCodePage from './pages/Login/RegisterCodeAccessQrCodePage';
import RegisterCodeAccessSmsPage from './pages/Login/RegisterCodeAccessSmsPage';
import SelectDefautSystemPage from './pages/Login/SelectDefautSystemPage';
import SelectRoleLoginPage from './pages/Login/SelectRoleLoginPage';
import PrivateScreensNavigator from "./pages/Navigation/PrivateScreens";
import './App.css';
import './themes/defaultStyles.css';
import './themes/stylesDetail.css';
import './themes/theme.css';

type Props = {
  loading: number
};

class App extends React.Component<Props> {

  render() {
    const { loggedIn, twoFactorAuthenticate } = this.props;
    const loading = this.props.loading > 0;
    console.log('loggedIn: ' + loggedIn);
    console.log('twoFactorAuthenticate: ' + twoFactorAuthenticate);
    return (
      <div className="notranslate">
        <Loading loading={loading}></Loading>
        <Toast></Toast>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<PrivateRoute component={PrivateScreensNavigator} loggedIn={loggedIn && twoFactorAuthenticate} />} />
            <Route path="/select-role-login" element={<SelectRoleLoginPage />} />
            <Route path="/login-doctor/:type?" element={<LoginDoctor />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route
              path="/authentication-factors"
              element={<AuthenticationFactorsPage />}
            />
            <Route
              path="/register-code-access-email"
              element={<RegisterCodeAccessEmailPage />}
            />
            <Route
              path="/register-code-access-sms"
              element={<RegisterCodeAccessSmsPage />}
            />
            <Route
              path="/register-code-access-qrcode"
              element={<RegisterCodeAccessQrCodePage />}
            />
            <Route
              path="/select-default-system"
              element={<SelectDefautSystemPage />}
            />
            <Route
              path="/code-login-confirm/:mfaToken/:authenticatorType/:email?"
              element={<CodeLoginConfirmPage />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { load } = state.load;
  const { loggedIn, twoFactorAuthenticate } = state.authentication;
  return {
    loading: load,
    loggedIn,
    twoFactorAuthenticate
  };
}

const mapDispatch = ({
  alert: { error, clear, info, warning }
}) => ({
  error: message => error(message),
  warning: message => warning(message),
  info: message => info({ message }),
  clear: clear()
});

export default connect(mapStateToProps, mapDispatch)(App); 