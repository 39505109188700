
import React, { Component } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Carousel from '../../components/carousel/Carousel';
import './styles/sumaryMenu.css';

class SummaryMenu extends Component {
  state = {
    items: [
      { title: 'Janeiro', month: 1 },
      { title: 'Fevereiro', month: 2 },
      { title: 'Março', month: 3 },
      { title: 'Abril', month: 4 },
      { title: 'Maio', month: 5 },
      { title: 'Junho', month: 6 },
      { title: 'Julho', month: 7 },
      { title: 'Agosto', month: 8 },
      { title: 'Setembro', month: 9 },
      { title: 'Outubro', month: 10 },
      { title: 'Novembro', month: 11 },
      { title: 'Dezembro', month: 12 },
    ],
  };

  componentDidMount() {
    const { order } = this.props;
    if (order && order.length === this.state.items.length) {
      const orderedItems = order.map((i) => this.state.items[i]);
      this.setState({ items: orderedItems });
    }
    // if (this.carousel) {
    //   this.carousel.snapToItem(this.props.activeMenu, true, false);
    // }
  }

  onClickMonth = (month) => {
    this.props.fetchData(month, this.props.year);
  };

  navigateLeft = () => {
    const newIndex = this.props.activeMenu - 1;
    if (newIndex >= 0) {
      this.props.fetchData(this.state.items[newIndex].month, this.props.year);
    }
  };

  navigateRight = () => {
    const newIndex = this.props.activeMenu + 1;
    if (newIndex < this.state.items.length) {
      this.props.fetchData(this.state.items[newIndex].month, this.props.year);
    }
  };

  _renderItem = ({ item, index }) => {
    return (
      <button
        className="carousel-item-button"
        onClick={() => {
          this.onClickMonth(item.month);
        }}
      >
        <div
          className={[
            'card',
            this.props.activeMenu === index ? 'activeSlide' : '',
          ].join(' ')}
        >
          <div
            className={[
              'cardTitle',
              this.props.activeMenu === index ? 'cardTitleActive' : '',
            ].join(' ')}
          >
            {item.title}
          </div>
          {this.props.activeMenu === index && <div className="cardTriangle" />}
        </div>
      </button>
    );
  };

  render() {
    const { activeMenu } = this.props;
    const canNavigateLeft = activeMenu > 0;
    const canNavigateRight = activeMenu < this.state.items.length - 1;

    return (
      <div className="containerSummaryMenu">
        {canNavigateLeft && (
          <button onClick={this.navigateLeft} className="icon-button">
            <IoIosArrowBack size={30} color="#097A5E" />
          </button>
        )}
        {/* <Carousel
          ref={(c) => {
            this.carousel = c;
          }}
          data={this.state.items}
          renderItem={this._renderItem}
          firstItem={this.props.activeMenu}
          sliderWidth={375}
          itemWidth={120}
          loop={false}
          autoplay={false}
          autoplayInterval={3000}
          onSnapToItem={() => {}}
        /> */}
        {canNavigateRight && (
          <button onClick={this.navigateRight} className="icon-button">
            <IoIosArrowForward size={30} color="#097A5E" />
          </button>
        )}
      </div>
    );
  }
}

export default SummaryMenu;
