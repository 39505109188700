//@flow
import React, { PureComponent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { InputType, Label } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { ToolTipHelp } from '../Utils/ToolTipHelp';

//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string
};


export default class FormTextArea extends PureComponent<Props> {
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false

  };

  getInput = () => {
    return this._input;
  };

  render() {
    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    const value = this.props.value;

    return (
      <div className={`${this.props.className && this.props.className} ${this.props.required ? "input-required" : "no-required"}`}>

        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={'help_' + this.props.id} />
                <ToolTipHelp
                  target={'help_' + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <TextareaAutosize
          id={this.props.id}
          name={this.props.name}
          value={value}
          onBlur={this.props.onBlur}
          onChange={this.props.onChange}
          required={this.props.required}
          minRows={this.props.minRows}
          placeholder={this.props.placeholder === "" || this.props.placeholder === undefined ? this.props.label : this.props.placeholder}
          disabled={this.props.disabled ? this.props.disabled : false}
          maxLength={this.props.maxLength}
          innerRef={input => {
            this._input = input;
          }}
          rows={this.props.rows}
          className={`form-control ${this.props.className} ${messageValidate ? 'is-invalid' : ''
            }`}
        >
          {this.props.children}
        </TextareaAutosize>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

// function mapStateToProps(state) {
//   const { messages } = state.fieldMessage;
//   const { alert } = state;

//   return {
//     messages,
//     alert
//   };
// }

// export default connect(mapStateToProps)(FormTextArea);
