import React from 'react';
import PropTypes from 'prop-types';
import './styles/SascCardDashboard.css';

/**
 * ProgressBar component simulation for React JS using div elements
 */
const ProgressBar = ({ progress, height, backgroundColor, trackColor }) => {
  const progressBarStyle = {
    height: `${height}px`,
    backgroundColor: trackColor,
    width: '100%',
    borderRadius: '4px',
    overflow: 'hidden',
  };

  const progressStyle = {
    height: '100%',
    width: `${progress * 100}%`,
    backgroundColor,
    transition: 'width 0.3s ease-in-out',
  };

  return (
    <div style={progressBarStyle}>
      <div style={progressStyle} />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  trackColor: PropTypes.string,
};

ProgressBar.defaultProps = {
  height: 40,
  backgroundColor: '#000',
  trackColor: 'transparent',
};

/**
 * SascCardDashboard
 *
 * @param title
 * @param type
 * @param totalPercent
 */
export default function SascCardDashboard({ title, type, totalPercent }) {
  return (
    <div>
      <div className="title">{title}</div>
      <ProgressBar
        progress={totalPercent !== null ? totalPercent : 0}
        height={40}
        backgroundColor={type}
        trackColor="transparent"
      />
    </div>
  );
}

SascCardDashboard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  totalPercent: PropTypes.number,
};

SascCardDashboard.defaultProps = {
  totalPercent: 0,
};
