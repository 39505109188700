import React, { Component } from 'react';
import './styles/input.css'

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    const { name, value } = this.props;
    this.onChangeText(name, value);
  }

  onChangeText = (name, value) => {
    const { required } = this.props;
    if (required && !value) {
      this.setState({ error: 'Campo Obrigatório' }, () => {
        this.props.onChangeText(name, value, this.state.error);
      });
    } else {
      this.setState({ error: '' }, () => {
        this.props.onChangeText(name, value);
      });
    }
  };

  render() {
    const { value, placeholder, style, name, submit } = this.props;
    const { error } = this.state;
    const inputStyle = [
      "formInput",
      style,
      submit && error && "formInputError"
    ].filter(Boolean).join(' ');

    return (
      <input
        ref={this.inputRef}
        className={inputStyle}
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          this.onChangeText(name, event.target.value);
        }}
        type="email"
      />
    );
  }
}

// You could also move this to a separate CSS file
// const styles = `
//   .formInputError {
//     border-color: ${theme.colors.error};
//   }
//   .formInput {
//     height: 47px;
//     background-color: #fff;
//     border-radius: 8px;
//     border-width: 1px;
//     border-color: #adadad;
//     padding: 10px;
//     width: 100%;
//   }
// `;

// Usage of the styles in a CSS file or style tag in the HTML
// <style>{styles}</style>

export default Input;
