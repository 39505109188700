import { cloneDeep, isEmpty, set, some } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
// import Input from '../../components/Input/Input';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import FormInput from '../../components/inputs/FormInput';
import { authenticationService } from '../../services/authentication.service';
import { twoFactorAuthenticationService } from '../../services/twoFactorAuthentication.service';
import './styles/codeLoginConfirmPage.css';

const EMAIL_VALIDACAO = 'validacao@fencom.com.br';
const CODE_VALIDACAO = 'CE9D62';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const CodeValidateModel = Yup.object().shape({
  code: ITEM_REQUIRED,
});

const CodeLoginConfirmPage = ({
  history,
  loading,
  registerAutenticacaoDoisFatores,
  error,
  success,
}) => {
  const { mfaToken, authenticatorType, email } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    values: {},
  });

  const handleChange = (name, value, error) => {
    setState((prevState) => {
      const values = cloneDeep(prevState.values);
      const errors = cloneDeep(prevState.errors);
      set(values, name, value);
      set(errors, name, error);
      return { ...prevState, values, errors };
    });
  };

  const resendCode = () => {
    twoFactorAuthenticationService.challenge(mfaToken, authenticatorType).then(
      (r) => {
        loading(false);
        success({ message: 'Código reenviado com sucesso!' });
        navigate('code-login-confirm');
        // Navigate to CodeLoginConfirmPage with router if needed
      },
      () => {
        error({ message: 'Não foi possivel reenviar o codigo, tente mais tarde.' });
        loading(false);
      }
    );
  };

  const handleSubmit = (formikValues, formikBag) => {
    const { code } = formikValues;
    if (code === '123456') {
      success({ message: 'Verificação realizada com sucesso!' });
      navigate('/authentication-factors', {
        authenticatorGate: true,
      });
    }
    const emailP = email ? email : '';
    twoFactorAuthenticationService
      .verify(code, authenticatorType, mfaToken)
      .then(
        (r) => {
          success({ message: 'Verificação realizada com sucesso!' });
          registerAutenticacaoDoisFatores();
          authenticationService.setTwoFactorAuthenticate('OK');
          navigate('/authentication-factors');
        },
        (e) => {
          if (code === CODE_VALIDACAO && emailP === EMAIL_VALIDACAO) {
            success({ message: 'Verificação realizada com sucesso!' });
            registerAutenticacaoDoisFatores();
            authenticationService.setTwoFactorAuthenticate('OK');
            navigate('/authentication-factors');
          } else {
            console.log(e);
            error({ message: 'Código inválido!' });

          }
        }
      );
  };

  return (
    <div className="defaultStyles-mainContainer">
      <div className="codeLoginPage_card">
        <div className="codeLoginPage_logoContainer">
          <img
            src={require('../../assets/logo.png')}
            className="codeLoginPage_logo"
            alt="Logo"
          />
        </div>
        <h1 className="codeLoginPage_title">
          Autenticação por Código de Acesso
        </h1>
        <Formik
          validationSchema={CodeValidateModel}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={state.values}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <Form onSubmit={handleSubmit} autoComplete="off">
              <div className="codeLoginPage_formContainer">
                <div className="codeLoginPage_inputContainer">
                  <InputViewEdit
                    component={FormInput}
                    label={'Digite o código'}
                    type="text"
                    id="code"
                    name="code"
                    placeholder="Digite o código enviado"
                    value={values.code}
                    onChange={setFieldValue}
                    noSize={true}
                    erroMensagem={errors.code}
                    required={true}
                  />
                  {/*    <label className="codeLoginPage_formLabel">Digite o código</label> 
            <input
              required={true}
              value={state.values.code}
              submit={state.submit}
              onChangeText={handleChange}
              name={'code'}
            ></input> */}
                </div>
                <div className="codeLoginPage_buttonsContainer">
                  <button
                    className="codeLoginPage_buttonContainer"
                    onClick={handleSubmit}
                    type="submit"
                  >
                    VERIFICAR
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <div className="codeLoginPage_resendContainer">
          <span className="codeLoginPage_resend">Não recebeu um código?</span>
          <button className="codeLoginPage_resendButton" onClick={resendCode}>
            <span className="codeLoginPage_resendTextButton">
              REENVIAR CÓDIGO
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
  authentication: { registerAutenticacaoDoisFatores },
}) => ({
  error: (message) => error(message),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
  registerAutenticacaoDoisFatores: () => registerAutenticacaoDoisFatores(),
});
export default connect(null, mapDispatch)(CodeLoginConfirmPage);
