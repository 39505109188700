
export const theme = {
    colors: {
        primary: 'rgb(99,179,126)',
        secondary: 'rgb(9,122,94)',
        black: 'rgb(0,0,0)',
        error: '#ad0809',
        gray:'#707070'
    },
};
