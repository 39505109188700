import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import TabMenu from '../../components/menu/TabMenu';
import { apiSasc } from '../../services/api.service';
import AwaitingPayment from '../AwaitingPayment/AwaitingPayment';
import CooperativeAnalysis from '../CooperativeAnalysis/CooperativeAnalysis';
import Entidade from '../Entidade/Entidade';
import ExpectedPayments from '../ExpectedPayments/ExpectedPayments';
import GlossedGuides from '../GlossedGuides/GlossedGuides';
import Home from '../Home/Home';
import ItemDetailPage from '../ItemDetail/ItemDetailPage';
import MedicalCare from '../MedicalCare/MedicalCare';
import PaymentsMade from '../PaymentsMade/PaymentsMade';
import Perfil from '../Perfil/Perfil';
import PerfilPage from '../Perfil/PerfilPage';
import PrintGuides from '../PrintGuides/PrintGuides';
import Search from '../Search/Search';
import SearchResult from '../Search/SearchResult';
import TreatmentsView from '../Search/view/TreatmentsView';
import Tabelas from '../Tabelas/Tabelas';
import TablesListByTreatments from '../Tabelas/TablesListByTreatments';
import Usuario from '../Usuario/Usuario';
import UsuarioList from '../Usuario/UsuarioList';

const PrivateScreensNavigator = ({ navigation }) => {
  const [system, setSystem] = useState(null);

  useEffect(() => {
    console.log('PrivateScreen');
    getSystemDefault();
  }, []);

  const getSystemDefault = () => {
    apiSasc.get('/getUserSystem').then(({ data }) => {
      setSystem(data);
    });
  };

  return (
    <React.Fragment>
      <Routes>
        <Route exact path="*" element={<Home />} />

        <Route path="/cooperativeanalysis" element={<CooperativeAnalysis />} />
        <Route path="/expectedpayments" element={<ExpectedPayments />} />
        <Route path="/glossedguides" element={<GlossedGuides />} />
        <Route path="/paymentsmade" element={<PaymentsMade />} />
        <Route path="/awaitingpayment" element={<AwaitingPayment />} />
        <Route path="/medicalcare" element={<MedicalCare />} />
        <Route path="/tabelas" element={<Tabelas />} />
        <Route
          path="/tableslistbytreatments"
          element={<TablesListByTreatments />}
        />
        <Route path="/search" element={<Search />} />
        <Route path="/searchresult" element={<SearchResult />} />
        <Route path="/usuario" element={<Usuario />} />
        <Route path="/usuariolist" element={<UsuarioList />} />
        <Route path="/perfilpage" element={<PerfilPage />} />
        <Route path="/perfil/:id" element={<Perfil />} />
        <Route path="/itemdetailpage/:ehGlosa?" element={<ItemDetailPage />} />
        <Route path="/treatmentsview" element={<TreatmentsView />} />
        <Route path="/printguides" element={<PrintGuides />} />
        <Route path="/entidade" element={<Entidade />} />
      </Routes>

      {/* <TabMenu systemDefault={system} navigation={navigation} /> */}
    </React.Fragment>
  );
};

export default PrivateScreensNavigator;
