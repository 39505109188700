import React, { useEffect, useState } from 'react';
// import InputSelectRole from '../../components/Input/InputSelectRole';
import { set } from 'formik';
import { IoMdMenu } from 'react-icons/io';
import { connect } from 'react-redux';
import Avatar from '../../components/image/Avatar';
import CheeseburgerMenu from '../../components/menu/CheeseburgerMenu';
import Menu from '../../components/menu/Menu';
import MenuPerfil from '../../components/menu/MenuPerfil';
import { apiSasc } from '../../services/api.service';
import { authenticationService } from '../../services/authentication.service';
import { entitiesService } from '../../services/entities.service';
import './styles/headerNavigation.css';
import { cloneDeep, get } from 'lodash';
import { AiOutlineLeft } from 'react-icons/ai';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';

function Header({
  nav,
  logout,
  loading,
  menuAberto,
  fechaMenu,
  abreMenu,
  menuPerfilAberto,
  fechaMenuPerfil,
  abreMenuPerfil,
  user,
  handleBackButton,
  error,
}) {
  const [values, setValues] = useState({ coop: {}, system: '' });
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    getDados();
  }, []);

  const getDados = () => {
    loading(true);
    Promise.all([
      authenticationService.getEntidade(),
      entitiesService.findEntidadesCooperado(),
      apiSasc.get('/getUserSystem'),
    ]).then(
      (response) => {
        const coopSelect = JSON.parse(response[0]);

        let listaCoop = response[1].data.map((e) => {
          return e;
        });

        setEntities(listaCoop);
        setValues({
          coop: coopSelect,
          system: response[2].data,
        });
        loading(false);
      },
      (err) => {
        console.log(JSON.stringify(err));
        loading(false);
        error(
          'Não foi possível carregar algumas informações, tente novamente.'
        );
      }
    );
  };

  const saveEntity = async (entity) => {
    try {
      loading(true);
      const response = await entitiesService.saveEntidadeCooperado(entity);
      const data = response.data;
      loading(false);
      if (data) {
        authenticationService.setEntidade(data.portalEntidadeCooperado);
      }
    } catch (err) {
      error('Ocorreu um erro ao obter os dados! Tente mais tarde.');
      setValues((prevState) => ({ ...prevState, loading: false }));
    }
  };

  return (
    <>
      <div className="header-top">
        <div className="d-flex justify-content-start ico-menu">
          {!handleBackButton ? (
            <button
              onClick={() => {
                abreMenu();
              }}
              className="button_menu"
            >
              <IoMdMenu size={25} color="black" />
            </button>
          ) : (
            <button
              className="defaultStyles-NewBackButton"
              onClick={handleBackButton}
            >
              <span className="defaultStyles-newBackButtonText">
                <AiOutlineLeft name={'left'} />
              </span>
            </button>
          )}
          <div className="col-8" style={{ marginLeft: 20 }}>
            <InputViewEdit
              component={SelectInput}
              options={entities}
              valueKey="idEntidade"
              labelKey="siglaEntidade"
              id="coop"
              name="coop"
              placeholder="Cooperativa"
              returnFullObject={true}
              value={values.coop}
              initialValue={values.coop}
              onChange={(name, value) => {
                setValues((prevValues) => ({
                  ...prevValues,
                  [name]: value,
                }));
                saveEntity(value);
              }}
            />
          </div>
        </div>
        <div className="logoContainer_Header">
          <img
            src={require('../../assets/logo.png')}
            alt="Logo"
            className="defaultStyles-logo d-none d-sm-block"
          />
          <img
            src={require('../../assets/logo.png')}
            alt="Logo"
            className="defaultStyles-logo d-block d-sm-none"
            style={{ maxWidth: '150px' }}
          />
        </div>
        <div className="container_menuPerfil">
          <div className="boas-vindas">
            Olá <b>{user.name}</b>, bem vindo ao <b>App Fencom</b> Sua
            ferramenta de gestão financeira!
          </div>
          <button onClick={abreMenuPerfil} className="buttonMenuPerfil">
            <Avatar />
          </button>
        </div>
      </div>
      <CheeseburgerMenu
        isOpen={menuAberto}
        closeCallback={fechaMenu}
        left={true}
        width={200}
      >
        <Menu />
      </CheeseburgerMenu>
      <CheeseburgerMenu
        isOpen={menuPerfilAberto}
        closeCallback={fechaMenuPerfil}
        right={true}
        width={200}
      >
        <MenuPerfil />
      </CheeseburgerMenu>
    </>
  );
}

const mapDispatch = ({
  load: { loading },
  menu: { toggleMenu, abreMenu, fechaMenu },
  menuPerfil: { toggleMenuPerfil, abreMenuPerfil, fechaMenuPerfil },
  authentication: { logout },
  alert: { error, clear, success, warning },
}) => ({
  error: (message, code) => error({ message, code }),
  loading: (load) => loading({ load }),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu({}),
  fechaMenu: () => fechaMenu(),
  toggleMenuPerfil: () => toggleMenuPerfil(),
  abreMenuPerfil: () => abreMenuPerfil({}),
  fechaMenuPerfil: () => fechaMenuPerfil(),
  logout: () => logout(),
});
function mapStateToProps(state) {
  const { menuAberto } = state.menu;
  const { menuPerfilAberto } = state.menuPerfil;
  const { user } = state.authentication;

  return {
    menuAberto,
    menuPerfilAberto,
    user,
  };
}

export default connect(mapStateToProps, mapDispatch)(Header);
