
import React from 'react';

const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;

const defaultStyles = {
  container: {
    height: screenHeight * 0.75,
    padding: '20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  containernoPadding: {
    height: screenHeight * 0.75,
    padding: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  containerScroll: {
    padding: '3px 0',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  textCenterBold: { marginTop: '10px', fontWeight: 'bold', textAlign: 'center' },
  zIndex: {
    zIndex: 2,
  },
  titlePage: {
    textTransform: 'uppercase',
    fontSize: '18px',
    color: '#097A5E',
  },
  titlePageCenter: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: '18px',
    color: '#097A5E',
    marginBottom: '5px',
  },
  formLabel: {
    margin: '0',
    fontWeight: 600,
    fontSize: '11px',
    color: '#5d5d5d',
  },
  // card listagem
  rowCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  itemCard: {
    flexShrink: 1,
  },
  tableCard: {
    borderColor: '#EDEDED',
    margin: '10px 0',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  filterContainerCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 20px',
  },
  inputSearchCard: {
    flex: 3,
    maxHeight: '40px',
  },
  btnExcelCard: {
    width: '25px',
    height: '25px',
    marginLeft: '5px',
  },
  headerInput: {
    paddingBottom: 0,
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  halfBack: { width: '50%' },

  inputContainerYear: {
    flex: 1,
    width: '50%',
    marginTop: '10px',
  },

  tableRowCard: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: '7.5px 0',
    borderColor: '#d2d2d2',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  tableCellCard: {
    flex: 1,
    padding: '10px',
    alignItems: 'center',
    textAlign: 'left',
    paddingVertical: '5px',
    width: '100%',
  },
  fullWidth: {
    width: '92%',
  },
  iconsCard: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  formHelperText: {
    margin: '0',
    fontWeight: 800,
    fontSize: '11px',
  },

  iconCard: {
    alignItems: 'stretch',
    padding: '10px 0',
  },
  tableColumnCard: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerCellCard: {
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  evenRowCard: {
    backgroundColor: '#EDEDED',
  },
  evenRowCardGreen: {
    backgroundColor: '#dee6e3',
  },
  oddRowCard: {
    backgroundColor: '#FFFFFF',
  },
  ph10: {
    padding: '5px 10px',
  },
  iconImgCard: {
    width: '35px',
    height: '35px',
    margin: '10px 0',
  },
  columnLayoutCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '10px',
  },
  rowLayoutCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    width: '100%',
  },

  inputContainer: {
    margin: '5px 0',
    width: '100%',
  },

  backButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  backButtonIcon: {
    marginTop: '20px',
  },

  header: {
    padding: '15px 15px 5px 15px',
  },

  backButtonText: {
    fontSize: '18px',
    color: '#0D4E33',
  },
};

/**
 * Exports
 *
 */
export default defaultStyles;
