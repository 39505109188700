import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import './styles/inputCpf.css'
// import _ from 'lodash';
// import themes from '../../theme';


export default class InputCPF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidMount() {
    const { name, value } = this.props;
    if (value) {
      this.onChangeText(name, value);
    }
  }  

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { value, name } = this.props;
    if (value !== prevProps.value) {
      this.onChangeText(name, value);
    }
  }

  onChangeText = (name, value) => {
    const { required } = this.props;
    if (required && !value) {
      this.setState({ error: 'Campo Obrigatório' }, () => {
        this.props.onChangeText(name, value, this.state.error);
      });
    } else {
      const cpfWithoutMask = value.replace(/[^\d]+/g, '');
      if (!value || cpfWithoutMask.length !== 11) {
        this.setState({ error: 'CPF inválido' }, () => {
          this.props.onChangeText(name, cpfWithoutMask, this.state.error);
        });
      } else {
        let sum = 0;
        let remainder;
        const cpfArray = [...cpfWithoutMask];
        if (cpfArray[0].repeat(11) === cpfWithoutMask) {
          this.setState({ error: 'CPF inválido' }, () => {
            this.props.onChangeText(name, cpfWithoutMask, this.state.error);
          });
          return;
        }
        for (let i = 1; i <= 9; i++) {
          sum += parseInt(cpfArray[i - 1], 10) * (11 - i);
        }
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpfArray[9], 10)) {
          this.setState({ error: 'CPF inválido' }, () => {
            this.props.onChangeText(name, cpfWithoutMask, this.state.error);
          });
          return;
        }
        sum = 0;
        for (let i = 1; i <= 10; i++) {
          sum += parseInt(cpfArray[i - 1], 10) * (12 - i);
        }
        remainder = (sum * 10) % 11;
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpfArray[10], 10)) {
          this.setState({ error: 'CPF inválido' }, () => {
            this.props.onChangeText(name, cpfWithoutMask, this.state.error);
          });
          return;
        }
        this.setState({ error: '' }, () => {
          this.props.onChangeText(name, cpfWithoutMask);
        });
      }
    }
  };

  render() {
    const { value, placeholder, name, submit } = this.props;
    const { error } = this.state;
    return (
      <InputMask
        className={`formInputCpf ${submit && error ? 'formInputErrorCpf' : ''}`}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          this.onChangeText(name, e.target.value);
        }}
        mask="999.999.999-99"
        maskChar=" "
        alwaysShowMask={false}
      />
    );
  }
}
