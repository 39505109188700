import { cloneDeep, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { IoMdMenu } from 'react-icons/io';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { apiSasc } from '../../services/api.service';
import './styles/menu.css';

const MENU_ITEMS = [
  {
    text: 'Início',
    icon: require('../../assets/home.png'),
    page: '/',
  },
  {
    text: 'Imprimir Guia',
    page: '/printguides',
    icon: require('../../assets/icon-imprimir.png'),
  },
  {
    text: 'Operador',
    page: '/usuariolist',
    icon: require('../../assets/icon-add-user.png'),
  },
  {
    text: 'Tabelas',
    icon: require('../../assets/tab-tabelas.png'),
    page: '/Tabelas',
  },
  {
    text: 'Novo Atendimento',
    icon: require('../../assets/add.png'),
    page: '/MedicalCare',
  },
  {
    text: 'Pesquisar',
    icon: require('../../assets/search.png'),
    page: '/Search',
  },
];

const Menu = ({ user, menuAberto, toggleMenu, logout, fechaMenu }) => {
  const navigate = useNavigate();

  let filteredMenuItems;

  const goTo = (url) => {
    toggleMenu();
    if (url) {
      navigate(url);
    }
  };

  const renderOption = (item, index) => (
    <div key={index} className="menuItem" onClick={() => goTo(item.page)}>
      <span className="menuItemText">{item.text}</span>
    </div>
  );

  let operador = cloneDeep(MENU_ITEMS).slice(0, 2);
  operador.push(MENU_ITEMS[3]);

  const storage = JSON.parse(localStorage.getItem('defaultSystem'));

  if (storage && storage.sascConnect === false) {
    filteredMenuItems = MENU_ITEMS.filter((item) => item.text !== 'Tabelas');
  } else {
    filteredMenuItems = MENU_ITEMS;
  }

  return (
    <div className="menu">
      <div className="d-flex flex-row header-menu">
        <div className="close-menu">
          <button
            className="btn-close"
            type="button"
            onClick={fechaMenu}
          ></button>
        </div>
        <div className="flex-fill d-flex align-items-center justify-content-center titulo">
          MENU
        </div>
      </div>
      <div>
        {get(user, 'perfilEnum') === 'OPERADOR'
          ? operador.map(renderOption)
          : filteredMenuItems.map(renderOption)}
      </div>
    </div>
  );
};

const mapDispatch = ({
  load: { loading },
  menu: { toggleMenu, abreMenu, fechaMenu },
  authentication: { logout },
}) => ({
  loading: (load) => loading({ load }),
  toggleMenu: () => toggleMenu(),
  abreMenu: () => abreMenu({}),
  fechaMenu: () => fechaMenu(),
  logout: () => logout(),
});
function mapStateToProps(state) {
  const { menuAberto } = state.menu;
  const { user } = state.authentication;

  return {
    menuAberto,
    user,
  };
}

export default connect(mapStateToProps, mapDispatch)(Menu);
