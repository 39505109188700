export const objectConstants = {
  AUTHENTICATOR_TYPE_EMAIL_COFFEE_BEAN: 'email_otp',
  AUTHENTICATOR_TYPE_SMS_COFFEE_BEAN: 'sms_otp',
  TIPO_LOGIN_MEDICO: 'medico',
  TIPO_LOGIN_OPERADOR: 'operador',
  TELEFONES: [
    { value: 'CELULAR', name: 'Celular' },
    { value: 'CELULAR_WHATSAPP', name: 'Whatsapp' },
    { value: 'COMERCIAL', name: 'Comercial' },
    { value: 'RESIDENCIAL', name: 'Residêncial' },
  ],
  SIM_NAO: [
    { label: 'Não', value: false },
    { label: 'Sim', value: true },
  ],
};
