import { Form, Formik } from 'formik';
import { has, isNil } from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import FormInput from '../../components/inputs/FormInput.js';
import ControlledTooltip from '../../components/Tooltip/ControlledTooltip.jsx';
import SelectInput from '../../components/inputs/SelectInput.js';
import { apiConnect, apiDynamic } from '../../services/api.service';
import { setValue } from '../../utils/DropDownSetValueUtils.tsx';
import './style/medicalCare.css';
import { usersService } from '../../services/users.service.jsx';

const ITEM_REQUIRED = Yup.string().required('Obrigatório');
const medicalCareSecondStepValidate = Yup.object().shape({
  medicalInsurance: ITEM_REQUIRED,
  cardNumber: ITEM_REQUIRED,
});

const MedicalCareSecondStep = forwardRef(
  (
    {
      values,
      system,
      errors,
      handleChange,
      loading,
      error,
      converterSascTreatmentsCreateSaveToContext,
      handleSubmit,
    },
    ref
  ) => {
    const [conveniosList, setConveniosList] = useState([]);
    useState(null);

    useImperativeHandle(ref, () => ({
      validateStep: validateStep,
    }));

    const validateStep = () => {
      console.log('valida 2');
      let isValid = true;
      const FIELD_REQUIRED = 'Campo obrigatório';
      const CARD_NUMBER_WRONG = 'Número de carteirinha inválido';
      if (!values.medicalInsurance) {
        handleChange(
          'medicalInsurance',
          values.medicalInsurance,
          FIELD_REQUIRED
        );
        isValid = false;
      }
      if (!values.cardNumber) {
        handleChange('cardNumber', values.cardNumber, FIELD_REQUIRED);
        isValid = false;
      } else {
        let valido = true;
        const medicalInsurance = values.medicalInsurance;
        if (
          medicalInsurance &&
          (medicalInsurance.quantMaxDigitosCarteirinha <
            values.cardNumber.length ||
            medicalInsurance.quantMinDigitosCarteirinha >
              values.cardNumber.length)
        ) {
          valido = false;
        }
        if (!valido) {
          handleChange('cardNumber', values.cardNumber, CARD_NUMBER_WRONG);
          isValid = false;
        }
      }

      if (!values.patient) {
        handleChange('patient', values.patient, FIELD_REQUIRED);
        isValid = false;
      }
      return isValid;
    };

    const findCPacienteByCarteirinha = async (carteirinha) => {
      console.log(carteirinha);
      loading(true);
      try {
        const response = await apiConnect.get(
          `/beneficiario/find/carteirinha/${carteirinha}`
        );
        if (!isNil(response.data)) {
          handleChange(
            'patient',
            {
              title: response.data.nomeBeneficiario,
              id: JSON.stringify(response.data),
              numeroCarteira: response.data.numeroCarteira,
            },
            null
          );
        } else {
        }
        loading(false);
      } catch (error) {
        console.error(error);
        loading(false);
      }
    };

    const findPaciente = async (name) => {
      handleChange(
        'patient',
        {
          nomeBeneficiario: name,
          numeroCarteira: values.cardNumber,
        },
        null
      );
      try {
        const r = await apiDynamic.get(
          `/beneficiario/find/similarNameOrCpfOrCarteirinha`,
          {
            params: { nameOrCpf: name },
          }
        );
        const data = r.data.map((e) => {
          return {
            title: e.nomeBeneficiario,
            id: JSON.stringify(e),
            numeroCarteira: e.numeroCarteira,
          };
        });

        return data;
      } catch (error) {
        console.error('error find paciente');
        console.error(JSON.stringify(error));
      }
    };

    const findConvenios = async () => {
      const { healthInstitute, guideType } = values;
      loading(true);
      try {
        const r = await apiDynamic.get(
          `/convenio/findByEstabelecimento/${
            healthInstitute && healthInstitute.id ? healthInstitute.id : 0
          }/${guideType}`
        );
        setConveniosList(
          r.data.map((e) => {
            const name = e.nome ? e.nome : e.sigla;
            return {
              nome: name,
              label: e.nome ? e.nome : e.sigla,
              name: e.nome,
              sigla: e.sigla,
              cnpj: e.cnpj,
              value: e.id,
              id: e.id,
            };
          })
        );
        loading(false);
      } catch (error) {
        console.error(error);
        loading(false);
      }
    };

    useEffect(() => {
      findConvenios();
    }, []);

    return (
      <Formik
        validationSchema={medicalCareSecondStepValidate}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form autoComplete="off" className="row">
            <FormGroup className="col-12 col-sm-6">
              <InputViewEdit
                component={SelectInput}
                options={conveniosList}
                label="Convênio"
                placeholder="Digite o convênio"
                valueKey="value"
                labelKey="nome"
                returnFullObject={true}
                id={'medicalInsurance'}
                name={'medicalInsurance'}
                value={values.medicalInsurance}
                onChange={(name, value) => {
                  handleChange(name, value, null);
                }}
                erroMensagem={errors.medicalInsurance}
              />
            </FormGroup>
            <FormGroup className="col-12 col-sm-6">
              <InputViewEdit
                component={FormInput}
                label={'Número da carteirinha'}
                placeholder="Digite o número da carteirinha"
                value={values.cardNumber}
                id={'cardNumber'}
                name={'cardNumber'}
                onChange={(name, value) => {
                  handleChange(name, value, null);
                }}
                required={true}
                erroMensagem={errors && errors.cardNumber}
                onBlur={(e) => findCPacienteByCarteirinha(e.target.value)}
                key={`cardNumber-${errors.cardNumber}`}
              />
              {/* <ControlledTooltip
                width={300}
                helpText={
                  ' Caso o número da carteirinha esteja inválido, provavelmente o numero de caracteres foi digitado incorretamente em caso de  dúvidas informe-se com sua cooperativa.'
                }
              >
                <IoInformationCircleOutline size={24} color="black" />
              </ControlledTooltip> */}
            </FormGroup>

            <FormGroup className="col-12 col-sm-6">
              <InputViewEdit
                component={SelectInput}
                valueKey="id"
                labelKey="title"
                placeholder="Nome ou CPF do paciente"
                label={'Paciente'}
                id="patient"
                name="patient"
                value={values.patient}
                onChange={(name, value) => {
                  console.log(value);
                  handleChange(name, value, null);
                  handleChange('cardNumber', value.numeroCarteira, null);
                }}
                noSize={true}
                returnFullObject={true}
                multi={false}
                onFetchData={findPaciente}
                sortKey={'title'}
                erroMensagem={errors && errors.patient}
              />
            </FormGroup>

            {/* <ControlledTooltip
              width={300}
              helpText={
                'O médico deve preencher com um Nome ou CPF válido, caso o Nome ou CPF não esteja em nossa base de dados o nome deverá ser preenchido para ser cadastrado na nossa base.'
              }
            >
              <IoInformationCircleOutline size={24} color="black" />
            </ControlledTooltip>*/}
          </Form>
        )}
      </Formik>
    );
  }
);

export default MedicalCareSecondStep;
