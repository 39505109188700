import React, { useState, useEffect } from 'react';
// import Select from '../Input/InputSelect';
import Avatar from '../image/Avatar';
import { isNull, concat } from 'lodash';
import { getToken } from '../../services/api.service';
import './styles/menuPerfil.css';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const MENU_PERFIL = [
  {
    text: 'Perfil',
    page: '/perfilpage',
    icon: require('../../assets/icon-add-user.png'),
  },
];

const MENU_CHANGE_SYSTEM = [
  {
    text: 'Trocar de sistema',
    page: '/select-default-system',
    icon: require('../../assets/icon-change.png'),
  },
];
const MENU_EXIT = [
  {
    text: 'Sair',
    page: 'logout',
    icon: require('../../assets/iconsair.png'),
  },
];

function MenuPerfil({ nav, logout, fechaMenuPerfil, toggleMenuPerfil }) {
  const [connect, setConnect] = useState(false);
  const navigate = useNavigate();

  const goTo = (url) => {
    toggleMenuPerfil();
    if (url) {
      url === 'logout' ? logout() : navigate(url);
    }
  };

  const menuMount = async () => {
    let connectStatus = !isNull(await getToken());
    setConnect(connectStatus);
  };

  useEffect(() => {
    menuMount();
  }, []);

  const renderOption = (item, index) => {
    return (
      <div key={index} className="menuItem" onClick={() => goTo(item.page)}>
        <span className="menuItemText"> {item.text}</span>
      </div>
    );
  };

  return (
    <div className="menu">
      <div className="d-flex flex-row header-menu">
        <div className="close-menu">
          <button
            className="btn-close"
            type="button"
            onClick={fechaMenuPerfil}
          ></button>
        </div>
        <div className="flex-fill d-flex align-items-center justify-content-center titulo">
          MENU
        </div>
      </div>
      <div>
        {connect
          ? concat(MENU_PERFIL, MENU_CHANGE_SYSTEM, MENU_EXIT).map(
              (item, index) => renderOption(item, index)
            )
          : concat(MENU_PERFIL, MENU_EXIT).map((item, index) =>
              renderOption(item, index)
            )}
      </div>
    </div>
  );
}

const mapDispatch = ({
  load: { loading },
  menuPerfil: { toggleMenuPerfil, abreMenuPerfil, fechaMenuPerfil },
  authentication: { logout },
}) => ({
  loading: (load) => loading({ load }),
  toggleMenuPerfil: () => toggleMenuPerfil(),
  abreMenuPerfil: () => abreMenuPerfil({}),
  fechaMenuPerfil: () => fechaMenuPerfil(),
  logout: () => logout(),
});
function mapStateToProps(state) {
  const { menuPerfilAberto } = state.menuPerfil;
  const { user } = state.authentication;

  return {
    menuPerfilAberto,
    user,
  };
}

export default connect(mapStateToProps, mapDispatch)(MenuPerfil);
