
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { filter } from 'lodash';
import Select from '../Input/InputSelect'; // This assumed to be a React JS component
import TabMenuItem from './TabMenuItem'; // This assumed to be a React JS component
import { apiSasc } from '../../services/api.service'; // Keep the same service as it can be used in React JS
import './styles/tabMenu.css';

// Import Ionicons and CSS Image assumed to be properly handled in React JS environment.
// Please ensure the appropriate package or reference is included in your project.

const MENU_ITEMS = [
  { text: 'INÍCIO', icon: require('../../assets/home.png'), page: '/' },
  {
    text: 'TABELAS',
    icon: require('../../assets/tab-tabelas.png'),
    page: 'Tabelas',
  },
  {
    text: 'NOVO ATENDIMENTO',
    icon: require('../../assets/add.png'),
    page: 'MedicalCare',
  },
  {
    text: 'COOPERATIVA',
    icon: require('../../assets/entidade.png'),
    page: 'Entidade',
  },
  {
    text: 'PESQUISAR',
    icon: require('../../assets/search.png'),
    page: 'Search',
  },
];

class TabMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      system: null,
    };
  }

  render() {
    return (
      <div className="tabMenu-container">
        {this.props.systemDefault === 'SASCCONNECT' &&
          MENU_ITEMS.map((menu) => (
            <TabMenuItem
              key={uuidv4()}
              navigation={this.props.navigation}
              page={menu.page}
              icon={menu.icon}
              text={menu.text}
              className="tabMenu-item"
            />
          ))}
        {this.props.systemDefault === 'SASC' &&
          filter(MENU_ITEMS, (item) => item.text !== 'TABELAS').map((menu) => (
            <TabMenuItem
              key={uuidv4()}
              navigation={this.props.navigation}
              page={menu.page}
              icon={menu.icon}
              text={menu.text}
              className="tabMenu-item"
            />
          ))}
      </div>
    );
  }
}

export default TabMenu;
