
import React, { Component } from 'react';
import './styles/avatar.css'

class Avatar extends Component {
  render() {
    return (
      <div className="container_avatar">
        <img
          className="image_avatar"
          src={require('../../assets/avatar.png')}
          alt="Avatar"
        />
      </div>
    );
  }
}

export default Avatar;
