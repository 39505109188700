//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';

const TIMOUT_TOAST = 10000;

const toastEffects = {
  state: {},
  effects: () => ({
    success: payload => {
      toastr.success(
        (payload.title ? payload.title : ''),
        (payload.code ? payload.code : '') + ' ' + payload.message,
        {
          timeOut: TIMOUT_TOAST,
          className: 'toast-sasc toast-sasc-success',
        }
      );
    },
    error: payload => {
      if (payload.html) {
        toastr.error(
          '',
          '',
          {
            timeOut: payload.time ? payload.time : TIMOUT_TOAST,
            className: 'toast-sasc toast-sasc-erro',
            component: (
              <div className={'mb-2'} style={{ color: 'inherit' }} dangerouslySetInnerHTML={{ __html: (payload.code ? payload.code : '') + ' ' + payload.message }}></div>
            )
          }
        );
      } else {
        toastr.error(
          (payload.code ? payload.code : '') + ' ' + payload.message,
          {
            timeOut: payload.time ? payload.time : TIMOUT_TOAST,
            className: 'toast-sasc toast-sasc-erro',
          }
        );
      }
    },
    warning: payload => {
      console.log('warning');
      if (payload.html) {
        toastr.warning(
          '',
          '',
          {
            timeOut: payload.time ? payload.time : TIMOUT_TOAST,
            className: 'toast-sasc toast-sasc-warning',
            component: <div dangerouslySetInnerHTML={{ __html: (payload.code ? payload.code : '') + ' ' + payload.message }}></div>
          }
        );
      } else {
        toastr.warning(
          '',
          (payload.code ? payload.code : '') + ' ' + payload.message,
          {
            timeOut: TIMOUT_TOAST,
            className: 'toast-sasc toast-sasc-warning',
            allowHtml: true
          }
        );
      }
    }, confirm: () => {
      toastr.confirm('');
    },

    clear: () => {
      toastr.clean();
    },
    info: async payload => {
      if (payload.html) {
        toastr.info(
          '',
          '',
          {
            timeOut: TIMOUT_TOAST,
            className: 'toast-sasc toast-sasc-info',
            component: (
              <div className={'mb-2'} dangerouslySetInnerHTML={{ __html: (payload.code ? payload.code : '') + ' ' + payload.message }}></div>
            )
          }
        );
      } else {
        toastr.info(
          '',
          (payload.code ? payload.code : '') + ' ' + payload.message,
          {
            timeOut: TIMOUT_TOAST,
            className: 'toast-sasc toast-sasc-info',
          }
        );
      }
    }
  })
};

export default toastEffects;
