import React, { useState, useEffect } from 'react';
import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../Navigation/Header.jsx';
import { AiOutlineLeft } from 'react-icons/ai';
import { formatStringDate } from '../../../utils/date-utils.tsx';
import '../styles/search.css';

const BASE_URL_CONNECT = 'https://your-backend.com'; // replace with actual API service URL
const PRINT_SADT = `/public/report/guiaSADT/{idAtendimento}`;
const PRINT_HONORARIO = `/public/report/guiaHonorarios/{idAtendimento}`;
const PRINT_CONSULTA = `/public/report/guiaConsulta/{idAtendimento}`;
const PRINT_INTERNACAO = `/public/report/guiaInternacao/{idAtendimento}`;

const getUrlPrintProcedure = (idAtendimento, guideType) => {
  let url = '';
  if (guideType && guideType.dtype === '0') {
    url = PRINT_SADT;
  }
  if (guideType && guideType.dtype === '1') {
    url = PRINT_HONORARIO;
  }
  if (guideType && guideType.dtype === '2') {
    url = PRINT_CONSULTA;
  }
  if (guideType && guideType.dtype === '3') {
    url = PRINT_INTERNACAO;
  }

  return BASE_URL_CONNECT + url.replace('{idAtendimento}', idAtendimento);
};

function TreatmentsView() {
  const location = useLocation();
  const [item, setItem] = useState(location.state ? location.state : {});
  console.log(location.state)
  const navigate = useNavigate();

  useEffect(() => {
    window.onpopstate = handleBackButtonClick;
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const printProcedure = (idAtendimento, guideType) => {
    const url = getUrlPrintProcedure(idAtendimento, guideType);
    window.open(url, '_blank');
  };

  return (
    <div className="container-fluid">
      <Header />
      <div className="defaultStyles-headerInput">
        <div className="defaultStyles-halfBack">
          <button
            className="defaultStyles-NewBackButton"
            onClick={handleBackButtonClick}
          >
            <span className="defaultStyles-newBackButtonText">
              <AiOutlineLeft name={'left'} />
              Voltar
            </span>
          </button>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center pb-4 mt-4">
        <div className="treatmentDetails">
          <div className="rowDirection paddingVertical">
            <span className="fontBold">CPF: </span>
            <span>{item.cpfPaciente}</span>
          </div>
          <div className="rowDirection paddingVertical">
            <span className="fontBold">Carteirinha: </span>
            <span>{item.carteirinha}</span>
          </div>
          <span className="fontBold">{item.nomePaciente}</span>

          <div className="rowDirection paddingVertical">
            <span className="fontBold">Convênio: </span>
            <span>{item.convenio?.nome}</span>
          </div>

          <div className="rowDirection paddingVertical">
            <span className="fontBold">Estabelecimento: </span>
            <span>{item.estabelecimento?.nome}</span>
          </div>

          <div className="rowDirection paddingVertical">
            <span className="fontBold">Atendimento: </span>
            <span>{item.numeroAtendimento}</span>
          </div>

          <div className="rowDirection paddingVertical">
            <span className="fontBold">Tipo guia: </span>
            <span>{item.tipoGuia?.nome}</span>
          </div>

          <div className="rowDirection paddingVertical">
            <span className="fontBold">Guia: </span>
            <span>{item.numeroGuia}</span>
          </div>

          {item &&
            item.procedimentoList &&
            item.procedimentoList.map((treatment, index) => (
              <React.Fragment key={index}>
                <div className="rowDirection paddingVertical marginHorizontal">
                  <span className="fontBold">Procedimento(s): </span>
                  <span>{treatment.descricao}</span>
                </div>
                <div className="rowDirection paddingVertical">
                  <span className="fontBold">Cód. Procedimento: </span>
                  <span>{treatment.codigoProcedimento}</span>
                </div>
                <div className="rowDirection paddingVertical">
                  <span className="fontBold">Data atendimento: </span>
                  <span>{formatStringDate(treatment.dataAtendimento)}</span>
                </div>
                <div className="rowDirection paddingVertical">
                  <span className="fontBold">Qtd: </span>
                  <span>{treatment.quantAutorizada}</span>
                </div>

                <div className="rowDirection justifyBetween">
                  <div className="rowDirection paddingVertical">
                    <span className="fontBold">Hora início: </span>
                    <span>{treatment.horaAtendimento}</span>
                  </div>
                  <div className="rowDirection paddingVertical">
                    <span className="fontBold">Hora Fim: </span>
                    <span>{treatment.horaFinalAtendimento}</span>
                  </div>
                </div>
                {treatment.grau !== null && treatment.grau !== undefined && (
                  <div className="rowDirection paddingVertical">
                    <span className="fontBold">Grau de Participação: </span>
                    <span>{treatment.grau}</span>
                  </div>
                )}
                {treatment.via !== null && treatment.via !== undefined && (
                  <div className="rowDirection paddingVertical">
                    <span className="fontBold">Via de acesso: </span>
                    <span>{treatment.via}</span>
                  </div>
                )}
                {treatment.tec !== null && treatment.tec !== undefined && (
                  <div className="rowDirection paddingVertical">
                    <span className="fontBold">Técnica: </span>
                    <span>{treatment.tec}</span>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(TreatmentsView);
