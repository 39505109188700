import Tooltip from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { useEffect } from 'react';

const ControlledTooltip: React.FC<TooltipProps & { helpText: string }> = ({
  helpText,
  ...props
}) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Tooltip
      visible={open ? open.toString() : undefined}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      {...props}
      title={
        <span
          style={{
            color: '#ffffff',
            fontSize: '14px',
            fontWeight: 'bold',
            textAlign: 'justify',
          }}
        >
          {helpText}
        </span>
      }
    />
  );
};

export default ControlledTooltip;
