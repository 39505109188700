import { get, isEmpty, omit, omitBy, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';
import FormTextArea from '../../components/inputs/FormTextArea.js';
import InputViewEdit from '../../components/inputs/InputViewEdit.js';
import { glossedGuidesService } from '../../services/glossedGuides.service';
import '../../themes/defaultStyles.css';
import formatPrice from '../../utils/currency-utils.tsx';
import { formatStringDate } from '../../utils/date-utils.tsx';
import { translator } from '../../utils/string-utils.tsx';
import Header from '../Navigation/Header';
import './styles/itemDetailPage.css';

function ItemDetailPage({ match }) {
  const [item, setItem] = useState({});
  const [justificarGlosa, setJustificarGlosa] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [requiredField, setRequiredField] = useState(false);
  const [submit, setSubmit] = useState(false);
  const location = useLocation();
  const { ehGlosa } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState('0');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  useEffect(() => {
    getItemDetail();
  }, []);

  const getItemDetail = () => {
    if (location.state) {
      console.log(location.state);
      setItem(location.state);
    }
    if (ehGlosa) {
      setJustificarGlosa(true);
    }
  };

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    if (!values.justificativaGlosa) {
      setRequiredField(true);
    }

    const newValues = { ...values, numeroAtendimento: item.numeroAtendimento };
    if (isEmpty(errors, true) || !some(errors)) {
      glossedGuidesService.saveGlosa(item.idGlosa, newValues).then(
        (r) => {
          navigate(-1);
        },
        (e) => {
          console.log('erro', e);
        }
      );
    } else {
      setSubmit(true);
    }
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const contentJustify = (item) => {
    return (
      <Accordion className="container acc" open={open} toggle={toggle}>
        <AccordionItem className="acc-item">
          <AccordionHeader className="acc-header" targetId="1">
            Justificar
          </AccordionHeader>
          <AccordionBody className="acc-body" accordionId="1">
            <InputViewEdit
              component={FormTextArea}
              label="Justificativa"
              name="justificativaGlosa"
              onChange={(e) => {
                const { name, value } = e.target;
                handleChange(name, value);
              }}
              minRows={5}
              className={'textAreaCustom'}
              placeholder="Escreva a justificativa da Glosa"
              required={true}
            />
            {/* <TextArea
              required={true}
              value={values.justificativaGlosa}
              submit={submit}
              placeholder={'Escreva a justificativa da Glosa'}
              onChangeText={handleChange}
              name={'justificativaGlosa'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            /> */}
            <button className="btn btn-secondary mt-3" onClick={handleSubmit}>
              Enviar justificativa
            </button>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    );
  };

  const getHeader = (item) => {
    return (
      <div className="headerContainer d-flex flex-colum justify-content-between">
        {get(item, 'data') && (
          <p className="dataItem">{formatStringDate(get(item, 'data'))}</p>
        )}
        {get(item, 'paciente') && (
          <h2 className="paciente">{get(item, 'paciente')}</h2>
        )}
        {get(item, 'numero') && (
          <p className="numeroItem">Número: {get(item, 'numero')}</p>
        )}
      </div>
    );
  };

  const formataDados = (key) => {
    switch (key) {
      case 'valor':
      case 'valorCobrado':
      case 'valorGlosado':
        return formatPrice(item[key]);
      case 'entrega':
      case 'dataGlosa':
      case 'dataVencimentoCartaGlosa':
      case 'dataRecursoGlosa':
      case 'dataRealizacao':
      case 'dataAtendimento':
        return formatStringDate(item[key]);
      default:
        return item[key];
    }
  };

  const renderDados = (key, label, index) => {
    return (
      <div key={key} className={'col-6 col-sm-3 itemDetailDado'}>
        <b>{label}</b>
        <span className="text-item-detail"> {': ' + formataDados(key)}</span>
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <Header handleBackButton={handleBackButton} />
      <div className="container">
        <h1 className="defaultStyles-titlePageCenter"> Detalhes</h1>
        <div className="defaultStyles-headerInput"></div>

        <div className="defaultStyles-containerScroll mt-1 mt-sm-4">
          {item && getHeader(item)}
          <div className=" itemDetails d-flex flex-row flex-wrap">
            {item &&
              Object.keys(
                omitBy(
                  omit(item, ['paciente', 'numero', 'data']),
                  (v) => v == null
                )
              ).map((key, index) => {
                return renderDados(key, translator[key], index);
              })}
          </div>
        </div>

        {item && justificarGlosa && contentJustify(item)}
      </div>
    </div>
  );
}

export default ItemDetailPage;
