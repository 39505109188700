import React, { Component } from 'react';
import './styles/inputPhone.css';
// import themes, { theme } from '../../theme';
// const mask = '([00]) [00000]-[0000]';

class InputPhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidMount() {
    const { name, value } = this.props;
    this.onChangeText(name, value);
  }

  onChangeText = (name, value) => {
    const { required } = this.props;
    const formattedPhoneNumber = this.formatPhoneNumber(value);
    
    if (required && !value) {
      this.setState({ error: 'Campo Obrigatório' }, () => {
        this.props.onChange(name, formattedPhoneNumber, this.state.error);
      });
    } else {
      if (!formattedPhoneNumber || formattedPhoneNumber.length !== 15) {
        this.setState({ error: 'Número inválido' }, () => {
          this.props.onChange(name, formattedPhoneNumber, this.state.error);
        });
      } else {
        this.setState({ error: '' }, () => {
          this.props.onChange(name, formattedPhoneNumber);
        });
      }
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber) {
      let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
      cleanedPhoneNumber = cleanedPhoneNumber.replace(
        /^(\d{2})(\d)/g,
        '($1) $2'
      ); //Coloca parênteses em volta dos dois primeiros dígitos
      cleanedPhoneNumber = cleanedPhoneNumber.replace(/(\d)(\d{4})$/, '$1-$2');
      return cleanedPhoneNumber;
    }
    return phoneNumber;
  };

  render() {
    const { value, placeholder, name, submit } = this.props;
    const { error } = this.state;
    const inputClass = `formInputPhone ${submit && error ? 'formInputErrorPhone' : ''}`;

    return (
      <input
        className={inputClass}
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          this.onChangeText(name, e.target.value);
        }}
        maxLength={15}
        type="tel"
        autoComplete="off"
      />
    );
  }
}

export default InputPhone;
