
import { isNil, merge } from 'lodash';
import React, { Component } from 'react';
import Dropdown from 'react-dropdown';
import SascDropDownPickerEmpty from './EmptyDropDownPicker';

const sascInputStylesContainerView = (zIndex, errorMessage) => {
  return {
    containerView: {
      width: '100%',
      marginBottom: 5,
      borderColor: isNil(errorMessage) ? '#C4C4C4' : 'red',
      zIndex: zIndex,
      position: 'relative', // This is required for zIndex to work in web CSS
    },
  };
};

const sascInputAutoCompleteStylesContainerView = (zIndex) => {
  return {
    containerView: {
      borderColor: '#adadad',
      height: '55px',
      zIndex: zIndex,
      position: 'relative', // This is required for zIndex to work in web CSS
    },
  };
};

let actualZIndexInverse = 3000;
let actualZIndex = 3;

const getActualZIndex = () => {
  actualZIndex += 1;
  return actualZIndex;
};

const getActualZIndexInverse = () => {
  actualZIndexInverse -= 1;
  return actualZIndexInverse;
};

class SascDropDownPicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      actualZIndex: getActualZIndex(),
      actualZIndexInverse: getActualZIndexInverse(),
      finalProps: props,
      open: false
    };
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {

  }

  getEmptyResult = (props) => {
    return (
      <SascDropDownPickerEmpty
        listMessageContainer={props.listMessageContainer}
        ActivityIndicatorComponent={props.ActivityIndicatorComponent}
        loading={props.loading}
      />
    );
  }

  setOpen = (openState) => {
    this.setState({ open: openState });
  }

  setValue(callback) {
    this.props.setValue(callback.value)
  }

  render() {
    const styles = sascInputStylesContainerView(
      !isNil(this.props.beforeAnotherDropDown) && this.props.beforeAnotherDropDown
        ? this.state.actualZIndexInverse
        : this.state.actualZIndex, this.props.errorMessage
    );

    return (
      <div style={styles.containerView}>
        <Dropdown
          listMode="MODAL"
          style={styles.containerView}
          items={this.props.items}
          open={this.state.open}
          setOpen={this.setOpen}
          ListEmptyComponent={this.getEmptyResult}
          setValue={(callback) => {
            this.setState(state => ({
              value: callback(this.props.value)
            }));
          }}
          onSelectItem={this.setValue}
          value={this.props.value}
          placeholder={this.props.placeholder}
          modalContentContainerStyle={{
            backgroundColor: '#eff3f4',
          }}
          modalProps={{
            animationType: 'fade'
          }}
          modalTitle={this.props.placeholder}
          modalTitleStyle={{
            fontWeight: 'bold',
          }}
          containerStyle={merge(
            { borderColor: '#adadad', border: '1px solid' },
            this.props.containerStyle
          )}
          textStyle={{ color: '#31444D' }}
          placeholderStyle={{ color: '#88949f' }}
        />
        {!isNil(this.props.errorMessage) && (
          <p style={stylesDropDown.errorMessageStyle}>
            {this.props.errorMessage}
          </p>
        )}
      </div>
    );
  }
}

export default SascDropDownPicker;


const stylesDropDown = {
  errorMessageStyle: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    zIndex: -1,
    color: 'red',
    paddingLeft: '5px',
  },
};
