import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { cloneDeep, get, set } from 'lodash';
import { Formik, Form } from 'formik';
import { FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput.js';
import { authenticationService } from '../../services/authentication.service';
import { entitiesService } from '../../services/entities.service';
import converterDropdownItem from '../../utils/itemsDropdown.tsx';
import SumaryReceive from '../Home/SumaryReceive';
import { setValue } from '../../utils/DropDownSetValueUtils.tsx';
import { apiSasc } from '../../services/api.service';
import Card from '../Home/Card';
import { dashboardService } from '../../services/dashboard.service';
import { useNavigate } from 'react-router-dom';
import Header from '../Navigation/Header.jsx';
import './styles/Entidade.css';

const printGuideValidate = Yup.object().shape({
  coop: Yup.object().required('Obrigatório'),
});

function Entidade({ loading }) {
  const [values, setValues] = useState({ coop: {}, system: '' });
  const [entities, setEntities] = useState([]);
  const [detalheAtendimento, setDetalheAtendimento] = useState(null);
  const navigate = useNavigate();

  const getDetalheAtendimento = () => {
    loading(true);
    dashboardService.findDetalheAtendimento().then(
      (r) => {
        setDetalheAtendimento(r.data);
        loading(false);
      },
      (e) => {
        console.log(e);
        loading(false);

        setDetalheAtendimento([]);
      }
    );
  };

  const getDados = () => {
    loading(true);
    Promise.all([
      authenticationService.getEntidade(),
      entitiesService.findEntidadesCooperado(),
      apiSasc.get('/getUserSystem'),
    ]).then(
      (response) => {
        const coopSelect = get(JSON.parse(response[0]), 'idEntidade');
        let entities = converterDropdownItem(
          response[1].data,
          'siglaEntidade',
          'idEntidade'
        );
        setValues({
          coop: coopSelect,
          system: response[2].data,
        });
        entities = [...entities, { label: 'COOPERATIVA 2 TESTE', value: 47 }];
        setEntities(entities);
        loading(false);
      },
      (error) => {
        console.log(JSON.stringify(error));
        loading(false);
        error(
          'Não foi possível carregar algumas informações, tente novamente.'
        );
      }
    );
  };

  console.log(entities);

  const saveEntity = async (entity) => {
    try {
      loading(true);
      const response = await entitiesService.saveEntidadeCooperado(entity);
      const data = response.data;
      loading(false);
      if (data) {
        authenticationService.setEntidade(data.portalEntidadeCooperado);
      }
    } catch (error) {
      error('Ocorreu um erro ao obter os dados! Tente mais tarde.');
      setValues((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleChange = (name, value) => {
    setValues((prevState) => {
      const newState = cloneDeep(prevState);
      set(newState, name, value);
      return newState;
    });
  };

  useEffect(() => {
    getDados();
  }, []);

  useEffect(() => {
    console.log(values.system);
    if (values.system === 'SASCCONNECT') {
      getDetalheAtendimento();
    }
  }, [values.system]);

  return (
    <div className="container-fluid">
      <Header />
      <h1 className="defaultStyles-titlePageCenter">Cooperativa</h1>
      <div className="container col-12 offset-md-4 col-md-4">
        <Formik
          validationSchema={printGuideValidate}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={values}
        >
          {({ values, errors, handleChange, setFieldValue }) => (
            <Form autoComplete="off" className="row">
              <FormGroup className="col-12">
                <InputViewEdit
                  component={SelectInput}
                  options={entities}
                  valueKey="value"
                  labelKey="label"
                  id="coop"
                  name="coop"
                  placeholder="Cooperativa"
                  value={values.coop}
                  initialValue={values.coop}
                  onChange={(name, value) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      [name]: value,
                    }));
                    const coop = setValue(entities, value, 'value');
                    handleChange('coop', value);
                    saveEntity({
                      idEntidade: value,
                      nome: get(coop, 'value'),
                    });
                  }}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
        {values.system === 'SASCCONNECT' && detalheAtendimento && (
          <>
            <div className="entidadePage_sumary">
              <SumaryReceive
                className="col-12 d-flex flex-row align-items-center p-2"
                label="SADT(s):"
                value={detalheAtendimento.amountSadt || 0}
              />
              <SumaryReceive
                className="col-12 d-flex flex-row align-items-center p-2"
                label="Consulta(s)"
                value={detalheAtendimento.amountConsulta || 0}
                color="#097A5E"
              />
              <SumaryReceive
                className="col-12 d-flex flex-row align-items-center p-2"
                label="Honorário(s)"
                value={detalheAtendimento.amountHonorarios || 0}
                color="#707070"
              />
              <SumaryReceive
                className="col-12 d-flex flex-row align-items-center p-2"
                label="internação(ções)"
                value={detalheAtendimento.amountInternacao || 0}
                color="#adadad"
              />
              <Card
                className={'col-12'}
                label="Situação"
                action={() => {
                  navigate('/home');
                }}
                image={require('../../assets/em-analise.png')}
              />
            </div>
          </>
        )}

        {values.system === 'SASC' && (
          <div className="CardContainer-Sasc">
            <Card
              className="col-12 flex-row align-items-center p-2 "
              label="Em Análise"
              action={() => {
                navigate('/CooperativeAnalysis');
              }}
              image={require('../../assets/em-analise.png')}
            />
            <Card
              className="col-12 flex-row align-items-center p-2"
              action={() => {
                navigate('/AwaitingPayment');
              }}
              label="Faturamento Enviado"
              image={require('../../assets/icon-faturamento-enviado.png')}
              color="#097A5E"
            />
            <Card
              className="col-12 flex-row align-items-center p-2"
              action={() => {
                navigate('/ExpectedPayments');
              }}
              label="Previsto"
              image={require('../../assets/icon-previstos.png')}
              color="#707070"
            />
            <Card
              className="col-12 flex-row align-items-center p-2"
              action={() => {
                navigate('/GlossedGuides');
              }}
              label="Glosados"
              image={require('../../assets/icon-glosados.png')}
              color="#adadad"
            />
            <Card
              className="col-12 flex-row align-items-center p-2"
              action={() => {
                navigate('/PaymentsMade');
              }}
              label="Realizado"
              image={require('../../assets/icon-realizado.png')}
            />
            <Card
              className="col-12 flex-row align-items-center p-2"
              action={() => {
                navigate('/ExpectedPayments');
              }}
              label="Protocolo Busca de Guia"
              image={require('../../assets/icon-protocolo.png')}
            />
          </div>
        )}
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   const { load } = state.load;
//   const { loggedIn, twoFactorAuthenticate } = state.authentication;
//   return {
//     loading: load,
//     loggedIn,
//     twoFactorAuthenticate,
//   };
// };

const mapDispatch = ({
  alert: { error, clear, success, warning },
  load: { loading },
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: (msg) => success(msg),
  warning: (msg) => warning(msg),
  loading: (load) => loading({ load }),
});

export default connect(null, mapDispatch)(Entidade);
