//@flow
type State = {
    menuPerfilAberto: boolean,
};

const initialState: State = { menuPerfilAberto: false };

const reducers = {
    toggleMenuPerfil: (state: State) => {
        return { ...state, menuPerfilAberto: !state.menuPerfilAberto };
    },
    abreMenuPerfil: (state: State) => {
        return { ...state, menuPerfilAberto: true };
    },
    fechaMenuPerfil: (state: State) => {
        return { ...state, menuPerfilAberto: false };
    },
};

const menuPerfil = {
    state: initialState,
    reducers,
};

export default menuPerfil;
